:root {
  ---max-width: 1152px;
}

* {
  -webkit-font-smoothing: antialiased !important;
}

:root {
  --brand-primary-50: #fff8e0;
  --brand-primary-100: #fff4c2;
  --brand-primary-200: #ffe994;
  --brand-primary-300: #ffdb52;
  --brand-primary-400: #ffcb05;
  --brand-primary-500: #febe10;
  --brand-primary-600: #d3980d;
  --brand-primary-700: #b27a0a;
  --brand-primary-800: #8b5c04;
  --brand-primary-900: #6f4901;
  --brand-secondary-50: #f1f7f7;
  --brand-secondary-100: #e6efef;
  --brand-secondary-200: #d6e3e6;
  --brand-secondary-300: #c0d3d8;
  --brand-secondary-400: #acc3cb;
  --brand-secondary-500: #7097a3;
  --brand-secondary-600: #4a7283;
  --brand-secondary-700: #315f6e;
  --brand-secondary-800: #184a58;
  --brand-secondary-900: #003946;
  --brand-tertiary-50: #e1f8ff;
  --brand-tertiary-100: #c6ecfb;
  --brand-tertiary-200: #94d9f4;
  --brand-tertiary-300: #6fcdf1;
  --brand-tertiary-400: #00aff0;
  --brand-tertiary-500: #0093cf;
  --brand-tertiary-600: #007eb8;
  --brand-tertiary-700: #0068a1;
  --brand-tertiary-800: #00588b;
  --brand-tertiary-900: #004570;
  --grey-50: #f9fafa;
  --grey-100: #f4f4f5;
  --grey-200: #eaebeb;
  --grey-300: #dcdedf;
  --grey-400: #c3c8cb;
  --grey-500: #a0a5a7;
  --grey-600: #71777a;
  --grey-700: #5b6062;
  --grey-800: #3d4142;
  --grey-900: #202223;
  --green-50: #f0fcee;
  --green-100: #e5f9e2;
  --green-200: #cceac7;
  --green-300: #addda7;
  --green-400: #94d289;
  --green-500: #5bb54b;
  --green-600: #479a39;
  --green-700: #367e2a;
  --green-800: #25611c;
  --green-900: #15480f;
  --teal-50: #e6f9f6;
  --teal-100: #d4f0eb;
  --teal-200: #b0e3db;
  --teal-300: #86d6ca;
  --teal-400: #63c8b9;
  --teal-500: #35ac9d;
  --teal-600: #269287;
  --teal-700: #1c7770;
  --teal-800: #0e5d59;
  --teal-900: #014646;
  --purple-50: #f9f0f8;
  --purple-100: #f1dfee;
  --purple-200: #e5bedd;
  --purple-300: #daa4ce;
  --purple-400: #cb76b8;
  --purple-500: #b04598;
  --purple-600: #9d3a86;
  --purple-700: #832f70;
  --purple-800: #6e215b;
  --purple-900: #5b1549;
  --red-50: #fef6f6;
  --red-100: #fde2e3;
  --red-200: #fab7bc;
  --red-300: #f499a2;
  --red-400: #ea677a;
  --red-500: #dc2e4a;
  --red-600: #d0203d;
  --red-700: #b81f39;
  --red-800: #9e1a30;
  --red-900: #7c1328;
  --orange-50: #fff5eb;
  --orange-100: #ffecd6;
  --orange-200: #ffdab2;
  --orange-300: #ffcb8f;
  --orange-400: #ffb866;
  --orange-500: #ffa13d;
  --orange-600: #fb8b23;
  --orange-700: #da6a10;
  --orange-800: #b8540f;
  --orange-900: #9d461b;
  --taupe-50: #faf6ef;
  --taupe-100: #f0e9e0;
  --taupe-200: #e2d9d0;
  --taupe-300: #d3c9c0;
  --taupe-400: #baaca1;
  --taupe-500: #968679;
  --taupe-600: #867769;
  --taupe-700: #76685b;
  --taupe-800: #5d5246;
  --taupe-900: #493d32;
  --white: #fff;
  --overlay: #202223bf;
  --color-primary-800: #8b5c04;
  --color-primary-600: #d3980d;
  --color-primary-400: #ffcb05;
  --color-primary-100: #fff4c2;
  --color-primary-50: #fffae0;
  --color-secondary-900: #003946;
  --color-secondary-200: #d6e3e6;
  --color-secondary-50: #f1f7f7;
  --color-tertiary-900: #004570;
  --color-tertiary-800: #00588b;
  --color-tertiary-700: #0068a1;
  --color-tertiary-500: #0093cf;
  --color-gray-900: #202223;
  --color-gray-800: #3d4142;
  --color-gray-600: #71777a;
  --color-gray-600-50: fade(#71777a, 50%);
  --color-gray-500: #a0a5a7;
  --color-gray-300: #dcdedf;
  --color-gray-200: #eaebeb;
  --color-gray-100: #f4f4f5;
  --color-gray-50: #f9fafa;
  --color-green-800: #25611c;
  --color-green-50: #f0fcee;
  --color-red-700: #b81f39;
  --color-red-900: #7c1328;
  --color-red-50: #fef6f6;
}

:root, [data-bs-theme="light"] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: #212529bf;
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: #21252980;
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dcdedf;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: 0;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0px 4px 8px 3px #00000014;
  --bs-box-shadow-sm: 0px 1px 3px 2px #0000001a;
  --bs-box-shadow-lg: 0px 4px 8px 3px #00000026, 0px 1px 3px #0000001a;
  --bs-box-shadow-inset: inset 0 1px 2px #00000013;
  --bs-focus-ring-width: .25rem;
  --bs-focus-ring-opacity: .25;
  --bs-focus-ring-color: #0d6efd40;
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: #dee2e6bf;
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: #dee2e680;
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: #ffffff26;
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: .25;
  margin: 1rem 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  color: var(--bs-heading-color);
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (width >= 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (width >= 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (width >= 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (width >= 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .small {
  font-size: .875em;
}

mark, .mark {
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: var(--bs-secondary-color);
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (width >= 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: var(--bs-secondary-color);
  font-size: .875em;
}

.container, .container-fluid, .container-xl, .container-lg, .container-md, .container-sm, .container-xs {
  --bs-gutter-x: 24px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

.container {
  max-width: 100%;
}

@media (width >= 600px) {
  .container-sm, .container {
    max-width: 100%;
  }
}

@media (width >= 905px) {
  .container-md, .container-sm, .container {
    max-width: 100%;
  }
}

@media (width >= 1240px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 1152px;
  }
}

@media (width >= 1440px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1152px;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 600px;
  --bs-breakpoint-md: 905px;
  --bs-breakpoint-lg: 1240px;
  --bs-breakpoint-xl: 1440px;
}

.row {
  --bs-gutter-x: 24px;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  flex: none;
  width: auto;
}

.row-cols-1 > * {
  flex: none;
  width: 100%;
}

.row-cols-2 > * {
  flex: none;
  width: 50%;
}

.row-cols-3 > * {
  flex: none;
  width: 33.3333%;
}

.row-cols-4 > * {
  flex: none;
  width: 25%;
}

.row-cols-5 > * {
  flex: none;
  width: 20%;
}

.row-cols-6 > * {
  flex: none;
  width: 16.6667%;
}

.col-auto {
  flex: none;
  width: auto;
}

.col-1 {
  flex: none;
  width: 8.33333%;
}

.col-2 {
  flex: none;
  width: 16.6667%;
}

.col-3 {
  flex: none;
  width: 25%;
}

.col-4 {
  flex: none;
  width: 33.3333%;
}

.col-5 {
  flex: none;
  width: 41.6667%;
}

.col-6 {
  flex: none;
  width: 50%;
}

.col-7 {
  flex: none;
  width: 58.3333%;
}

.col-8 {
  flex: none;
  width: 66.6667%;
}

.col-9 {
  flex: none;
  width: 75%;
}

.col-10 {
  flex: none;
  width: 83.3333%;
}

.col-11 {
  flex: none;
  width: 91.6667%;
}

.col-12 {
  flex: none;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --bs-gutter-x: 0;
}

.g-0, .gy-0 {
  --bs-gutter-y: 0;
}

.g-1, .gx-1 {
  --bs-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --bs-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --bs-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --bs-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3, .gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4, .gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4, .gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5, .gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5, .gy-5 {
  --bs-gutter-y: 3rem;
}

.g-sl4, .gx-sl4 {
  --bs-gutter-x: .25rem;
}

.g-sl4, .gy-sl4 {
  --bs-gutter-y: .25rem;
}

.g-sl8, .gx-sl8 {
  --bs-gutter-x: .5rem;
}

.g-sl8, .gy-sl8 {
  --bs-gutter-y: .5rem;
}

.g-sl12, .gx-sl12 {
  --bs-gutter-x: .75rem;
}

.g-sl12, .gy-sl12 {
  --bs-gutter-y: .75rem;
}

.g-sl16, .gx-sl16 {
  --bs-gutter-x: 1rem;
}

.g-sl16, .gy-sl16 {
  --bs-gutter-y: 1rem;
}

.g-sl24, .gx-sl24 {
  --bs-gutter-x: 1.5rem;
}

.g-sl24, .gy-sl24 {
  --bs-gutter-y: 1.5rem;
}

.g-sl32, .gx-sl32 {
  --bs-gutter-x: 2rem;
}

.g-sl32, .gy-sl32 {
  --bs-gutter-y: 2rem;
}

.g-sl40, .gx-sl40 {
  --bs-gutter-x: 2.5rem;
}

.g-sl40, .gy-sl40 {
  --bs-gutter-y: 2.5rem;
}

.g-sl48, .gx-sl48 {
  --bs-gutter-x: 3rem;
}

.g-sl48, .gy-sl48 {
  --bs-gutter-y: 3rem;
}

.g-sl56, .gx-sl56 {
  --bs-gutter-x: 3.5rem;
}

.g-sl56, .gy-sl56 {
  --bs-gutter-y: 3.5rem;
}

.g-sl64, .gx-sl64 {
  --bs-gutter-x: 4rem;
}

.g-sl64, .gy-sl64 {
  --bs-gutter-y: 4rem;
}

.g-sl72, .gx-sl72 {
  --bs-gutter-x: 4.5rem;
}

.g-sl72, .gy-sl72 {
  --bs-gutter-y: 4.5rem;
}

.g-sl80, .gx-sl80 {
  --bs-gutter-x: 5rem;
}

.g-sl80, .gy-sl80 {
  --bs-gutter-y: 5rem;
}

.g-sl88, .gx-sl88 {
  --bs-gutter-x: 5.5rem;
}

.g-sl88, .gy-sl88 {
  --bs-gutter-y: 5.5rem;
}

.g-sl96, .gx-sl96 {
  --bs-gutter-x: 6rem;
}

.g-sl96, .gy-sl96 {
  --bs-gutter-y: 6rem;
}

@media (width >= 600px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-sm-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-auto {
    flex: none;
    width: auto;
  }

  .col-sm-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-3 {
    flex: none;
    width: 25%;
  }

  .col-sm-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-sm-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-sm-6 {
    flex: none;
    width: 50%;
  }

  .col-sm-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-sm-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-sm-9 {
    flex: none;
    width: 75%;
  }

  .col-sm-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-sm-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-sm-12 {
    flex: none;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }

  .g-sm-sl4, .gx-sm-sl4 {
    --bs-gutter-x: .25rem;
  }

  .g-sm-sl4, .gy-sm-sl4 {
    --bs-gutter-y: .25rem;
  }

  .g-sm-sl8, .gx-sm-sl8 {
    --bs-gutter-x: .5rem;
  }

  .g-sm-sl8, .gy-sm-sl8 {
    --bs-gutter-y: .5rem;
  }

  .g-sm-sl12, .gx-sm-sl12 {
    --bs-gutter-x: .75rem;
  }

  .g-sm-sl12, .gy-sm-sl12 {
    --bs-gutter-y: .75rem;
  }

  .g-sm-sl16, .gx-sm-sl16 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-sl16, .gy-sm-sl16 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-sl24, .gx-sm-sl24 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-sl24, .gy-sm-sl24 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-sl32, .gx-sm-sl32 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-sl32, .gy-sm-sl32 {
    --bs-gutter-y: 2rem;
  }

  .g-sm-sl40, .gx-sm-sl40 {
    --bs-gutter-x: 2.5rem;
  }

  .g-sm-sl40, .gy-sm-sl40 {
    --bs-gutter-y: 2.5rem;
  }

  .g-sm-sl48, .gx-sm-sl48 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-sl48, .gy-sm-sl48 {
    --bs-gutter-y: 3rem;
  }

  .g-sm-sl56, .gx-sm-sl56 {
    --bs-gutter-x: 3.5rem;
  }

  .g-sm-sl56, .gy-sm-sl56 {
    --bs-gutter-y: 3.5rem;
  }

  .g-sm-sl64, .gx-sm-sl64 {
    --bs-gutter-x: 4rem;
  }

  .g-sm-sl64, .gy-sm-sl64 {
    --bs-gutter-y: 4rem;
  }

  .g-sm-sl72, .gx-sm-sl72 {
    --bs-gutter-x: 4.5rem;
  }

  .g-sm-sl72, .gy-sm-sl72 {
    --bs-gutter-y: 4.5rem;
  }

  .g-sm-sl80, .gx-sm-sl80 {
    --bs-gutter-x: 5rem;
  }

  .g-sm-sl80, .gy-sm-sl80 {
    --bs-gutter-y: 5rem;
  }

  .g-sm-sl88, .gx-sm-sl88 {
    --bs-gutter-x: 5.5rem;
  }

  .g-sm-sl88, .gy-sm-sl88 {
    --bs-gutter-y: 5.5rem;
  }

  .g-sm-sl96, .gx-sm-sl96 {
    --bs-gutter-x: 6rem;
  }

  .g-sm-sl96, .gy-sm-sl96 {
    --bs-gutter-y: 6rem;
  }
}

@media (width >= 905px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-md-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-md-auto {
    flex: none;
    width: auto;
  }

  .col-md-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }

  .col-md-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-md-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-md-9 {
    flex: none;
    width: 75%;
  }

  .col-md-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-md-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-md-12 {
    flex: none;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3, .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4, .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4, .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5, .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5, .gy-md-5 {
    --bs-gutter-y: 3rem;
  }

  .g-md-sl4, .gx-md-sl4 {
    --bs-gutter-x: .25rem;
  }

  .g-md-sl4, .gy-md-sl4 {
    --bs-gutter-y: .25rem;
  }

  .g-md-sl8, .gx-md-sl8 {
    --bs-gutter-x: .5rem;
  }

  .g-md-sl8, .gy-md-sl8 {
    --bs-gutter-y: .5rem;
  }

  .g-md-sl12, .gx-md-sl12 {
    --bs-gutter-x: .75rem;
  }

  .g-md-sl12, .gy-md-sl12 {
    --bs-gutter-y: .75rem;
  }

  .g-md-sl16, .gx-md-sl16 {
    --bs-gutter-x: 1rem;
  }

  .g-md-sl16, .gy-md-sl16 {
    --bs-gutter-y: 1rem;
  }

  .g-md-sl24, .gx-md-sl24 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-sl24, .gy-md-sl24 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-sl32, .gx-md-sl32 {
    --bs-gutter-x: 2rem;
  }

  .g-md-sl32, .gy-md-sl32 {
    --bs-gutter-y: 2rem;
  }

  .g-md-sl40, .gx-md-sl40 {
    --bs-gutter-x: 2.5rem;
  }

  .g-md-sl40, .gy-md-sl40 {
    --bs-gutter-y: 2.5rem;
  }

  .g-md-sl48, .gx-md-sl48 {
    --bs-gutter-x: 3rem;
  }

  .g-md-sl48, .gy-md-sl48 {
    --bs-gutter-y: 3rem;
  }

  .g-md-sl56, .gx-md-sl56 {
    --bs-gutter-x: 3.5rem;
  }

  .g-md-sl56, .gy-md-sl56 {
    --bs-gutter-y: 3.5rem;
  }

  .g-md-sl64, .gx-md-sl64 {
    --bs-gutter-x: 4rem;
  }

  .g-md-sl64, .gy-md-sl64 {
    --bs-gutter-y: 4rem;
  }

  .g-md-sl72, .gx-md-sl72 {
    --bs-gutter-x: 4.5rem;
  }

  .g-md-sl72, .gy-md-sl72 {
    --bs-gutter-y: 4.5rem;
  }

  .g-md-sl80, .gx-md-sl80 {
    --bs-gutter-x: 5rem;
  }

  .g-md-sl80, .gy-md-sl80 {
    --bs-gutter-y: 5rem;
  }

  .g-md-sl88, .gx-md-sl88 {
    --bs-gutter-x: 5.5rem;
  }

  .g-md-sl88, .gy-md-sl88 {
    --bs-gutter-y: 5.5rem;
  }

  .g-md-sl96, .gx-md-sl96 {
    --bs-gutter-x: 6rem;
  }

  .g-md-sl96, .gy-md-sl96 {
    --bs-gutter-y: 6rem;
  }
}

@media (width >= 1240px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-lg-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-auto {
    flex: none;
    width: auto;
  }

  .col-lg-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-lg-9 {
    flex: none;
    width: 75%;
  }

  .col-lg-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-lg-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-lg-12 {
    flex: none;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }

  .g-lg-sl4, .gx-lg-sl4 {
    --bs-gutter-x: .25rem;
  }

  .g-lg-sl4, .gy-lg-sl4 {
    --bs-gutter-y: .25rem;
  }

  .g-lg-sl8, .gx-lg-sl8 {
    --bs-gutter-x: .5rem;
  }

  .g-lg-sl8, .gy-lg-sl8 {
    --bs-gutter-y: .5rem;
  }

  .g-lg-sl12, .gx-lg-sl12 {
    --bs-gutter-x: .75rem;
  }

  .g-lg-sl12, .gy-lg-sl12 {
    --bs-gutter-y: .75rem;
  }

  .g-lg-sl16, .gx-lg-sl16 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-sl16, .gy-lg-sl16 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-sl24, .gx-lg-sl24 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-sl24, .gy-lg-sl24 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-sl32, .gx-lg-sl32 {
    --bs-gutter-x: 2rem;
  }

  .g-lg-sl32, .gy-lg-sl32 {
    --bs-gutter-y: 2rem;
  }

  .g-lg-sl40, .gx-lg-sl40 {
    --bs-gutter-x: 2.5rem;
  }

  .g-lg-sl40, .gy-lg-sl40 {
    --bs-gutter-y: 2.5rem;
  }

  .g-lg-sl48, .gx-lg-sl48 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-sl48, .gy-lg-sl48 {
    --bs-gutter-y: 3rem;
  }

  .g-lg-sl56, .gx-lg-sl56 {
    --bs-gutter-x: 3.5rem;
  }

  .g-lg-sl56, .gy-lg-sl56 {
    --bs-gutter-y: 3.5rem;
  }

  .g-lg-sl64, .gx-lg-sl64 {
    --bs-gutter-x: 4rem;
  }

  .g-lg-sl64, .gy-lg-sl64 {
    --bs-gutter-y: 4rem;
  }

  .g-lg-sl72, .gx-lg-sl72 {
    --bs-gutter-x: 4.5rem;
  }

  .g-lg-sl72, .gy-lg-sl72 {
    --bs-gutter-y: 4.5rem;
  }

  .g-lg-sl80, .gx-lg-sl80 {
    --bs-gutter-x: 5rem;
  }

  .g-lg-sl80, .gy-lg-sl80 {
    --bs-gutter-y: 5rem;
  }

  .g-lg-sl88, .gx-lg-sl88 {
    --bs-gutter-x: 5.5rem;
  }

  .g-lg-sl88, .gy-lg-sl88 {
    --bs-gutter-y: 5.5rem;
  }

  .g-lg-sl96, .gx-lg-sl96 {
    --bs-gutter-x: 6rem;
  }

  .g-lg-sl96, .gy-lg-sl96 {
    --bs-gutter-y: 6rem;
  }
}

@media (width >= 1440px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-auto {
    flex: none;
    width: auto;
  }

  .col-xl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xl-6 {
    flex: none;
    width: 50%;
  }

  .col-xl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xl-9 {
    flex: none;
    width: 75%;
  }

  .col-xl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }

  .g-xl-sl4, .gx-xl-sl4 {
    --bs-gutter-x: .25rem;
  }

  .g-xl-sl4, .gy-xl-sl4 {
    --bs-gutter-y: .25rem;
  }

  .g-xl-sl8, .gx-xl-sl8 {
    --bs-gutter-x: .5rem;
  }

  .g-xl-sl8, .gy-xl-sl8 {
    --bs-gutter-y: .5rem;
  }

  .g-xl-sl12, .gx-xl-sl12 {
    --bs-gutter-x: .75rem;
  }

  .g-xl-sl12, .gy-xl-sl12 {
    --bs-gutter-y: .75rem;
  }

  .g-xl-sl16, .gx-xl-sl16 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-sl16, .gy-xl-sl16 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-sl24, .gx-xl-sl24 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-sl24, .gy-xl-sl24 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-sl32, .gx-xl-sl32 {
    --bs-gutter-x: 2rem;
  }

  .g-xl-sl32, .gy-xl-sl32 {
    --bs-gutter-y: 2rem;
  }

  .g-xl-sl40, .gx-xl-sl40 {
    --bs-gutter-x: 2.5rem;
  }

  .g-xl-sl40, .gy-xl-sl40 {
    --bs-gutter-y: 2.5rem;
  }

  .g-xl-sl48, .gx-xl-sl48 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-sl48, .gy-xl-sl48 {
    --bs-gutter-y: 3rem;
  }

  .g-xl-sl56, .gx-xl-sl56 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xl-sl56, .gy-xl-sl56 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xl-sl64, .gx-xl-sl64 {
    --bs-gutter-x: 4rem;
  }

  .g-xl-sl64, .gy-xl-sl64 {
    --bs-gutter-y: 4rem;
  }

  .g-xl-sl72, .gx-xl-sl72 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xl-sl72, .gy-xl-sl72 {
    --bs-gutter-y: 4.5rem;
  }

  .g-xl-sl80, .gx-xl-sl80 {
    --bs-gutter-x: 5rem;
  }

  .g-xl-sl80, .gy-xl-sl80 {
    --bs-gutter-y: 5rem;
  }

  .g-xl-sl88, .gx-xl-sl88 {
    --bs-gutter-x: 5.5rem;
  }

  .g-xl-sl88, .gy-xl-sl88 {
    --bs-gutter-y: 5.5rem;
  }

  .g-xl-sl96, .gx-xl-sl96 {
    --bs-gutter-x: 6rem;
  }

  .g-xl-sl96, .gy-xl-sl96 {
    --bs-gutter-y: 6rem;
  }
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}

.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: .125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}

.nav-underline .nav-link {
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
  padding-left: 0;
  padding-right: 0;
}

.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentColor;
}

.nav-underline .nav-link.active, .nav-underline .show > .nav-link {
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentColor;
  font-weight: 700;
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.form-label {
  margin-bottom: .5rem;
}

.col-form-label {
  padding-top: calc(.375rem + var(--bs-border-width));
  padding-bottom: calc(.375rem + var(--bs-border-width));
  font-size: inherit;
  margin-bottom: 0;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + var(--bs-border-width));
  padding-bottom: calc(.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(.25rem + var(--bs-border-width));
  padding-bottom: calc(.25rem + var(--bs-border-width));
  font-size: .875rem;
}

.form-text {
  color: var(--bs-secondary-color);
  margin-top: .25rem;
  font-size: .875em;
}

.form-control {
  width: 100%;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  background-clip: padding-box;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  padding: 0;
  display: block;
}

.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.form-control::file-selector-button {
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  margin: -.375rem -.75rem;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  width: 100%;
  color: var(--bs-body-color);
  border: solid #0000;
  border-width: var(--bs-border-width) 0;
  background-color: #0000;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  min-height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2));
  border-radius: var(--bs-border-radius-sm);
  padding: .25rem .5rem;
  font-size: .875rem;
}

.form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  border-radius: var(--bs-border-radius-lg);
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + .75rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-sm {
  min-height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + calc(var(--bs-border-width) * 2));
  padding: .375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: var(--bs-border-radius);
  border: 0 !important;
}

.form-control-color::-webkit-color-swatch {
  border-radius: var(--bs-border-radius);
  border: 0 !important;
}

.form-control-color.form-control-sm {
  height: calc(1.5em + .5rem + calc(var(--bs-border-width) * 2));
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  width: 100%;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}

.form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  border-radius: var(--bs-border-radius-sm);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.form-select-lg {
  border-radius: var(--bs-border-radius-lg);
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: .25em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-image: var(--bs-form-switch-bg);
  background-position: 0;
  border-radius: 2em;
  width: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  appearance: none;
  background-color: #0000;
  width: 100%;
  height: 1.5rem;
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #0d6efd40;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #0d6efd40;
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
  color: #0000;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: #0000;
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range::-moz-range-thumb {
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-moz-range-track {
  color: #0000;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: #0000;
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}

.form-floating > label {
  z-index: 2;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  height: 100%;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
  padding: 1rem .75rem;
}

.form-floating > .form-control::placeholder, .form-floating > .form-control-plaintext::placeholder {
  color: #0000;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown), :-webkit-any(.form-floating > .form-control:-webkit-autofill, .form-floating > .form-control-plaintext:-webkit-autofill), .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown), :is(.form-floating > .form-control:autofill, .form-floating > .form-control-plaintext:autofill), .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translate(.15rem);
}

.form-floating > .form-control:focus ~ label:after, .form-floating > .form-control:not(:placeholder-shown) ~ label:after, .form-floating > .form-control-plaintext ~ label:after, .form-floating > .form-select ~ label:after {
  z-index: -1;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
  height: 1.5em;
  position: absolute;
  inset: 1rem .375rem;
}

.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translate(.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}

.form-floating > :disabled ~ label, .form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}

.form-floating > :disabled ~ label:after, .form-floating > .form-control:disabled ~ label:after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select, .input-group > .form-floating {
  flex: auto;
  width: 1%;
  min-width: 0;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus, .input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  border-radius: var(--bs-border-radius-lg);
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  border-radius: var(--bs-border-radius-sm);
  padding: .25rem .5rem;
  font-size: .875rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  width: 100%;
  color: var(--bs-form-valid-color);
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3.75rem + 1.5em);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 .25rem rgba(var(--bs-success-rgb), .25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated .input-group > .form-select:not(:focus):valid, .input-group > .form-select:not(:focus).is-valid, .was-validated .input-group > .form-floating:not(:focus-within):valid, .input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  width: 100%;
  color: var(--bs-form-invalid-color);
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3.75rem + 1.5em);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .25rem rgba(var(--bs-danger-rgb), .25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated .input-group > .form-select:not(:focus):invalid, .input-group > .form-select:not(:focus).is-invalid, .was-validated .input-group > .form-floating:not(:focus-within):invalid, .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary:hover, .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, .75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, .75)) !important;
}

.focus-ring:focus {
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
  outline: 0;
}

.icon-link {
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, .5));
  text-underline-offset: .25em;
  backface-visibility: hidden;
  align-items: center;
  gap: .375rem;
  display: inline-flex;
}

.icon-link > .bi {
  fill: currentColor;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  transition: transform .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(.25em, 0, 0));
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (width >= 600px) {
  .sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 905px) {
  .sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1240px) {
  .sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1440px) {
  .sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.visually-hidden:not(caption), .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  width: var(--bs-border-width);
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  min-height: 1em;
  display: inline-block;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translate(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-6 {
  border-width: 6px !important;
}

.border-opacity-10 {
  --bs-border-opacity: .1;
}

.border-opacity-25 {
  --bs-border-opacity: .25;
}

.border-opacity-50 {
  --bs-border-opacity: .5;
}

.border-opacity-75 {
  --bs-border-opacity: .75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-sl4 {
  margin: .25rem !important;
}

.m-sl8 {
  margin: .5rem !important;
}

.m-sl12 {
  margin: .75rem !important;
}

.m-sl16 {
  margin: 1rem !important;
}

.m-sl24 {
  margin: 1.5rem !important;
}

.m-sl32 {
  margin: 2rem !important;
}

.m-sl40 {
  margin: 2.5rem !important;
}

.m-sl48 {
  margin: 3rem !important;
}

.m-sl56 {
  margin: 3.5rem !important;
}

.m-sl64 {
  margin: 4rem !important;
}

.m-sl72 {
  margin: 4.5rem !important;
}

.m-sl80 {
  margin: 5rem !important;
}

.m-sl88 {
  margin: 5.5rem !important;
}

.m-sl96 {
  margin: 6rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-sl4 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-sl8 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-sl12 {
  margin-left: .75rem !important;
  margin-right: .75rem !important;
}

.mx-sl16 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-sl24 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-sl32 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mx-sl40 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.mx-sl48 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-sl56 {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}

.mx-sl64 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.mx-sl72 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}

.mx-sl80 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.mx-sl88 {
  margin-left: 5.5rem !important;
  margin-right: 5.5rem !important;
}

.mx-sl96 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-sl4 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-sl8 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-sl12 {
  margin-top: .75rem !important;
  margin-bottom: .75rem !important;
}

.my-sl16 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-sl24 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-sl32 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-sl40 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-sl48 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-sl56 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-sl64 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-sl72 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-sl80 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-sl88 {
  margin-top: 5.5rem !important;
  margin-bottom: 5.5rem !important;
}

.my-sl96 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-sl4 {
  margin-top: .25rem !important;
}

.mt-sl8 {
  margin-top: .5rem !important;
}

.mt-sl12 {
  margin-top: .75rem !important;
}

.mt-sl16 {
  margin-top: 1rem !important;
}

.mt-sl24 {
  margin-top: 1.5rem !important;
}

.mt-sl32 {
  margin-top: 2rem !important;
}

.mt-sl40 {
  margin-top: 2.5rem !important;
}

.mt-sl48 {
  margin-top: 3rem !important;
}

.mt-sl56 {
  margin-top: 3.5rem !important;
}

.mt-sl64 {
  margin-top: 4rem !important;
}

.mt-sl72 {
  margin-top: 4.5rem !important;
}

.mt-sl80 {
  margin-top: 5rem !important;
}

.mt-sl88 {
  margin-top: 5.5rem !important;
}

.mt-sl96 {
  margin-top: 6rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-sl4 {
  margin-right: .25rem !important;
}

.me-sl8 {
  margin-right: .5rem !important;
}

.me-sl12 {
  margin-right: .75rem !important;
}

.me-sl16 {
  margin-right: 1rem !important;
}

.me-sl24 {
  margin-right: 1.5rem !important;
}

.me-sl32 {
  margin-right: 2rem !important;
}

.me-sl40 {
  margin-right: 2.5rem !important;
}

.me-sl48 {
  margin-right: 3rem !important;
}

.me-sl56 {
  margin-right: 3.5rem !important;
}

.me-sl64 {
  margin-right: 4rem !important;
}

.me-sl72 {
  margin-right: 4.5rem !important;
}

.me-sl80 {
  margin-right: 5rem !important;
}

.me-sl88 {
  margin-right: 5.5rem !important;
}

.me-sl96 {
  margin-right: 6rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-sl4 {
  margin-bottom: .25rem !important;
}

.mb-sl8 {
  margin-bottom: .5rem !important;
}

.mb-sl12 {
  margin-bottom: .75rem !important;
}

.mb-sl16 {
  margin-bottom: 1rem !important;
}

.mb-sl24 {
  margin-bottom: 1.5rem !important;
}

.mb-sl32 {
  margin-bottom: 2rem !important;
}

.mb-sl40 {
  margin-bottom: 2.5rem !important;
}

.mb-sl48 {
  margin-bottom: 3rem !important;
}

.mb-sl56 {
  margin-bottom: 3.5rem !important;
}

.mb-sl64 {
  margin-bottom: 4rem !important;
}

.mb-sl72 {
  margin-bottom: 4.5rem !important;
}

.mb-sl80 {
  margin-bottom: 5rem !important;
}

.mb-sl88 {
  margin-bottom: 5.5rem !important;
}

.mb-sl96 {
  margin-bottom: 6rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-sl4 {
  margin-left: .25rem !important;
}

.ms-sl8 {
  margin-left: .5rem !important;
}

.ms-sl12 {
  margin-left: .75rem !important;
}

.ms-sl16 {
  margin-left: 1rem !important;
}

.ms-sl24 {
  margin-left: 1.5rem !important;
}

.ms-sl32 {
  margin-left: 2rem !important;
}

.ms-sl40 {
  margin-left: 2.5rem !important;
}

.ms-sl48 {
  margin-left: 3rem !important;
}

.ms-sl56 {
  margin-left: 3.5rem !important;
}

.ms-sl64 {
  margin-left: 4rem !important;
}

.ms-sl72 {
  margin-left: 4.5rem !important;
}

.ms-sl80 {
  margin-left: 5rem !important;
}

.ms-sl88 {
  margin-left: 5.5rem !important;
}

.ms-sl96 {
  margin-left: 6rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-sl4 {
  padding: .25rem !important;
}

.p-sl8 {
  padding: .5rem !important;
}

.p-sl12 {
  padding: .75rem !important;
}

.p-sl16 {
  padding: 1rem !important;
}

.p-sl24 {
  padding: 1.5rem !important;
}

.p-sl32 {
  padding: 2rem !important;
}

.p-sl40 {
  padding: 2.5rem !important;
}

.p-sl48 {
  padding: 3rem !important;
}

.p-sl56 {
  padding: 3.5rem !important;
}

.p-sl64 {
  padding: 4rem !important;
}

.p-sl72 {
  padding: 4.5rem !important;
}

.p-sl80 {
  padding: 5rem !important;
}

.p-sl88 {
  padding: 5.5rem !important;
}

.p-sl96 {
  padding: 6rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-sl4 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-sl8 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-sl12 {
  padding-left: .75rem !important;
  padding-right: .75rem !important;
}

.px-sl16 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-sl24 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-sl32 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-sl40 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.px-sl48 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-sl56 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.px-sl64 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.px-sl72 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}

.px-sl80 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.px-sl88 {
  padding-left: 5.5rem !important;
  padding-right: 5.5rem !important;
}

.px-sl96 {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-sl4 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-sl8 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-sl12 {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
}

.py-sl16 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-sl24 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-sl32 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-sl40 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-sl48 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-sl56 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-sl64 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-sl72 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-sl80 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-sl88 {
  padding-top: 5.5rem !important;
  padding-bottom: 5.5rem !important;
}

.py-sl96 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-sl4 {
  padding-top: .25rem !important;
}

.pt-sl8 {
  padding-top: .5rem !important;
}

.pt-sl12 {
  padding-top: .75rem !important;
}

.pt-sl16 {
  padding-top: 1rem !important;
}

.pt-sl24 {
  padding-top: 1.5rem !important;
}

.pt-sl32 {
  padding-top: 2rem !important;
}

.pt-sl40 {
  padding-top: 2.5rem !important;
}

.pt-sl48 {
  padding-top: 3rem !important;
}

.pt-sl56 {
  padding-top: 3.5rem !important;
}

.pt-sl64 {
  padding-top: 4rem !important;
}

.pt-sl72 {
  padding-top: 4.5rem !important;
}

.pt-sl80 {
  padding-top: 5rem !important;
}

.pt-sl88 {
  padding-top: 5.5rem !important;
}

.pt-sl96 {
  padding-top: 6rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-sl4 {
  padding-right: .25rem !important;
}

.pe-sl8 {
  padding-right: .5rem !important;
}

.pe-sl12 {
  padding-right: .75rem !important;
}

.pe-sl16 {
  padding-right: 1rem !important;
}

.pe-sl24 {
  padding-right: 1.5rem !important;
}

.pe-sl32 {
  padding-right: 2rem !important;
}

.pe-sl40 {
  padding-right: 2.5rem !important;
}

.pe-sl48 {
  padding-right: 3rem !important;
}

.pe-sl56 {
  padding-right: 3.5rem !important;
}

.pe-sl64 {
  padding-right: 4rem !important;
}

.pe-sl72 {
  padding-right: 4.5rem !important;
}

.pe-sl80 {
  padding-right: 5rem !important;
}

.pe-sl88 {
  padding-right: 5.5rem !important;
}

.pe-sl96 {
  padding-right: 6rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-sl4 {
  padding-bottom: .25rem !important;
}

.pb-sl8 {
  padding-bottom: .5rem !important;
}

.pb-sl12 {
  padding-bottom: .75rem !important;
}

.pb-sl16 {
  padding-bottom: 1rem !important;
}

.pb-sl24 {
  padding-bottom: 1.5rem !important;
}

.pb-sl32 {
  padding-bottom: 2rem !important;
}

.pb-sl40 {
  padding-bottom: 2.5rem !important;
}

.pb-sl48 {
  padding-bottom: 3rem !important;
}

.pb-sl56 {
  padding-bottom: 3.5rem !important;
}

.pb-sl64 {
  padding-bottom: 4rem !important;
}

.pb-sl72 {
  padding-bottom: 4.5rem !important;
}

.pb-sl80 {
  padding-bottom: 5rem !important;
}

.pb-sl88 {
  padding-bottom: 5.5rem !important;
}

.pb-sl96 {
  padding-bottom: 6rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-sl4 {
  padding-left: .25rem !important;
}

.ps-sl8 {
  padding-left: .5rem !important;
}

.ps-sl12 {
  padding-left: .75rem !important;
}

.ps-sl16 {
  padding-left: 1rem !important;
}

.ps-sl24 {
  padding-left: 1.5rem !important;
}

.ps-sl32 {
  padding-left: 2rem !important;
}

.ps-sl40 {
  padding-left: 2.5rem !important;
}

.ps-sl48 {
  padding-left: 3rem !important;
}

.ps-sl56 {
  padding-left: 3.5rem !important;
}

.ps-sl64 {
  padding-left: 4rem !important;
}

.ps-sl72 {
  padding-left: 4.5rem !important;
}

.ps-sl80 {
  padding-left: 5rem !important;
}

.ps-sl88 {
  padding-left: 5.5rem !important;
}

.ps-sl96 {
  padding-left: 6rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-sl4 {
  gap: .25rem !important;
}

.gap-sl8 {
  gap: .5rem !important;
}

.gap-sl12 {
  gap: .75rem !important;
}

.gap-sl16 {
  gap: 1rem !important;
}

.gap-sl24 {
  gap: 1.5rem !important;
}

.gap-sl32 {
  gap: 2rem !important;
}

.gap-sl40 {
  gap: 2.5rem !important;
}

.gap-sl48 {
  gap: 3rem !important;
}

.gap-sl56 {
  gap: 3.5rem !important;
}

.gap-sl64 {
  gap: 4rem !important;
}

.gap-sl72 {
  gap: 4.5rem !important;
}

.gap-sl80 {
  gap: 5rem !important;
}

.gap-sl88 {
  gap: 5.5rem !important;
}

.gap-sl96 {
  gap: 6rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: .25rem !important;
}

.row-gap-2 {
  row-gap: .5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.row-gap-sl4 {
  row-gap: .25rem !important;
}

.row-gap-sl8 {
  row-gap: .5rem !important;
}

.row-gap-sl12 {
  row-gap: .75rem !important;
}

.row-gap-sl16 {
  row-gap: 1rem !important;
}

.row-gap-sl24 {
  row-gap: 1.5rem !important;
}

.row-gap-sl32 {
  row-gap: 2rem !important;
}

.row-gap-sl40 {
  row-gap: 2.5rem !important;
}

.row-gap-sl48 {
  row-gap: 3rem !important;
}

.row-gap-sl56 {
  row-gap: 3.5rem !important;
}

.row-gap-sl64 {
  row-gap: 4rem !important;
}

.row-gap-sl72 {
  row-gap: 4.5rem !important;
}

.row-gap-sl80 {
  row-gap: 5rem !important;
}

.row-gap-sl88 {
  row-gap: 5.5rem !important;
}

.row-gap-sl96 {
  row-gap: 6rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: .25rem !important;
}

.column-gap-2 {
  column-gap: .5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.column-gap-sl4 {
  column-gap: .25rem !important;
}

.column-gap-sl8 {
  column-gap: .5rem !important;
}

.column-gap-sl12 {
  column-gap: .75rem !important;
}

.column-gap-sl16 {
  column-gap: 1rem !important;
}

.column-gap-sl24 {
  column-gap: 1.5rem !important;
}

.column-gap-sl32 {
  column-gap: 2rem !important;
}

.column-gap-sl40 {
  column-gap: 2.5rem !important;
}

.column-gap-sl48 {
  column-gap: 3rem !important;
}

.column-gap-sl56 {
  column-gap: 3.5rem !important;
}

.column-gap-sl64 {
  column-gap: 4rem !important;
}

.column-gap-sl72 {
  column-gap: 4.5rem !important;
}

.column-gap-sl80 {
  column-gap: 5rem !important;
}

.column-gap-sl88 {
  column-gap: 5.5rem !important;
}

.column-gap-sl96 {
  column-gap: 6rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: .25;
}

.text-opacity-50 {
  --bs-text-opacity: .5;
}

.text-opacity-75 {
  --bs-text-opacity: .75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10, .link-opacity-10-hover:hover {
  --bs-link-opacity: .1;
}

.link-opacity-25, .link-opacity-25-hover:hover {
  --bs-link-opacity: .25;
}

.link-opacity-50, .link-opacity-50-hover:hover {
  --bs-link-opacity: .5;
}

.link-opacity-75, .link-opacity-75-hover:hover {
  --bs-link-opacity: .75;
}

.link-opacity-100, .link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1, .link-offset-1-hover:hover {
  text-underline-offset: .125em !important;
}

.link-offset-2, .link-offset-2-hover:hover {
  text-underline-offset: .25em !important;
}

.link-offset-3, .link-offset-3-hover:hover {
  text-underline-offset: .375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0, .link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10, .link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: .1;
}

.link-underline-opacity-25, .link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: .25;
}

.link-underline-opacity-50, .link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: .5;
}

.link-underline-opacity-75, .link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: .75;
}

.link-underline-opacity-100, .link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (width >= 600px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-sl4 {
    margin: .25rem !important;
  }

  .m-sm-sl8 {
    margin: .5rem !important;
  }

  .m-sm-sl12 {
    margin: .75rem !important;
  }

  .m-sm-sl16 {
    margin: 1rem !important;
  }

  .m-sm-sl24 {
    margin: 1.5rem !important;
  }

  .m-sm-sl32 {
    margin: 2rem !important;
  }

  .m-sm-sl40 {
    margin: 2.5rem !important;
  }

  .m-sm-sl48 {
    margin: 3rem !important;
  }

  .m-sm-sl56 {
    margin: 3.5rem !important;
  }

  .m-sm-sl64 {
    margin: 4rem !important;
  }

  .m-sm-sl72 {
    margin: 4.5rem !important;
  }

  .m-sm-sl80 {
    margin: 5rem !important;
  }

  .m-sm-sl88 {
    margin: 5.5rem !important;
  }

  .m-sm-sl96 {
    margin: 6rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-sl4 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-sl8 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-sl12 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-sm-sl16 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-sl24 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-sl32 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-sm-sl40 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-sm-sl48 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-sl56 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-sm-sl64 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-sm-sl72 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-sm-sl80 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-sm-sl88 {
    margin-left: 5.5rem !important;
    margin-right: 5.5rem !important;
  }

  .mx-sm-sl96 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-sl4 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-sl8 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-sl12 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-sm-sl16 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-sl24 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-sl32 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-sm-sl40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-sm-sl48 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-sl56 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-sl64 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-sl72 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-sl80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-sl88 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .my-sm-sl96 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-sl4 {
    margin-top: .25rem !important;
  }

  .mt-sm-sl8 {
    margin-top: .5rem !important;
  }

  .mt-sm-sl12 {
    margin-top: .75rem !important;
  }

  .mt-sm-sl16 {
    margin-top: 1rem !important;
  }

  .mt-sm-sl24 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-sl32 {
    margin-top: 2rem !important;
  }

  .mt-sm-sl40 {
    margin-top: 2.5rem !important;
  }

  .mt-sm-sl48 {
    margin-top: 3rem !important;
  }

  .mt-sm-sl56 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-sl64 {
    margin-top: 4rem !important;
  }

  .mt-sm-sl72 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-sl80 {
    margin-top: 5rem !important;
  }

  .mt-sm-sl88 {
    margin-top: 5.5rem !important;
  }

  .mt-sm-sl96 {
    margin-top: 6rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-sl4 {
    margin-right: .25rem !important;
  }

  .me-sm-sl8 {
    margin-right: .5rem !important;
  }

  .me-sm-sl12 {
    margin-right: .75rem !important;
  }

  .me-sm-sl16 {
    margin-right: 1rem !important;
  }

  .me-sm-sl24 {
    margin-right: 1.5rem !important;
  }

  .me-sm-sl32 {
    margin-right: 2rem !important;
  }

  .me-sm-sl40 {
    margin-right: 2.5rem !important;
  }

  .me-sm-sl48 {
    margin-right: 3rem !important;
  }

  .me-sm-sl56 {
    margin-right: 3.5rem !important;
  }

  .me-sm-sl64 {
    margin-right: 4rem !important;
  }

  .me-sm-sl72 {
    margin-right: 4.5rem !important;
  }

  .me-sm-sl80 {
    margin-right: 5rem !important;
  }

  .me-sm-sl88 {
    margin-right: 5.5rem !important;
  }

  .me-sm-sl96 {
    margin-right: 6rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-sl4 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-sl8 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-sl12 {
    margin-bottom: .75rem !important;
  }

  .mb-sm-sl16 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-sl24 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-sl32 {
    margin-bottom: 2rem !important;
  }

  .mb-sm-sl40 {
    margin-bottom: 2.5rem !important;
  }

  .mb-sm-sl48 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-sl56 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-sl64 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-sl72 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-sl80 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-sl88 {
    margin-bottom: 5.5rem !important;
  }

  .mb-sm-sl96 {
    margin-bottom: 6rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-sl4 {
    margin-left: .25rem !important;
  }

  .ms-sm-sl8 {
    margin-left: .5rem !important;
  }

  .ms-sm-sl12 {
    margin-left: .75rem !important;
  }

  .ms-sm-sl16 {
    margin-left: 1rem !important;
  }

  .ms-sm-sl24 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-sl32 {
    margin-left: 2rem !important;
  }

  .ms-sm-sl40 {
    margin-left: 2.5rem !important;
  }

  .ms-sm-sl48 {
    margin-left: 3rem !important;
  }

  .ms-sm-sl56 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-sl64 {
    margin-left: 4rem !important;
  }

  .ms-sm-sl72 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-sl80 {
    margin-left: 5rem !important;
  }

  .ms-sm-sl88 {
    margin-left: 5.5rem !important;
  }

  .ms-sm-sl96 {
    margin-left: 6rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .p-sm-sl4 {
    padding: .25rem !important;
  }

  .p-sm-sl8 {
    padding: .5rem !important;
  }

  .p-sm-sl12 {
    padding: .75rem !important;
  }

  .p-sm-sl16 {
    padding: 1rem !important;
  }

  .p-sm-sl24 {
    padding: 1.5rem !important;
  }

  .p-sm-sl32 {
    padding: 2rem !important;
  }

  .p-sm-sl40 {
    padding: 2.5rem !important;
  }

  .p-sm-sl48 {
    padding: 3rem !important;
  }

  .p-sm-sl56 {
    padding: 3.5rem !important;
  }

  .p-sm-sl64 {
    padding: 4rem !important;
  }

  .p-sm-sl72 {
    padding: 4.5rem !important;
  }

  .p-sm-sl80 {
    padding: 5rem !important;
  }

  .p-sm-sl88 {
    padding: 5.5rem !important;
  }

  .p-sm-sl96 {
    padding: 6rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-sm-sl4 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-sl8 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-sl12 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-sm-sl16 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-sl24 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-sl32 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-sm-sl40 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-sm-sl48 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-sm-sl56 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-sm-sl64 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-sm-sl72 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-sm-sl80 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .px-sm-sl88 {
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
  }

  .px-sm-sl96 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-sl4 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-sl8 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-sl12 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-sm-sl16 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-sl24 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-sl32 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-sm-sl40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-sm-sl48 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-sl56 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-sl64 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-sm-sl72 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-sm-sl80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-sm-sl88 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .py-sm-sl96 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pt-sm-sl4 {
    padding-top: .25rem !important;
  }

  .pt-sm-sl8 {
    padding-top: .5rem !important;
  }

  .pt-sm-sl12 {
    padding-top: .75rem !important;
  }

  .pt-sm-sl16 {
    padding-top: 1rem !important;
  }

  .pt-sm-sl24 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-sl32 {
    padding-top: 2rem !important;
  }

  .pt-sm-sl40 {
    padding-top: 2.5rem !important;
  }

  .pt-sm-sl48 {
    padding-top: 3rem !important;
  }

  .pt-sm-sl56 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-sl64 {
    padding-top: 4rem !important;
  }

  .pt-sm-sl72 {
    padding-top: 4.5rem !important;
  }

  .pt-sm-sl80 {
    padding-top: 5rem !important;
  }

  .pt-sm-sl88 {
    padding-top: 5.5rem !important;
  }

  .pt-sm-sl96 {
    padding-top: 6rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pe-sm-sl4 {
    padding-right: .25rem !important;
  }

  .pe-sm-sl8 {
    padding-right: .5rem !important;
  }

  .pe-sm-sl12 {
    padding-right: .75rem !important;
  }

  .pe-sm-sl16 {
    padding-right: 1rem !important;
  }

  .pe-sm-sl24 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-sl32 {
    padding-right: 2rem !important;
  }

  .pe-sm-sl40 {
    padding-right: 2.5rem !important;
  }

  .pe-sm-sl48 {
    padding-right: 3rem !important;
  }

  .pe-sm-sl56 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-sl64 {
    padding-right: 4rem !important;
  }

  .pe-sm-sl72 {
    padding-right: 4.5rem !important;
  }

  .pe-sm-sl80 {
    padding-right: 5rem !important;
  }

  .pe-sm-sl88 {
    padding-right: 5.5rem !important;
  }

  .pe-sm-sl96 {
    padding-right: 6rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-sl4 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-sl8 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-sl12 {
    padding-bottom: .75rem !important;
  }

  .pb-sm-sl16 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-sl24 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-sl32 {
    padding-bottom: 2rem !important;
  }

  .pb-sm-sl40 {
    padding-bottom: 2.5rem !important;
  }

  .pb-sm-sl48 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-sl56 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-sl64 {
    padding-bottom: 4rem !important;
  }

  .pb-sm-sl72 {
    padding-bottom: 4.5rem !important;
  }

  .pb-sm-sl80 {
    padding-bottom: 5rem !important;
  }

  .pb-sm-sl88 {
    padding-bottom: 5.5rem !important;
  }

  .pb-sm-sl96 {
    padding-bottom: 6rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .ps-sm-sl4 {
    padding-left: .25rem !important;
  }

  .ps-sm-sl8 {
    padding-left: .5rem !important;
  }

  .ps-sm-sl12 {
    padding-left: .75rem !important;
  }

  .ps-sm-sl16 {
    padding-left: 1rem !important;
  }

  .ps-sm-sl24 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-sl32 {
    padding-left: 2rem !important;
  }

  .ps-sm-sl40 {
    padding-left: 2.5rem !important;
  }

  .ps-sm-sl48 {
    padding-left: 3rem !important;
  }

  .ps-sm-sl56 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-sl64 {
    padding-left: 4rem !important;
  }

  .ps-sm-sl72 {
    padding-left: 4.5rem !important;
  }

  .ps-sm-sl80 {
    padding-left: 5rem !important;
  }

  .ps-sm-sl88 {
    padding-left: 5.5rem !important;
  }

  .ps-sm-sl96 {
    padding-left: 6rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .gap-sm-sl4 {
    gap: .25rem !important;
  }

  .gap-sm-sl8 {
    gap: .5rem !important;
  }

  .gap-sm-sl12 {
    gap: .75rem !important;
  }

  .gap-sm-sl16 {
    gap: 1rem !important;
  }

  .gap-sm-sl24 {
    gap: 1.5rem !important;
  }

  .gap-sm-sl32 {
    gap: 2rem !important;
  }

  .gap-sm-sl40 {
    gap: 2.5rem !important;
  }

  .gap-sm-sl48 {
    gap: 3rem !important;
  }

  .gap-sm-sl56 {
    gap: 3.5rem !important;
  }

  .gap-sm-sl64 {
    gap: 4rem !important;
  }

  .gap-sm-sl72 {
    gap: 4.5rem !important;
  }

  .gap-sm-sl80 {
    gap: 5rem !important;
  }

  .gap-sm-sl88 {
    gap: 5.5rem !important;
  }

  .gap-sm-sl96 {
    gap: 6rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: .25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: .5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }

  .row-gap-sm-sl4 {
    row-gap: .25rem !important;
  }

  .row-gap-sm-sl8 {
    row-gap: .5rem !important;
  }

  .row-gap-sm-sl12 {
    row-gap: .75rem !important;
  }

  .row-gap-sm-sl16 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-sl24 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-sl32 {
    row-gap: 2rem !important;
  }

  .row-gap-sm-sl40 {
    row-gap: 2.5rem !important;
  }

  .row-gap-sm-sl48 {
    row-gap: 3rem !important;
  }

  .row-gap-sm-sl56 {
    row-gap: 3.5rem !important;
  }

  .row-gap-sm-sl64 {
    row-gap: 4rem !important;
  }

  .row-gap-sm-sl72 {
    row-gap: 4.5rem !important;
  }

  .row-gap-sm-sl80 {
    row-gap: 5rem !important;
  }

  .row-gap-sm-sl88 {
    row-gap: 5.5rem !important;
  }

  .row-gap-sm-sl96 {
    row-gap: 6rem !important;
  }

  .column-gap-sm-0 {
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    column-gap: .25rem !important;
  }

  .column-gap-sm-2 {
    column-gap: .5rem !important;
  }

  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }

  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }

  .column-gap-sm-sl4 {
    column-gap: .25rem !important;
  }

  .column-gap-sm-sl8 {
    column-gap: .5rem !important;
  }

  .column-gap-sm-sl12 {
    column-gap: .75rem !important;
  }

  .column-gap-sm-sl16 {
    column-gap: 1rem !important;
  }

  .column-gap-sm-sl24 {
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-sl32 {
    column-gap: 2rem !important;
  }

  .column-gap-sm-sl40 {
    column-gap: 2.5rem !important;
  }

  .column-gap-sm-sl48 {
    column-gap: 3rem !important;
  }

  .column-gap-sm-sl56 {
    column-gap: 3.5rem !important;
  }

  .column-gap-sm-sl64 {
    column-gap: 4rem !important;
  }

  .column-gap-sm-sl72 {
    column-gap: 4.5rem !important;
  }

  .column-gap-sm-sl80 {
    column-gap: 5rem !important;
  }

  .column-gap-sm-sl88 {
    column-gap: 5.5rem !important;
  }

  .column-gap-sm-sl96 {
    column-gap: 6rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (width >= 905px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-sl4 {
    margin: .25rem !important;
  }

  .m-md-sl8 {
    margin: .5rem !important;
  }

  .m-md-sl12 {
    margin: .75rem !important;
  }

  .m-md-sl16 {
    margin: 1rem !important;
  }

  .m-md-sl24 {
    margin: 1.5rem !important;
  }

  .m-md-sl32 {
    margin: 2rem !important;
  }

  .m-md-sl40 {
    margin: 2.5rem !important;
  }

  .m-md-sl48 {
    margin: 3rem !important;
  }

  .m-md-sl56 {
    margin: 3.5rem !important;
  }

  .m-md-sl64 {
    margin: 4rem !important;
  }

  .m-md-sl72 {
    margin: 4.5rem !important;
  }

  .m-md-sl80 {
    margin: 5rem !important;
  }

  .m-md-sl88 {
    margin: 5.5rem !important;
  }

  .m-md-sl96 {
    margin: 6rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-sl4 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-sl8 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-sl12 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-md-sl16 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-sl24 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-sl32 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-md-sl40 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-md-sl48 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-sl56 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-md-sl64 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-md-sl72 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-md-sl80 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-md-sl88 {
    margin-left: 5.5rem !important;
    margin-right: 5.5rem !important;
  }

  .mx-md-sl96 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-sl4 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-sl8 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-sl12 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-md-sl16 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-sl24 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-sl32 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-md-sl40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-md-sl48 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-sl56 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-sl64 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-sl72 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-sl80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-sl88 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .my-md-sl96 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-sl4 {
    margin-top: .25rem !important;
  }

  .mt-md-sl8 {
    margin-top: .5rem !important;
  }

  .mt-md-sl12 {
    margin-top: .75rem !important;
  }

  .mt-md-sl16 {
    margin-top: 1rem !important;
  }

  .mt-md-sl24 {
    margin-top: 1.5rem !important;
  }

  .mt-md-sl32 {
    margin-top: 2rem !important;
  }

  .mt-md-sl40 {
    margin-top: 2.5rem !important;
  }

  .mt-md-sl48 {
    margin-top: 3rem !important;
  }

  .mt-md-sl56 {
    margin-top: 3.5rem !important;
  }

  .mt-md-sl64 {
    margin-top: 4rem !important;
  }

  .mt-md-sl72 {
    margin-top: 4.5rem !important;
  }

  .mt-md-sl80 {
    margin-top: 5rem !important;
  }

  .mt-md-sl88 {
    margin-top: 5.5rem !important;
  }

  .mt-md-sl96 {
    margin-top: 6rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-sl4 {
    margin-right: .25rem !important;
  }

  .me-md-sl8 {
    margin-right: .5rem !important;
  }

  .me-md-sl12 {
    margin-right: .75rem !important;
  }

  .me-md-sl16 {
    margin-right: 1rem !important;
  }

  .me-md-sl24 {
    margin-right: 1.5rem !important;
  }

  .me-md-sl32 {
    margin-right: 2rem !important;
  }

  .me-md-sl40 {
    margin-right: 2.5rem !important;
  }

  .me-md-sl48 {
    margin-right: 3rem !important;
  }

  .me-md-sl56 {
    margin-right: 3.5rem !important;
  }

  .me-md-sl64 {
    margin-right: 4rem !important;
  }

  .me-md-sl72 {
    margin-right: 4.5rem !important;
  }

  .me-md-sl80 {
    margin-right: 5rem !important;
  }

  .me-md-sl88 {
    margin-right: 5.5rem !important;
  }

  .me-md-sl96 {
    margin-right: 6rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-sl4 {
    margin-bottom: .25rem !important;
  }

  .mb-md-sl8 {
    margin-bottom: .5rem !important;
  }

  .mb-md-sl12 {
    margin-bottom: .75rem !important;
  }

  .mb-md-sl16 {
    margin-bottom: 1rem !important;
  }

  .mb-md-sl24 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-sl32 {
    margin-bottom: 2rem !important;
  }

  .mb-md-sl40 {
    margin-bottom: 2.5rem !important;
  }

  .mb-md-sl48 {
    margin-bottom: 3rem !important;
  }

  .mb-md-sl56 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-sl64 {
    margin-bottom: 4rem !important;
  }

  .mb-md-sl72 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-sl80 {
    margin-bottom: 5rem !important;
  }

  .mb-md-sl88 {
    margin-bottom: 5.5rem !important;
  }

  .mb-md-sl96 {
    margin-bottom: 6rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-sl4 {
    margin-left: .25rem !important;
  }

  .ms-md-sl8 {
    margin-left: .5rem !important;
  }

  .ms-md-sl12 {
    margin-left: .75rem !important;
  }

  .ms-md-sl16 {
    margin-left: 1rem !important;
  }

  .ms-md-sl24 {
    margin-left: 1.5rem !important;
  }

  .ms-md-sl32 {
    margin-left: 2rem !important;
  }

  .ms-md-sl40 {
    margin-left: 2.5rem !important;
  }

  .ms-md-sl48 {
    margin-left: 3rem !important;
  }

  .ms-md-sl56 {
    margin-left: 3.5rem !important;
  }

  .ms-md-sl64 {
    margin-left: 4rem !important;
  }

  .ms-md-sl72 {
    margin-left: 4.5rem !important;
  }

  .ms-md-sl80 {
    margin-left: 5rem !important;
  }

  .ms-md-sl88 {
    margin-left: 5.5rem !important;
  }

  .ms-md-sl96 {
    margin-left: 6rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .p-md-sl4 {
    padding: .25rem !important;
  }

  .p-md-sl8 {
    padding: .5rem !important;
  }

  .p-md-sl12 {
    padding: .75rem !important;
  }

  .p-md-sl16 {
    padding: 1rem !important;
  }

  .p-md-sl24 {
    padding: 1.5rem !important;
  }

  .p-md-sl32 {
    padding: 2rem !important;
  }

  .p-md-sl40 {
    padding: 2.5rem !important;
  }

  .p-md-sl48 {
    padding: 3rem !important;
  }

  .p-md-sl56 {
    padding: 3.5rem !important;
  }

  .p-md-sl64 {
    padding: 4rem !important;
  }

  .p-md-sl72 {
    padding: 4.5rem !important;
  }

  .p-md-sl80 {
    padding: 5rem !important;
  }

  .p-md-sl88 {
    padding: 5.5rem !important;
  }

  .p-md-sl96 {
    padding: 6rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-md-sl4 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-sl8 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-sl12 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-md-sl16 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-sl24 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-sl32 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-md-sl40 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-md-sl48 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-md-sl56 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-md-sl64 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-md-sl72 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-md-sl80 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .px-md-sl88 {
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
  }

  .px-md-sl96 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-sl4 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-sl8 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-sl12 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-md-sl16 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-sl24 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-sl32 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-md-sl40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-md-sl48 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-sl56 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-sl64 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-md-sl72 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-md-sl80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-md-sl88 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .py-md-sl96 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pt-md-sl4 {
    padding-top: .25rem !important;
  }

  .pt-md-sl8 {
    padding-top: .5rem !important;
  }

  .pt-md-sl12 {
    padding-top: .75rem !important;
  }

  .pt-md-sl16 {
    padding-top: 1rem !important;
  }

  .pt-md-sl24 {
    padding-top: 1.5rem !important;
  }

  .pt-md-sl32 {
    padding-top: 2rem !important;
  }

  .pt-md-sl40 {
    padding-top: 2.5rem !important;
  }

  .pt-md-sl48 {
    padding-top: 3rem !important;
  }

  .pt-md-sl56 {
    padding-top: 3.5rem !important;
  }

  .pt-md-sl64 {
    padding-top: 4rem !important;
  }

  .pt-md-sl72 {
    padding-top: 4.5rem !important;
  }

  .pt-md-sl80 {
    padding-top: 5rem !important;
  }

  .pt-md-sl88 {
    padding-top: 5.5rem !important;
  }

  .pt-md-sl96 {
    padding-top: 6rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pe-md-sl4 {
    padding-right: .25rem !important;
  }

  .pe-md-sl8 {
    padding-right: .5rem !important;
  }

  .pe-md-sl12 {
    padding-right: .75rem !important;
  }

  .pe-md-sl16 {
    padding-right: 1rem !important;
  }

  .pe-md-sl24 {
    padding-right: 1.5rem !important;
  }

  .pe-md-sl32 {
    padding-right: 2rem !important;
  }

  .pe-md-sl40 {
    padding-right: 2.5rem !important;
  }

  .pe-md-sl48 {
    padding-right: 3rem !important;
  }

  .pe-md-sl56 {
    padding-right: 3.5rem !important;
  }

  .pe-md-sl64 {
    padding-right: 4rem !important;
  }

  .pe-md-sl72 {
    padding-right: 4.5rem !important;
  }

  .pe-md-sl80 {
    padding-right: 5rem !important;
  }

  .pe-md-sl88 {
    padding-right: 5.5rem !important;
  }

  .pe-md-sl96 {
    padding-right: 6rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .pb-md-sl4 {
    padding-bottom: .25rem !important;
  }

  .pb-md-sl8 {
    padding-bottom: .5rem !important;
  }

  .pb-md-sl12 {
    padding-bottom: .75rem !important;
  }

  .pb-md-sl16 {
    padding-bottom: 1rem !important;
  }

  .pb-md-sl24 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-sl32 {
    padding-bottom: 2rem !important;
  }

  .pb-md-sl40 {
    padding-bottom: 2.5rem !important;
  }

  .pb-md-sl48 {
    padding-bottom: 3rem !important;
  }

  .pb-md-sl56 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-sl64 {
    padding-bottom: 4rem !important;
  }

  .pb-md-sl72 {
    padding-bottom: 4.5rem !important;
  }

  .pb-md-sl80 {
    padding-bottom: 5rem !important;
  }

  .pb-md-sl88 {
    padding-bottom: 5.5rem !important;
  }

  .pb-md-sl96 {
    padding-bottom: 6rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .ps-md-sl4 {
    padding-left: .25rem !important;
  }

  .ps-md-sl8 {
    padding-left: .5rem !important;
  }

  .ps-md-sl12 {
    padding-left: .75rem !important;
  }

  .ps-md-sl16 {
    padding-left: 1rem !important;
  }

  .ps-md-sl24 {
    padding-left: 1.5rem !important;
  }

  .ps-md-sl32 {
    padding-left: 2rem !important;
  }

  .ps-md-sl40 {
    padding-left: 2.5rem !important;
  }

  .ps-md-sl48 {
    padding-left: 3rem !important;
  }

  .ps-md-sl56 {
    padding-left: 3.5rem !important;
  }

  .ps-md-sl64 {
    padding-left: 4rem !important;
  }

  .ps-md-sl72 {
    padding-left: 4.5rem !important;
  }

  .ps-md-sl80 {
    padding-left: 5rem !important;
  }

  .ps-md-sl88 {
    padding-left: 5.5rem !important;
  }

  .ps-md-sl96 {
    padding-left: 6rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .gap-md-sl4 {
    gap: .25rem !important;
  }

  .gap-md-sl8 {
    gap: .5rem !important;
  }

  .gap-md-sl12 {
    gap: .75rem !important;
  }

  .gap-md-sl16 {
    gap: 1rem !important;
  }

  .gap-md-sl24 {
    gap: 1.5rem !important;
  }

  .gap-md-sl32 {
    gap: 2rem !important;
  }

  .gap-md-sl40 {
    gap: 2.5rem !important;
  }

  .gap-md-sl48 {
    gap: 3rem !important;
  }

  .gap-md-sl56 {
    gap: 3.5rem !important;
  }

  .gap-md-sl64 {
    gap: 4rem !important;
  }

  .gap-md-sl72 {
    gap: 4.5rem !important;
  }

  .gap-md-sl80 {
    gap: 5rem !important;
  }

  .gap-md-sl88 {
    gap: 5.5rem !important;
  }

  .gap-md-sl96 {
    gap: 6rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: .25rem !important;
  }

  .row-gap-md-2 {
    row-gap: .5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 1rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-5 {
    row-gap: 3rem !important;
  }

  .row-gap-md-sl4 {
    row-gap: .25rem !important;
  }

  .row-gap-md-sl8 {
    row-gap: .5rem !important;
  }

  .row-gap-md-sl12 {
    row-gap: .75rem !important;
  }

  .row-gap-md-sl16 {
    row-gap: 1rem !important;
  }

  .row-gap-md-sl24 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-sl32 {
    row-gap: 2rem !important;
  }

  .row-gap-md-sl40 {
    row-gap: 2.5rem !important;
  }

  .row-gap-md-sl48 {
    row-gap: 3rem !important;
  }

  .row-gap-md-sl56 {
    row-gap: 3.5rem !important;
  }

  .row-gap-md-sl64 {
    row-gap: 4rem !important;
  }

  .row-gap-md-sl72 {
    row-gap: 4.5rem !important;
  }

  .row-gap-md-sl80 {
    row-gap: 5rem !important;
  }

  .row-gap-md-sl88 {
    row-gap: 5.5rem !important;
  }

  .row-gap-md-sl96 {
    row-gap: 6rem !important;
  }

  .column-gap-md-0 {
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    column-gap: .25rem !important;
  }

  .column-gap-md-2 {
    column-gap: .5rem !important;
  }

  .column-gap-md-3 {
    column-gap: 1rem !important;
  }

  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-md-5 {
    column-gap: 3rem !important;
  }

  .column-gap-md-sl4 {
    column-gap: .25rem !important;
  }

  .column-gap-md-sl8 {
    column-gap: .5rem !important;
  }

  .column-gap-md-sl12 {
    column-gap: .75rem !important;
  }

  .column-gap-md-sl16 {
    column-gap: 1rem !important;
  }

  .column-gap-md-sl24 {
    column-gap: 1.5rem !important;
  }

  .column-gap-md-sl32 {
    column-gap: 2rem !important;
  }

  .column-gap-md-sl40 {
    column-gap: 2.5rem !important;
  }

  .column-gap-md-sl48 {
    column-gap: 3rem !important;
  }

  .column-gap-md-sl56 {
    column-gap: 3.5rem !important;
  }

  .column-gap-md-sl64 {
    column-gap: 4rem !important;
  }

  .column-gap-md-sl72 {
    column-gap: 4.5rem !important;
  }

  .column-gap-md-sl80 {
    column-gap: 5rem !important;
  }

  .column-gap-md-sl88 {
    column-gap: 5.5rem !important;
  }

  .column-gap-md-sl96 {
    column-gap: 6rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (width >= 1240px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-sl4 {
    margin: .25rem !important;
  }

  .m-lg-sl8 {
    margin: .5rem !important;
  }

  .m-lg-sl12 {
    margin: .75rem !important;
  }

  .m-lg-sl16 {
    margin: 1rem !important;
  }

  .m-lg-sl24 {
    margin: 1.5rem !important;
  }

  .m-lg-sl32 {
    margin: 2rem !important;
  }

  .m-lg-sl40 {
    margin: 2.5rem !important;
  }

  .m-lg-sl48 {
    margin: 3rem !important;
  }

  .m-lg-sl56 {
    margin: 3.5rem !important;
  }

  .m-lg-sl64 {
    margin: 4rem !important;
  }

  .m-lg-sl72 {
    margin: 4.5rem !important;
  }

  .m-lg-sl80 {
    margin: 5rem !important;
  }

  .m-lg-sl88 {
    margin: 5.5rem !important;
  }

  .m-lg-sl96 {
    margin: 6rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-sl4 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-sl8 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-sl12 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-lg-sl16 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-sl24 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-sl32 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-lg-sl40 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-lg-sl48 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-sl56 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-lg-sl64 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-lg-sl72 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-lg-sl80 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-lg-sl88 {
    margin-left: 5.5rem !important;
    margin-right: 5.5rem !important;
  }

  .mx-lg-sl96 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-sl4 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-sl8 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-sl12 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-lg-sl16 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-sl24 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-sl32 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-lg-sl40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-lg-sl48 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-sl56 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-sl64 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-sl72 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-sl80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-sl88 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .my-lg-sl96 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-sl4 {
    margin-top: .25rem !important;
  }

  .mt-lg-sl8 {
    margin-top: .5rem !important;
  }

  .mt-lg-sl12 {
    margin-top: .75rem !important;
  }

  .mt-lg-sl16 {
    margin-top: 1rem !important;
  }

  .mt-lg-sl24 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-sl32 {
    margin-top: 2rem !important;
  }

  .mt-lg-sl40 {
    margin-top: 2.5rem !important;
  }

  .mt-lg-sl48 {
    margin-top: 3rem !important;
  }

  .mt-lg-sl56 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-sl64 {
    margin-top: 4rem !important;
  }

  .mt-lg-sl72 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-sl80 {
    margin-top: 5rem !important;
  }

  .mt-lg-sl88 {
    margin-top: 5.5rem !important;
  }

  .mt-lg-sl96 {
    margin-top: 6rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-sl4 {
    margin-right: .25rem !important;
  }

  .me-lg-sl8 {
    margin-right: .5rem !important;
  }

  .me-lg-sl12 {
    margin-right: .75rem !important;
  }

  .me-lg-sl16 {
    margin-right: 1rem !important;
  }

  .me-lg-sl24 {
    margin-right: 1.5rem !important;
  }

  .me-lg-sl32 {
    margin-right: 2rem !important;
  }

  .me-lg-sl40 {
    margin-right: 2.5rem !important;
  }

  .me-lg-sl48 {
    margin-right: 3rem !important;
  }

  .me-lg-sl56 {
    margin-right: 3.5rem !important;
  }

  .me-lg-sl64 {
    margin-right: 4rem !important;
  }

  .me-lg-sl72 {
    margin-right: 4.5rem !important;
  }

  .me-lg-sl80 {
    margin-right: 5rem !important;
  }

  .me-lg-sl88 {
    margin-right: 5.5rem !important;
  }

  .me-lg-sl96 {
    margin-right: 6rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-sl4 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-sl8 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-sl12 {
    margin-bottom: .75rem !important;
  }

  .mb-lg-sl16 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-sl24 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-sl32 {
    margin-bottom: 2rem !important;
  }

  .mb-lg-sl40 {
    margin-bottom: 2.5rem !important;
  }

  .mb-lg-sl48 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-sl56 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-sl64 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-sl72 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-sl80 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-sl88 {
    margin-bottom: 5.5rem !important;
  }

  .mb-lg-sl96 {
    margin-bottom: 6rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-sl4 {
    margin-left: .25rem !important;
  }

  .ms-lg-sl8 {
    margin-left: .5rem !important;
  }

  .ms-lg-sl12 {
    margin-left: .75rem !important;
  }

  .ms-lg-sl16 {
    margin-left: 1rem !important;
  }

  .ms-lg-sl24 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-sl32 {
    margin-left: 2rem !important;
  }

  .ms-lg-sl40 {
    margin-left: 2.5rem !important;
  }

  .ms-lg-sl48 {
    margin-left: 3rem !important;
  }

  .ms-lg-sl56 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-sl64 {
    margin-left: 4rem !important;
  }

  .ms-lg-sl72 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-sl80 {
    margin-left: 5rem !important;
  }

  .ms-lg-sl88 {
    margin-left: 5.5rem !important;
  }

  .ms-lg-sl96 {
    margin-left: 6rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .p-lg-sl4 {
    padding: .25rem !important;
  }

  .p-lg-sl8 {
    padding: .5rem !important;
  }

  .p-lg-sl12 {
    padding: .75rem !important;
  }

  .p-lg-sl16 {
    padding: 1rem !important;
  }

  .p-lg-sl24 {
    padding: 1.5rem !important;
  }

  .p-lg-sl32 {
    padding: 2rem !important;
  }

  .p-lg-sl40 {
    padding: 2.5rem !important;
  }

  .p-lg-sl48 {
    padding: 3rem !important;
  }

  .p-lg-sl56 {
    padding: 3.5rem !important;
  }

  .p-lg-sl64 {
    padding: 4rem !important;
  }

  .p-lg-sl72 {
    padding: 4.5rem !important;
  }

  .p-lg-sl80 {
    padding: 5rem !important;
  }

  .p-lg-sl88 {
    padding: 5.5rem !important;
  }

  .p-lg-sl96 {
    padding: 6rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-lg-sl4 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-sl8 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-sl12 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-lg-sl16 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-sl24 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-sl32 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-lg-sl40 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-lg-sl48 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-lg-sl56 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-lg-sl64 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-lg-sl72 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-lg-sl80 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .px-lg-sl88 {
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
  }

  .px-lg-sl96 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-sl4 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-sl8 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-sl12 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-lg-sl16 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-sl24 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-sl32 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-lg-sl40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-lg-sl48 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-sl56 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-sl64 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-lg-sl72 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-lg-sl80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-lg-sl88 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .py-lg-sl96 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pt-lg-sl4 {
    padding-top: .25rem !important;
  }

  .pt-lg-sl8 {
    padding-top: .5rem !important;
  }

  .pt-lg-sl12 {
    padding-top: .75rem !important;
  }

  .pt-lg-sl16 {
    padding-top: 1rem !important;
  }

  .pt-lg-sl24 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-sl32 {
    padding-top: 2rem !important;
  }

  .pt-lg-sl40 {
    padding-top: 2.5rem !important;
  }

  .pt-lg-sl48 {
    padding-top: 3rem !important;
  }

  .pt-lg-sl56 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-sl64 {
    padding-top: 4rem !important;
  }

  .pt-lg-sl72 {
    padding-top: 4.5rem !important;
  }

  .pt-lg-sl80 {
    padding-top: 5rem !important;
  }

  .pt-lg-sl88 {
    padding-top: 5.5rem !important;
  }

  .pt-lg-sl96 {
    padding-top: 6rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pe-lg-sl4 {
    padding-right: .25rem !important;
  }

  .pe-lg-sl8 {
    padding-right: .5rem !important;
  }

  .pe-lg-sl12 {
    padding-right: .75rem !important;
  }

  .pe-lg-sl16 {
    padding-right: 1rem !important;
  }

  .pe-lg-sl24 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-sl32 {
    padding-right: 2rem !important;
  }

  .pe-lg-sl40 {
    padding-right: 2.5rem !important;
  }

  .pe-lg-sl48 {
    padding-right: 3rem !important;
  }

  .pe-lg-sl56 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-sl64 {
    padding-right: 4rem !important;
  }

  .pe-lg-sl72 {
    padding-right: 4.5rem !important;
  }

  .pe-lg-sl80 {
    padding-right: 5rem !important;
  }

  .pe-lg-sl88 {
    padding-right: 5.5rem !important;
  }

  .pe-lg-sl96 {
    padding-right: 6rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-sl4 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-sl8 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-sl12 {
    padding-bottom: .75rem !important;
  }

  .pb-lg-sl16 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-sl24 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-sl32 {
    padding-bottom: 2rem !important;
  }

  .pb-lg-sl40 {
    padding-bottom: 2.5rem !important;
  }

  .pb-lg-sl48 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-sl56 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-sl64 {
    padding-bottom: 4rem !important;
  }

  .pb-lg-sl72 {
    padding-bottom: 4.5rem !important;
  }

  .pb-lg-sl80 {
    padding-bottom: 5rem !important;
  }

  .pb-lg-sl88 {
    padding-bottom: 5.5rem !important;
  }

  .pb-lg-sl96 {
    padding-bottom: 6rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .ps-lg-sl4 {
    padding-left: .25rem !important;
  }

  .ps-lg-sl8 {
    padding-left: .5rem !important;
  }

  .ps-lg-sl12 {
    padding-left: .75rem !important;
  }

  .ps-lg-sl16 {
    padding-left: 1rem !important;
  }

  .ps-lg-sl24 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-sl32 {
    padding-left: 2rem !important;
  }

  .ps-lg-sl40 {
    padding-left: 2.5rem !important;
  }

  .ps-lg-sl48 {
    padding-left: 3rem !important;
  }

  .ps-lg-sl56 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-sl64 {
    padding-left: 4rem !important;
  }

  .ps-lg-sl72 {
    padding-left: 4.5rem !important;
  }

  .ps-lg-sl80 {
    padding-left: 5rem !important;
  }

  .ps-lg-sl88 {
    padding-left: 5.5rem !important;
  }

  .ps-lg-sl96 {
    padding-left: 6rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .gap-lg-sl4 {
    gap: .25rem !important;
  }

  .gap-lg-sl8 {
    gap: .5rem !important;
  }

  .gap-lg-sl12 {
    gap: .75rem !important;
  }

  .gap-lg-sl16 {
    gap: 1rem !important;
  }

  .gap-lg-sl24 {
    gap: 1.5rem !important;
  }

  .gap-lg-sl32 {
    gap: 2rem !important;
  }

  .gap-lg-sl40 {
    gap: 2.5rem !important;
  }

  .gap-lg-sl48 {
    gap: 3rem !important;
  }

  .gap-lg-sl56 {
    gap: 3.5rem !important;
  }

  .gap-lg-sl64 {
    gap: 4rem !important;
  }

  .gap-lg-sl72 {
    gap: 4.5rem !important;
  }

  .gap-lg-sl80 {
    gap: 5rem !important;
  }

  .gap-lg-sl88 {
    gap: 5.5rem !important;
  }

  .gap-lg-sl96 {
    gap: 6rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: .25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: .5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }

  .row-gap-lg-sl4 {
    row-gap: .25rem !important;
  }

  .row-gap-lg-sl8 {
    row-gap: .5rem !important;
  }

  .row-gap-lg-sl12 {
    row-gap: .75rem !important;
  }

  .row-gap-lg-sl16 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-sl24 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-sl32 {
    row-gap: 2rem !important;
  }

  .row-gap-lg-sl40 {
    row-gap: 2.5rem !important;
  }

  .row-gap-lg-sl48 {
    row-gap: 3rem !important;
  }

  .row-gap-lg-sl56 {
    row-gap: 3.5rem !important;
  }

  .row-gap-lg-sl64 {
    row-gap: 4rem !important;
  }

  .row-gap-lg-sl72 {
    row-gap: 4.5rem !important;
  }

  .row-gap-lg-sl80 {
    row-gap: 5rem !important;
  }

  .row-gap-lg-sl88 {
    row-gap: 5.5rem !important;
  }

  .row-gap-lg-sl96 {
    row-gap: 6rem !important;
  }

  .column-gap-lg-0 {
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    column-gap: .25rem !important;
  }

  .column-gap-lg-2 {
    column-gap: .5rem !important;
  }

  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }

  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }

  .column-gap-lg-sl4 {
    column-gap: .25rem !important;
  }

  .column-gap-lg-sl8 {
    column-gap: .5rem !important;
  }

  .column-gap-lg-sl12 {
    column-gap: .75rem !important;
  }

  .column-gap-lg-sl16 {
    column-gap: 1rem !important;
  }

  .column-gap-lg-sl24 {
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-sl32 {
    column-gap: 2rem !important;
  }

  .column-gap-lg-sl40 {
    column-gap: 2.5rem !important;
  }

  .column-gap-lg-sl48 {
    column-gap: 3rem !important;
  }

  .column-gap-lg-sl56 {
    column-gap: 3.5rem !important;
  }

  .column-gap-lg-sl64 {
    column-gap: 4rem !important;
  }

  .column-gap-lg-sl72 {
    column-gap: 4.5rem !important;
  }

  .column-gap-lg-sl80 {
    column-gap: 5rem !important;
  }

  .column-gap-lg-sl88 {
    column-gap: 5.5rem !important;
  }

  .column-gap-lg-sl96 {
    column-gap: 6rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (width >= 1440px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-sl4 {
    margin: .25rem !important;
  }

  .m-xl-sl8 {
    margin: .5rem !important;
  }

  .m-xl-sl12 {
    margin: .75rem !important;
  }

  .m-xl-sl16 {
    margin: 1rem !important;
  }

  .m-xl-sl24 {
    margin: 1.5rem !important;
  }

  .m-xl-sl32 {
    margin: 2rem !important;
  }

  .m-xl-sl40 {
    margin: 2.5rem !important;
  }

  .m-xl-sl48 {
    margin: 3rem !important;
  }

  .m-xl-sl56 {
    margin: 3.5rem !important;
  }

  .m-xl-sl64 {
    margin: 4rem !important;
  }

  .m-xl-sl72 {
    margin: 4.5rem !important;
  }

  .m-xl-sl80 {
    margin: 5rem !important;
  }

  .m-xl-sl88 {
    margin: 5.5rem !important;
  }

  .m-xl-sl96 {
    margin: 6rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-sl4 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-sl8 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-sl12 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-xl-sl16 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-sl24 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-sl32 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .mx-xl-sl40 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .mx-xl-sl48 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-sl56 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .mx-xl-sl64 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .mx-xl-sl72 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-xl-sl80 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .mx-xl-sl88 {
    margin-left: 5.5rem !important;
    margin-right: 5.5rem !important;
  }

  .mx-xl-sl96 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-sl4 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-sl8 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-sl12 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-xl-sl16 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-sl24 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-sl32 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .my-xl-sl40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .my-xl-sl48 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-sl56 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-sl64 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-sl72 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-sl80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-sl88 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .my-xl-sl96 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-sl4 {
    margin-top: .25rem !important;
  }

  .mt-xl-sl8 {
    margin-top: .5rem !important;
  }

  .mt-xl-sl12 {
    margin-top: .75rem !important;
  }

  .mt-xl-sl16 {
    margin-top: 1rem !important;
  }

  .mt-xl-sl24 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-sl32 {
    margin-top: 2rem !important;
  }

  .mt-xl-sl40 {
    margin-top: 2.5rem !important;
  }

  .mt-xl-sl48 {
    margin-top: 3rem !important;
  }

  .mt-xl-sl56 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-sl64 {
    margin-top: 4rem !important;
  }

  .mt-xl-sl72 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-sl80 {
    margin-top: 5rem !important;
  }

  .mt-xl-sl88 {
    margin-top: 5.5rem !important;
  }

  .mt-xl-sl96 {
    margin-top: 6rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-sl4 {
    margin-right: .25rem !important;
  }

  .me-xl-sl8 {
    margin-right: .5rem !important;
  }

  .me-xl-sl12 {
    margin-right: .75rem !important;
  }

  .me-xl-sl16 {
    margin-right: 1rem !important;
  }

  .me-xl-sl24 {
    margin-right: 1.5rem !important;
  }

  .me-xl-sl32 {
    margin-right: 2rem !important;
  }

  .me-xl-sl40 {
    margin-right: 2.5rem !important;
  }

  .me-xl-sl48 {
    margin-right: 3rem !important;
  }

  .me-xl-sl56 {
    margin-right: 3.5rem !important;
  }

  .me-xl-sl64 {
    margin-right: 4rem !important;
  }

  .me-xl-sl72 {
    margin-right: 4.5rem !important;
  }

  .me-xl-sl80 {
    margin-right: 5rem !important;
  }

  .me-xl-sl88 {
    margin-right: 5.5rem !important;
  }

  .me-xl-sl96 {
    margin-right: 6rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-sl4 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-sl8 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-sl12 {
    margin-bottom: .75rem !important;
  }

  .mb-xl-sl16 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-sl24 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-sl32 {
    margin-bottom: 2rem !important;
  }

  .mb-xl-sl40 {
    margin-bottom: 2.5rem !important;
  }

  .mb-xl-sl48 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-sl56 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-sl64 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-sl72 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-sl80 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-sl88 {
    margin-bottom: 5.5rem !important;
  }

  .mb-xl-sl96 {
    margin-bottom: 6rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-sl4 {
    margin-left: .25rem !important;
  }

  .ms-xl-sl8 {
    margin-left: .5rem !important;
  }

  .ms-xl-sl12 {
    margin-left: .75rem !important;
  }

  .ms-xl-sl16 {
    margin-left: 1rem !important;
  }

  .ms-xl-sl24 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-sl32 {
    margin-left: 2rem !important;
  }

  .ms-xl-sl40 {
    margin-left: 2.5rem !important;
  }

  .ms-xl-sl48 {
    margin-left: 3rem !important;
  }

  .ms-xl-sl56 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-sl64 {
    margin-left: 4rem !important;
  }

  .ms-xl-sl72 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-sl80 {
    margin-left: 5rem !important;
  }

  .ms-xl-sl88 {
    margin-left: 5.5rem !important;
  }

  .ms-xl-sl96 {
    margin-left: 6rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .p-xl-sl4 {
    padding: .25rem !important;
  }

  .p-xl-sl8 {
    padding: .5rem !important;
  }

  .p-xl-sl12 {
    padding: .75rem !important;
  }

  .p-xl-sl16 {
    padding: 1rem !important;
  }

  .p-xl-sl24 {
    padding: 1.5rem !important;
  }

  .p-xl-sl32 {
    padding: 2rem !important;
  }

  .p-xl-sl40 {
    padding: 2.5rem !important;
  }

  .p-xl-sl48 {
    padding: 3rem !important;
  }

  .p-xl-sl56 {
    padding: 3.5rem !important;
  }

  .p-xl-sl64 {
    padding: 4rem !important;
  }

  .p-xl-sl72 {
    padding: 4.5rem !important;
  }

  .p-xl-sl80 {
    padding: 5rem !important;
  }

  .p-xl-sl88 {
    padding: 5.5rem !important;
  }

  .p-xl-sl96 {
    padding: 6rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-xl-sl4 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-sl8 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-sl12 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-xl-sl16 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-sl24 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-sl32 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .px-xl-sl40 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .px-xl-sl48 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .px-xl-sl56 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .px-xl-sl64 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .px-xl-sl72 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .px-xl-sl80 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .px-xl-sl88 {
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
  }

  .px-xl-sl96 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-sl4 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-sl8 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-sl12 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-xl-sl16 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-sl24 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-sl32 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .py-xl-sl40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .py-xl-sl48 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-sl56 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-sl64 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .py-xl-sl72 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .py-xl-sl80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .py-xl-sl88 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .py-xl-sl96 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pt-xl-sl4 {
    padding-top: .25rem !important;
  }

  .pt-xl-sl8 {
    padding-top: .5rem !important;
  }

  .pt-xl-sl12 {
    padding-top: .75rem !important;
  }

  .pt-xl-sl16 {
    padding-top: 1rem !important;
  }

  .pt-xl-sl24 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-sl32 {
    padding-top: 2rem !important;
  }

  .pt-xl-sl40 {
    padding-top: 2.5rem !important;
  }

  .pt-xl-sl48 {
    padding-top: 3rem !important;
  }

  .pt-xl-sl56 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-sl64 {
    padding-top: 4rem !important;
  }

  .pt-xl-sl72 {
    padding-top: 4.5rem !important;
  }

  .pt-xl-sl80 {
    padding-top: 5rem !important;
  }

  .pt-xl-sl88 {
    padding-top: 5.5rem !important;
  }

  .pt-xl-sl96 {
    padding-top: 6rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pe-xl-sl4 {
    padding-right: .25rem !important;
  }

  .pe-xl-sl8 {
    padding-right: .5rem !important;
  }

  .pe-xl-sl12 {
    padding-right: .75rem !important;
  }

  .pe-xl-sl16 {
    padding-right: 1rem !important;
  }

  .pe-xl-sl24 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-sl32 {
    padding-right: 2rem !important;
  }

  .pe-xl-sl40 {
    padding-right: 2.5rem !important;
  }

  .pe-xl-sl48 {
    padding-right: 3rem !important;
  }

  .pe-xl-sl56 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-sl64 {
    padding-right: 4rem !important;
  }

  .pe-xl-sl72 {
    padding-right: 4.5rem !important;
  }

  .pe-xl-sl80 {
    padding-right: 5rem !important;
  }

  .pe-xl-sl88 {
    padding-right: 5.5rem !important;
  }

  .pe-xl-sl96 {
    padding-right: 6rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-sl4 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-sl8 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-sl12 {
    padding-bottom: .75rem !important;
  }

  .pb-xl-sl16 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-sl24 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-sl32 {
    padding-bottom: 2rem !important;
  }

  .pb-xl-sl40 {
    padding-bottom: 2.5rem !important;
  }

  .pb-xl-sl48 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-sl56 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-sl64 {
    padding-bottom: 4rem !important;
  }

  .pb-xl-sl72 {
    padding-bottom: 4.5rem !important;
  }

  .pb-xl-sl80 {
    padding-bottom: 5rem !important;
  }

  .pb-xl-sl88 {
    padding-bottom: 5.5rem !important;
  }

  .pb-xl-sl96 {
    padding-bottom: 6rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .ps-xl-sl4 {
    padding-left: .25rem !important;
  }

  .ps-xl-sl8 {
    padding-left: .5rem !important;
  }

  .ps-xl-sl12 {
    padding-left: .75rem !important;
  }

  .ps-xl-sl16 {
    padding-left: 1rem !important;
  }

  .ps-xl-sl24 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-sl32 {
    padding-left: 2rem !important;
  }

  .ps-xl-sl40 {
    padding-left: 2.5rem !important;
  }

  .ps-xl-sl48 {
    padding-left: 3rem !important;
  }

  .ps-xl-sl56 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-sl64 {
    padding-left: 4rem !important;
  }

  .ps-xl-sl72 {
    padding-left: 4.5rem !important;
  }

  .ps-xl-sl80 {
    padding-left: 5rem !important;
  }

  .ps-xl-sl88 {
    padding-left: 5.5rem !important;
  }

  .ps-xl-sl96 {
    padding-left: 6rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .gap-xl-sl4 {
    gap: .25rem !important;
  }

  .gap-xl-sl8 {
    gap: .5rem !important;
  }

  .gap-xl-sl12 {
    gap: .75rem !important;
  }

  .gap-xl-sl16 {
    gap: 1rem !important;
  }

  .gap-xl-sl24 {
    gap: 1.5rem !important;
  }

  .gap-xl-sl32 {
    gap: 2rem !important;
  }

  .gap-xl-sl40 {
    gap: 2.5rem !important;
  }

  .gap-xl-sl48 {
    gap: 3rem !important;
  }

  .gap-xl-sl56 {
    gap: 3.5rem !important;
  }

  .gap-xl-sl64 {
    gap: 4rem !important;
  }

  .gap-xl-sl72 {
    gap: 4.5rem !important;
  }

  .gap-xl-sl80 {
    gap: 5rem !important;
  }

  .gap-xl-sl88 {
    gap: 5.5rem !important;
  }

  .gap-xl-sl96 {
    gap: 6rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: .25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: .5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }

  .row-gap-xl-sl4 {
    row-gap: .25rem !important;
  }

  .row-gap-xl-sl8 {
    row-gap: .5rem !important;
  }

  .row-gap-xl-sl12 {
    row-gap: .75rem !important;
  }

  .row-gap-xl-sl16 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-sl24 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-sl32 {
    row-gap: 2rem !important;
  }

  .row-gap-xl-sl40 {
    row-gap: 2.5rem !important;
  }

  .row-gap-xl-sl48 {
    row-gap: 3rem !important;
  }

  .row-gap-xl-sl56 {
    row-gap: 3.5rem !important;
  }

  .row-gap-xl-sl64 {
    row-gap: 4rem !important;
  }

  .row-gap-xl-sl72 {
    row-gap: 4.5rem !important;
  }

  .row-gap-xl-sl80 {
    row-gap: 5rem !important;
  }

  .row-gap-xl-sl88 {
    row-gap: 5.5rem !important;
  }

  .row-gap-xl-sl96 {
    row-gap: 6rem !important;
  }

  .column-gap-xl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    column-gap: .25rem !important;
  }

  .column-gap-xl-2 {
    column-gap: .5rem !important;
  }

  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }

  .column-gap-xl-sl4 {
    column-gap: .25rem !important;
  }

  .column-gap-xl-sl8 {
    column-gap: .5rem !important;
  }

  .column-gap-xl-sl12 {
    column-gap: .75rem !important;
  }

  .column-gap-xl-sl16 {
    column-gap: 1rem !important;
  }

  .column-gap-xl-sl24 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-sl32 {
    column-gap: 2rem !important;
  }

  .column-gap-xl-sl40 {
    column-gap: 2.5rem !important;
  }

  .column-gap-xl-sl48 {
    column-gap: 3rem !important;
  }

  .column-gap-xl-sl56 {
    column-gap: 3.5rem !important;
  }

  .column-gap-xl-sl64 {
    column-gap: 4rem !important;
  }

  .column-gap-xl-sl72 {
    column-gap: 4.5rem !important;
  }

  .column-gap-xl-sl80 {
    column-gap: 5rem !important;
  }

  .column-gap-xl-sl88 {
    column-gap: 5.5rem !important;
  }

  .column-gap-xl-sl96 {
    column-gap: 6rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

@media (width <= 599.98px) {
  .container {
    padding: 0 1rem;
  }
}

@media (width >= 600px) and (width <= 904.98px) {
  .container {
    padding: 0 2rem;
  }
}

@media (width >= 905px) and (width <= 1239.98px) {
  .container {
    padding: 0 3.75rem;
  }
}

@media (width >= 1240px) and (width <= 1439.98px) {
  .container {
    padding: 0;
  }
}

@media (width >= 1440px) {
  .container {
    padding: 0;
  }
}

.shadow-x {
  box-shadow: 1px 0 3px #00000040;
}

html {
  margin: 0;
  padding: 0;
  font-size: 100%;
}

h1, .h1 {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 2.75rem;
}

h2, .h2 {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.5rem;
}

h3, .h3 {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 2.25rem;
}

h4, .h4 {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
}

h5, .h5 {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 1.75rem;
}

.display-h1 {
  color: var(--grey-900);
  font-family: Sun Life New Display Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 2.75rem;
}

.display-h2 {
  color: var(--grey-900);
  font-family: Sun Life New Display Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.5rem;
}

.body-medium {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.body-medium-bold {
  color: var(--grey-900);
  font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.body-medium-italic {
  color: var(--grey-900);
  font-family: Sun Life New Text Italic, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.body-small {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

.body-small-bold {
  color: var(--grey-900);
  font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

.body-xs {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1rem;
}

.body-xs-bold {
  color: var(--grey-900);
  font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1rem;
}

.body-color-caption, .body-caption {
  color: var(--grey-600);
}

.body-navigation {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.6875rem;
}

.link-medium {
  color: var(--brand-tertiary-800);
  font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.link-small {
  color: var(--brand-tertiary-800);
  font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

.link-xs {
  color: var(--brand-tertiary-800);
  font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1rem;
}

.label-small {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .6875rem;
  font-weight: 400;
  line-height: 1rem;
}

.input-label {
  color: var(--grey-700);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .75rem;
  font-weight: 400;
  line-height: .75rem;
}

.input-text {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
}

body, p {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-icon {
  background: var(--grey-900);
  display: inline-block;
}

.sl-icon-account {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/account-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/account-default-icon.svg") 0 0 / cover;
}

.sl-icon-account:not(.sl-icon_non-interactive):hover, .sl-icon-account:not(.sl-icon_non-interactive):focus, .sl-icon-account.sl-icon_active, a:hover .sl-icon-account:not(.sl-icon_non-interactive), button:hover .sl-icon-account:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/account-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/account-hover-icon.svg") 0 0 / cover;
}

.sl-icon-add-circle {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/add-circle-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/add-circle-default-icon.svg") 0 0 / cover;
}

.sl-icon-add-circle:not(.sl-icon_non-interactive):hover, .sl-icon-add-circle:not(.sl-icon_non-interactive):focus, .sl-icon-add-circle.sl-icon_active, a:hover .sl-icon-add-circle:not(.sl-icon_non-interactive), button:hover .sl-icon-add-circle:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/add-circle-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/add-circle-hover-icon.svg") 0 0 / cover;
}

.sl-icon-add-coverage {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/add-coverage-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/add-coverage-default-icon.svg") 0 0 / cover;
}

.sl-icon-add-coverage:not(.sl-icon_non-interactive):hover, .sl-icon-add-coverage:not(.sl-icon_non-interactive):focus, .sl-icon-add-coverage.sl-icon_active, a:hover .sl-icon-add-coverage:not(.sl-icon_non-interactive), button:hover .sl-icon-add-coverage:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/add-coverage-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/add-coverage-hover-icon.svg") 0 0 / cover;
}

.sl-icon-add-prescription {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/add-prescription-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/add-prescription-default-icon.svg") 0 0 / cover;
}

.sl-icon-add-prescription:not(.sl-icon_non-interactive):hover, .sl-icon-add-prescription:not(.sl-icon_non-interactive):focus, .sl-icon-add-prescription.sl-icon_active, a:hover .sl-icon-add-prescription:not(.sl-icon_non-interactive), button:hover .sl-icon-add-prescription:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/add-prescription-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/add-prescription-hover-icon.svg") 0 0 / cover;
}

.sl-icon-arrow-down {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/arrow-down-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/arrow-down-default-icon.svg") 0 0 / cover;
}

.sl-icon-arrow-down:not(.sl-icon_non-interactive):hover, .sl-icon-arrow-down:not(.sl-icon_non-interactive):focus, .sl-icon-arrow-down.sl-icon_active, a:hover .sl-icon-arrow-down:not(.sl-icon_non-interactive), button:hover .sl-icon-arrow-down:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/arrow-down-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/arrow-down-hover-icon.svg") 0 0 / cover;
}

.sl-icon-arrow-up {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/arrow-up-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/arrow-up-default-icon.svg") 0 0 / cover;
}

.sl-icon-arrow-up:not(.sl-icon_non-interactive):hover, .sl-icon-arrow-up:not(.sl-icon_non-interactive):focus, .sl-icon-arrow-up.sl-icon_active, a:hover .sl-icon-arrow-up:not(.sl-icon_non-interactive), button:hover .sl-icon-arrow-up:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/arrow-up-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/arrow-up-hover-icon.svg") 0 0 / cover;
}

.sl-icon-bars {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bars-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bars-default-icon.svg") 0 0 / cover;
}

.sl-icon-bars:not(.sl-icon_non-interactive):hover, .sl-icon-bars:not(.sl-icon_non-interactive):focus, .sl-icon-bars.sl-icon_active, a:hover .sl-icon-bars:not(.sl-icon_non-interactive), button:hover .sl-icon-bars:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bars-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bars-hover-icon.svg") 0 0 / cover;
}

.sl-icon-bell-no {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bell-no-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bell-no-default-icon.svg") 0 0 / cover;
}

.sl-icon-bell-no:not(.sl-icon_non-interactive):hover, .sl-icon-bell-no:not(.sl-icon_non-interactive):focus, .sl-icon-bell-no.sl-icon_active, a:hover .sl-icon-bell-no:not(.sl-icon_non-interactive), button:hover .sl-icon-bell-no:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bell-no-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bell-no-hover-icon.svg") 0 0 / cover;
}

.sl-icon-bell-yes {
  background-color: #0000;
  background-image: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bell-yes-default-icon.svg");
  background-size: cover;
  width: 1.5rem;
  height: 1.5rem;
}

.sl-icon-bell-yes:not(.sl-icon_non-interactive):hover, .sl-icon-bell-yes:not(.sl-icon_non-interactive):focus, .sl-icon-bell-yes.sl-icon_active, a:hover .sl-icon-bell-yes:not(.sl-icon_non-interactive), button:hover .sl-icon-bell-yes:not(.sl-icon_non-interactive) {
  background-color: #0000;
  background-image: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bell-yes-hover-icon.svg");
  background-size: cover;
}

.sl-icon-bookmark {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bookmark-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bookmark-default-icon.svg") 0 0 / cover;
}

.sl-icon-bookmark:not(.sl-icon_non-interactive):hover, .sl-icon-bookmark:not(.sl-icon_non-interactive):focus, .sl-icon-bookmark.sl-icon_active, a:hover .sl-icon-bookmark:not(.sl-icon_non-interactive), button:hover .sl-icon-bookmark:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bookmark-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bookmark-hover-icon.svg") 0 0 / cover;
}

.sl-icon-briefcase {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/briefcase-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/briefcase-default-icon.svg") 0 0 / cover;
}

.sl-icon-briefcase:not(.sl-icon_non-interactive):hover, .sl-icon-briefcase:not(.sl-icon_non-interactive):focus, .sl-icon-briefcase.sl-icon_active, a:hover .sl-icon-briefcase:not(.sl-icon_non-interactive), button:hover .sl-icon-briefcase:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/briefcase-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/briefcase-hover-icon.svg") 0 0 / cover;
}

.sl-icon-calendar {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/calendar-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/calendar-default-icon.svg") 0 0 / cover;
}

.sl-icon-calendar:not(.sl-icon_non-interactive):hover, .sl-icon-calendar:not(.sl-icon_non-interactive):focus, .sl-icon-calendar.sl-icon_active, a:hover .sl-icon-calendar:not(.sl-icon_non-interactive), button:hover .sl-icon-calendar:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/calendar-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/calendar-hover-icon.svg") 0 0 / cover;
}

.sl-icon-call {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/call-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/call-default-icon.svg") 0 0 / cover;
}

.sl-icon-call:not(.sl-icon_non-interactive):hover, .sl-icon-call:not(.sl-icon_non-interactive):focus, .sl-icon-call.sl-icon_active, a:hover .sl-icon-call:not(.sl-icon_non-interactive), button:hover .sl-icon-call:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/call-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/call-hover-icon.svg") 0 0 / cover;
}

.sl-icon-caret-down {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-down-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-down-default-icon.svg") 0 0 / cover;
}

.sl-icon-caret-down:not(.sl-icon_non-interactive):hover, .sl-icon-caret-down:not(.sl-icon_non-interactive):focus, .sl-icon-caret-down.sl-icon_active, a:hover .sl-icon-caret-down:not(.sl-icon_non-interactive), button:hover .sl-icon-caret-down:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-down-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-down-hover-icon.svg") 0 0 / cover;
}

.sl-icon-caret-left {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-left-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-left-default-icon.svg") 0 0 / cover;
}

.sl-icon-caret-left:not(.sl-icon_non-interactive):hover, .sl-icon-caret-left:not(.sl-icon_non-interactive):focus, .sl-icon-caret-left.sl-icon_active, a:hover .sl-icon-caret-left:not(.sl-icon_non-interactive), button:hover .sl-icon-caret-left:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-left-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-left-hover-icon.svg") 0 0 / cover;
}

.sl-icon-caret-right {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-right-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-right-default-icon.svg") 0 0 / cover;
}

.sl-icon-caret-right:not(.sl-icon_non-interactive):hover, .sl-icon-caret-right:not(.sl-icon_non-interactive):focus, .sl-icon-caret-right.sl-icon_active, a:hover .sl-icon-caret-right:not(.sl-icon_non-interactive), button:hover .sl-icon-caret-right:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-right-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-right-hover-icon.svg") 0 0 / cover;
}

.sl-icon-caret-up {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-up-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-up-default-icon.svg") 0 0 / cover;
}

.sl-icon-caret-up:not(.sl-icon_non-interactive):hover, .sl-icon-caret-up:not(.sl-icon_non-interactive):focus, .sl-icon-caret-up.sl-icon_active, a:hover .sl-icon-caret-up:not(.sl-icon_non-interactive), button:hover .sl-icon-caret-up:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-up-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-up-hover-icon.svg") 0 0 / cover;
}

.sl-icon-chat {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chat-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chat-default-icon.svg") 0 0 / cover;
}

.sl-icon-chat:not(.sl-icon_non-interactive):hover, .sl-icon-chat:not(.sl-icon_non-interactive):focus, .sl-icon-chat.sl-icon_active, a:hover .sl-icon-chat:not(.sl-icon_non-interactive), button:hover .sl-icon-chat:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chat-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chat-hover-icon.svg") 0 0 / cover;
}

.sl-icon-check {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-default-icon.svg") 0 0 / cover;
}

.sl-icon-check:not(.sl-icon_non-interactive):hover, .sl-icon-check:not(.sl-icon_non-interactive):focus, .sl-icon-check.sl-icon_active, a:hover .sl-icon-check:not(.sl-icon_non-interactive), button:hover .sl-icon-check:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-hover-icon.svg") 0 0 / cover;
}

.sl-icon-check-circle {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-circle-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-circle-default-icon.svg") 0 0 / cover;
}

.sl-icon-check-circle:not(.sl-icon_non-interactive):hover, .sl-icon-check-circle:not(.sl-icon_non-interactive):focus, .sl-icon-check-circle.sl-icon_active, a:hover .sl-icon-check-circle:not(.sl-icon_non-interactive), button:hover .sl-icon-check-circle:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-circle-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-circle-hover-icon.svg") 0 0 / cover;
}

.sl-icon-check-circle-active {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-circle-active-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-circle-active-default-icon.svg") 0 0 / cover;
}

.sl-icon-check-circle-active.sl-icon_active {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-circle-active-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-circle-active-hover-icon.svg") 0 0 / cover;
}

.sl-icon-check-grey {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-grey-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-grey-default-icon.svg") 0 0 / cover;
}

.sl-icon-check-grey:not(.sl-icon_non-interactive):hover, .sl-icon-check-grey:not(.sl-icon_non-interactive):focus, .sl-icon-check-grey.sl-icon_active, a:hover .sl-icon-check-grey:not(.sl-icon_non-interactive), button:hover .sl-icon-check-grey:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-grey-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-grey-hover-icon.svg") 0 0 / cover;
}

.sl-icon-check-white {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-white-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-white-default-icon.svg") 0 0 / cover;
}

.sl-icon-check-white:not(.sl-icon_non-interactive):hover, .sl-icon-check-white:not(.sl-icon_non-interactive):focus, .sl-icon-check-white.sl-icon_active, a:hover .sl-icon-check-white:not(.sl-icon_non-interactive), button:hover .sl-icon-check-white:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-white-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-white-hover-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-circle-down {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-down-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-down-default-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-circle-down:not(.sl-icon_non-interactive):hover, .sl-icon-chevron-circle-down:not(.sl-icon_non-interactive):focus, .sl-icon-chevron-circle-down.sl-icon_active, a:hover .sl-icon-chevron-circle-down:not(.sl-icon_non-interactive), button:hover .sl-icon-chevron-circle-down:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-down-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-down-hover-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-circle-left {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-left-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-left-default-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-circle-left:not(.sl-icon_non-interactive):hover, .sl-icon-chevron-circle-left:not(.sl-icon_non-interactive):focus, .sl-icon-chevron-circle-left.sl-icon_active, a:hover .sl-icon-chevron-circle-left:not(.sl-icon_non-interactive), button:hover .sl-icon-chevron-circle-left:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-left-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-left-hover-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-circle-right {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-right-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-right-default-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-circle-right:not(.sl-icon_non-interactive):hover, .sl-icon-chevron-circle-right:not(.sl-icon_non-interactive):focus, .sl-icon-chevron-circle-right.sl-icon_active, a:hover .sl-icon-chevron-circle-right:not(.sl-icon_non-interactive), button:hover .sl-icon-chevron-circle-right:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-right-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-right-hover-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-circle-up {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-up-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-up-default-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-circle-up:not(.sl-icon_non-interactive):hover, .sl-icon-chevron-circle-up:not(.sl-icon_non-interactive):focus, .sl-icon-chevron-circle-up.sl-icon_active, a:hover .sl-icon-chevron-circle-up:not(.sl-icon_non-interactive), button:hover .sl-icon-chevron-circle-up:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-up-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-up-hover-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-double-left {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-double-left-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-double-left-default-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-double-left:not(.sl-icon_non-interactive):hover, .sl-icon-chevron-double-left:not(.sl-icon_non-interactive):focus, .sl-icon-chevron-double-left.sl-icon_active, a:hover .sl-icon-chevron-double-left:not(.sl-icon_non-interactive), button:hover .sl-icon-chevron-double-left:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-double-left-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-double-left-hover-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-double-right {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-double-right-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-double-right-default-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-double-right:not(.sl-icon_non-interactive):hover, .sl-icon-chevron-double-right:not(.sl-icon_non-interactive):focus, .sl-icon-chevron-double-right.sl-icon_active, a:hover .sl-icon-chevron-double-right:not(.sl-icon_non-interactive), button:hover .sl-icon-chevron-double-right:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-double-right-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-double-right-hover-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-down {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-down-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-down-default-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-down:not(.sl-icon_non-interactive):hover, .sl-icon-chevron-down:not(.sl-icon_non-interactive):focus, .sl-icon-chevron-down.sl-icon_active, a:hover .sl-icon-chevron-down:not(.sl-icon_non-interactive), button:hover .sl-icon-chevron-down:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-down-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-down-hover-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-last-left {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-last-left-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-last-left-default-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-last-left:not(.sl-icon_non-interactive):hover, .sl-icon-chevron-last-left:not(.sl-icon_non-interactive):focus, .sl-icon-chevron-last-left.sl-icon_active, a:hover .sl-icon-chevron-last-left:not(.sl-icon_non-interactive), button:hover .sl-icon-chevron-last-left:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-last-left-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-last-left-hover-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-last-right {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-last-right-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-last-right-default-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-last-right:not(.sl-icon_non-interactive):hover, .sl-icon-chevron-last-right:not(.sl-icon_non-interactive):focus, .sl-icon-chevron-last-right.sl-icon_active, a:hover .sl-icon-chevron-last-right:not(.sl-icon_non-interactive), button:hover .sl-icon-chevron-last-right:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-last-right-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-last-right-hover-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-left {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-left-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-left-default-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-left:not(.sl-icon_non-interactive):hover, .sl-icon-chevron-left:not(.sl-icon_non-interactive):focus, .sl-icon-chevron-left.sl-icon_active, a:hover .sl-icon-chevron-left:not(.sl-icon_non-interactive), button:hover .sl-icon-chevron-left:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-left-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-left-hover-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-right {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-right-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-right-default-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-right:not(.sl-icon_non-interactive):hover, .sl-icon-chevron-right:not(.sl-icon_non-interactive):focus, .sl-icon-chevron-right.sl-icon_active, a:hover .sl-icon-chevron-right:not(.sl-icon_non-interactive), button:hover .sl-icon-chevron-right:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-right-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-right-hover-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-up {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-up-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-up-default-icon.svg") 0 0 / cover;
}

.sl-icon-chevron-up:not(.sl-icon_non-interactive):hover, .sl-icon-chevron-up:not(.sl-icon_non-interactive):focus, .sl-icon-chevron-up.sl-icon_active, a:hover .sl-icon-chevron-up:not(.sl-icon_non-interactive), button:hover .sl-icon-chevron-up:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-up-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-up-hover-icon.svg") 0 0 / cover;
}

.sl-icon-circle-indicator {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/circle-indicator-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/circle-indicator-default-icon.svg") 0 0 / cover;
}

.sl-icon-circle-indicator:not(.sl-icon_non-interactive):hover, .sl-icon-circle-indicator:not(.sl-icon_non-interactive):focus, .sl-icon-circle-indicator.sl-icon_active, a:hover .sl-icon-circle-indicator:not(.sl-icon_non-interactive), button:hover .sl-icon-circle-indicator:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/circle-indicator-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/circle-indicator-hover-icon.svg") 0 0 / cover;
}

.sl-icon-claim-coverage {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/claim-coverage-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/claim-coverage-default-icon.svg") 0 0 / cover;
}

.sl-icon-claim-coverage:not(.sl-icon_non-interactive):hover, .sl-icon-claim-coverage:not(.sl-icon_non-interactive):focus, .sl-icon-claim-coverage.sl-icon_active, a:hover .sl-icon-claim-coverage:not(.sl-icon_non-interactive), button:hover .sl-icon-claim-coverage:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/claim-coverage-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/claim-coverage-hover-icon.svg") 0 0 / cover;
}

.sl-icon-claim {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/claim-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/claim-default-icon.svg") 0 0 / cover;
}

.sl-icon-claim:not(.sl-icon_non-interactive):hover, .sl-icon-claim:not(.sl-icon_non-interactive):focus, .sl-icon-claim.sl-icon_active, a:hover .sl-icon-claim:not(.sl-icon_non-interactive), button:hover .sl-icon-claim:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/claim-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/claim-hover-icon.svg") 0 0 / cover;
}

.sl-icon-close-circle {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/close-circle-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/close-circle-default-icon.svg") 0 0 / cover;
}

.sl-icon-close-circle:not(.sl-icon_non-interactive):hover, .sl-icon-close-circle:not(.sl-icon_non-interactive):focus, .sl-icon-close-circle.sl-icon_active, a:hover .sl-icon-close-circle:not(.sl-icon_non-interactive), button:hover .sl-icon-close-circle:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/close-circle-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/close-circle-hover-icon.svg") 0 0 / cover;
}

.sl-icon-close {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/close-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/close-default-icon.svg") 0 0 / cover;
}

.sl-icon-close:not(.sl-icon_non-interactive):hover, .sl-icon-close:not(.sl-icon_non-interactive):focus, .sl-icon-close.sl-icon_active, a:hover .sl-icon-close:not(.sl-icon_non-interactive), button:hover .sl-icon-close:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/close-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/close-hover-icon.svg") 0 0 / cover;
}

.sl-icon-contribution {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/contribution-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/contribution-default-icon.svg") 0 0 / cover;
}

.sl-icon-contribution:not(.sl-icon_non-interactive):hover, .sl-icon-contribution:not(.sl-icon_non-interactive):focus, .sl-icon-contribution.sl-icon_active, a:hover .sl-icon-contribution:not(.sl-icon_non-interactive), button:hover .sl-icon-contribution:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/contribution-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/contribution-hover-icon.svg") 0 0 / cover;
}

.sl-icon-coverage-card {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/coverage-card-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/coverage-card-default-icon.svg") 0 0 / cover;
}

.sl-icon-coverage-card:not(.sl-icon_non-interactive):hover, .sl-icon-coverage-card:not(.sl-icon_non-interactive):focus, .sl-icon-coverage-card.sl-icon_active, a:hover .sl-icon-coverage-card:not(.sl-icon_non-interactive), button:hover .sl-icon-coverage-card:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/coverage-card-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/coverage-card-hover-icon.svg") 0 0 / cover;
}

.sl-icon-coverage-info {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/coverage-info-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/coverage-info-default-icon.svg") 0 0 / cover;
}

.sl-icon-coverage-info:not(.sl-icon_non-interactive):hover, .sl-icon-coverage-info:not(.sl-icon_non-interactive):focus, .sl-icon-coverage-info.sl-icon_active, a:hover .sl-icon-coverage-info:not(.sl-icon_non-interactive), button:hover .sl-icon-coverage-info:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/coverage-info-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/coverage-info-hover-icon.svg") 0 0 / cover;
}

.sl-icon-credit {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/credit-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/credit-default-icon.svg") 0 0 / cover;
}

.sl-icon-credit:not(.sl-icon_non-interactive):hover, .sl-icon-credit:not(.sl-icon_non-interactive):focus, .sl-icon-credit.sl-icon_active, a:hover .sl-icon-credit:not(.sl-icon_non-interactive), button:hover .sl-icon-credit:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/credit-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/credit-hover-icon.svg") 0 0 / cover;
}

.sl-icon-dashboard {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/dashboard-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/dashboard-default-icon.svg") 0 0 / cover;
}

.sl-icon-dashboard:not(.sl-icon_non-interactive):hover, .sl-icon-dashboard:not(.sl-icon_non-interactive):focus, .sl-icon-dashboard.sl-icon_active, a:hover .sl-icon-dashboard:not(.sl-icon_non-interactive), button:hover .sl-icon-dashboard:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/dashboard-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/dashboard-hover-icon.svg") 0 0 / cover;
}

.sl-icon-dental {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/dental-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/dental-default-icon.svg") 0 0 / cover;
}

.sl-icon-dental:not(.sl-icon_non-interactive):hover, .sl-icon-dental:not(.sl-icon_non-interactive):focus, .sl-icon-dental.sl-icon_active, a:hover .sl-icon-dental:not(.sl-icon_non-interactive), button:hover .sl-icon-dental:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/dental-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/dental-hover-icon.svg") 0 0 / cover;
}

.sl-icon-disability {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/disability-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/disability-default-icon.svg") 0 0 / cover;
}

.sl-icon-disability:not(.sl-icon_non-interactive):hover, .sl-icon-disability:not(.sl-icon_non-interactive):focus, .sl-icon-disability.sl-icon_active, a:hover .sl-icon-disability:not(.sl-icon_non-interactive), button:hover .sl-icon-disability:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/disability-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/disability-hover-icon.svg") 0 0 / cover;
}

.sl-icon-dollar-sign {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/dollar-sign-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/dollar-sign-default-icon.svg") 0 0 / cover;
}

.sl-icon-dollar-sign:not(.sl-icon_non-interactive):hover, .sl-icon-dollar-sign:not(.sl-icon_non-interactive):focus, .sl-icon-dollar-sign.sl-icon_active, a:hover .sl-icon-dollar-sign:not(.sl-icon_non-interactive), button:hover .sl-icon-dollar-sign:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/dollar-sign-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/dollar-sign-hover-icon.svg") 0 0 / cover;
}

.sl-icon-download {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/download-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/download-default-icon.svg") 0 0 / cover;
}

.sl-icon-download:not(.sl-icon_non-interactive):hover, .sl-icon-download:not(.sl-icon_non-interactive):focus, .sl-icon-download.sl-icon_active, a:hover .sl-icon-download:not(.sl-icon_non-interactive), button:hover .sl-icon-download:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/download-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/download-hover-icon.svg") 0 0 / cover;
}

.sl-icon-drug {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/drug-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/drug-default-icon.svg") 0 0 / cover;
}

.sl-icon-drug:not(.sl-icon_non-interactive):hover, .sl-icon-drug:not(.sl-icon_non-interactive):focus, .sl-icon-drug.sl-icon_active, a:hover .sl-icon-drug:not(.sl-icon_non-interactive), button:hover .sl-icon-drug:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/drug-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/drug-hover-icon.svg") 0 0 / cover;
}

.sl-icon-edit {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/edit-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/edit-default-icon.svg") 0 0 / cover;
}

.sl-icon-edit:not(.sl-icon_non-interactive):hover, .sl-icon-edit:not(.sl-icon_non-interactive):focus, .sl-icon-edit.sl-icon_active, a:hover .sl-icon-edit:not(.sl-icon_non-interactive), button:hover .sl-icon-edit:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/edit-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/edit-hover-icon.svg") 0 0 / cover;
}

.sl-icon-ellipsis-horizontal {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/ellipsis-horizontal-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/ellipsis-horizontal-default-icon.svg") 0 0 / cover;
}

.sl-icon-ellipsis-horizontal:not(.sl-icon_non-interactive):hover, .sl-icon-ellipsis-horizontal:not(.sl-icon_non-interactive):focus, .sl-icon-ellipsis-horizontal.sl-icon_active, a:hover .sl-icon-ellipsis-horizontal:not(.sl-icon_non-interactive), button:hover .sl-icon-ellipsis-horizontal:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/ellipsis-horizontal-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/ellipsis-horizontal-hover-icon.svg") 0 0 / cover;
}

.sl-icon-ellipsis-vertical {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/ellipsis-vertical-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/ellipsis-vertical-default-icon.svg") 0 0 / cover;
}

.sl-icon-ellipsis-vertical:not(.sl-icon_non-interactive):hover, .sl-icon-ellipsis-vertical:not(.sl-icon_non-interactive):focus, .sl-icon-ellipsis-vertical.sl-icon_active, a:hover .sl-icon-ellipsis-vertical:not(.sl-icon_non-interactive), button:hover .sl-icon-ellipsis-vertical:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/ellipsis-vertical-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/ellipsis-vertical-hover-icon.svg") 0 0 / cover;
}

.sl-icon-email {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/email-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/email-default-icon.svg") 0 0 / cover;
}

.sl-icon-email:not(.sl-icon_non-interactive):hover, .sl-icon-email:not(.sl-icon_non-interactive):focus, .sl-icon-email.sl-icon_active, a:hover .sl-icon-email:not(.sl-icon_non-interactive), button:hover .sl-icon-email:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/email-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/email-hover-icon.svg") 0 0 / cover;
}

.sl-icon-exclamation-circle {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/exclamation-circle-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/exclamation-circle-default-icon.svg") 0 0 / cover;
}

.sl-icon-exclamation-circle:not(.sl-icon_non-interactive):hover, .sl-icon-exclamation-circle:not(.sl-icon_non-interactive):focus, .sl-icon-exclamation-circle.sl-icon_active, a:hover .sl-icon-exclamation-circle:not(.sl-icon_non-interactive), button:hover .sl-icon-exclamation-circle:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/exclamation-circle-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/exclamation-circle-hover-icon.svg") 0 0 / cover;
}

.sl-icon-exclamation-triangle {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/exclamation-triangle-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/exclamation-triangle-default-icon.svg") 0 0 / cover;
}

.sl-icon-exclamation-triangle:not(.sl-icon_non-interactive):hover, .sl-icon-exclamation-triangle:not(.sl-icon_non-interactive):focus, .sl-icon-exclamation-triangle.sl-icon_active, a:hover .sl-icon-exclamation-triangle:not(.sl-icon_non-interactive), button:hover .sl-icon-exclamation-triangle:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/exclamation-triangle-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/exclamation-triangle-hover-icon.svg") 0 0 / cover;
}

.sl-icon-external-link {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/external-link-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/external-link-default-icon.svg") 0 0 / cover;
}

.sl-icon-external-link:not(.sl-icon_non-interactive):hover, .sl-icon-external-link:not(.sl-icon_non-interactive):focus, .sl-icon-external-link.sl-icon_active, a:hover .sl-icon-external-link:not(.sl-icon_non-interactive), button:hover .sl-icon-external-link:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/external-link-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/external-link-hover-icon.svg") 0 0 / cover;
}

.sl-icon-eye-reveal {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/eye-reveal-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/eye-reveal-default-icon.svg") 0 0 / cover;
}

.sl-icon-eye-reveal:not(.sl-icon_non-interactive):hover, .sl-icon-eye-reveal:not(.sl-icon_non-interactive):focus, .sl-icon-eye-reveal.sl-icon_active, a:hover .sl-icon-eye-reveal:not(.sl-icon_non-interactive), button:hover .sl-icon-eye-reveal:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/eye-reveal-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/eye-reveal-hover-icon.svg") 0 0 / cover;
}

.sl-icon-eye-unreveal {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/eye-unreveal-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/eye-unreveal-default-icon.svg") 0 0 / cover;
}

.sl-icon-eye-unreveal:not(.sl-icon_non-interactive):hover, .sl-icon-eye-unreveal:not(.sl-icon_non-interactive):focus, .sl-icon-eye-unreveal.sl-icon_active, a:hover .sl-icon-eye-unreveal:not(.sl-icon_non-interactive), button:hover .sl-icon-eye-unreveal:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/eye-unreveal-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/eye-unreveal-hover-icon.svg") 0 0 / cover;
}

.sl-icon-file-upload {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/file-upload-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/file-upload-default-icon.svg") 0 0 / cover;
}

.sl-icon-file-upload:not(.sl-icon_non-interactive):hover, .sl-icon-file-upload:not(.sl-icon_non-interactive):focus, .sl-icon-file-upload.sl-icon_active, a:hover .sl-icon-file-upload:not(.sl-icon_non-interactive), button:hover .sl-icon-file-upload:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/file-upload-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/file-upload-hover-icon.svg") 0 0 / cover;
}

.sl-icon-filter {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/filter-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/filter-default-icon.svg") 0 0 / cover;
}

.sl-icon-filter:not(.sl-icon_non-interactive):hover, .sl-icon-filter:not(.sl-icon_non-interactive):focus, .sl-icon-filter.sl-icon_active, a:hover .sl-icon-filter:not(.sl-icon_non-interactive), button:hover .sl-icon-filter:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/filter-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/filter-hover-icon.svg") 0 0 / cover;
}

.sl-icon-find-an-advisor {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/find-an-advisor-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/find-an-advisor-default-icon.svg") 0 0 / cover;
}

.sl-icon-find-an-advisor:not(.sl-icon_non-interactive):hover, .sl-icon-find-an-advisor:not(.sl-icon_non-interactive):focus, .sl-icon-find-an-advisor.sl-icon_active, a:hover .sl-icon-find-an-advisor:not(.sl-icon_non-interactive), button:hover .sl-icon-find-an-advisor:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/find-an-advisor-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/find-an-advisor-hover-icon.svg") 0 0 / cover;
}

.sl-icon-fitness {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/fitness-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/fitness-default-icon.svg") 0 0 / cover;
}

.sl-icon-fitness:not(.sl-icon_non-interactive):hover, .sl-icon-fitness:not(.sl-icon_non-interactive):focus, .sl-icon-fitness.sl-icon_active, a:hover .sl-icon-fitness:not(.sl-icon_non-interactive), button:hover .sl-icon-fitness:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/fitness-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/fitness-hover-icon.svg") 0 0 / cover;
}

.sl-icon-grip-lines {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/grip-lines-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/grip-lines-default-icon.svg") 0 0 / cover;
}

.sl-icon-grip-lines:not(.sl-icon_non-interactive):hover, .sl-icon-grip-lines:not(.sl-icon_non-interactive):focus, .sl-icon-grip-lines.sl-icon_active, a:hover .sl-icon-grip-lines:not(.sl-icon_non-interactive), button:hover .sl-icon-grip-lines:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/grip-lines-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/grip-lines-hover-icon.svg") 0 0 / cover;
}

.sl-icon-home {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/home-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/home-default-icon.svg") 0 0 / cover;
}

.sl-icon-home:not(.sl-icon_non-interactive):hover, .sl-icon-home:not(.sl-icon_non-interactive):focus, .sl-icon-home.sl-icon_active, a:hover .sl-icon-home:not(.sl-icon_non-interactive), button:hover .sl-icon-home:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/home-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/home-hover-icon.svg") 0 0 / cover;
}

.sl-icon-info {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-default-icon.svg") 0 0 / cover;
}

.sl-icon-info:not(.sl-icon_non-interactive):hover, .sl-icon-info:not(.sl-icon_non-interactive):focus, .sl-icon-info.sl-icon_active, a:hover .sl-icon-info:not(.sl-icon_non-interactive), button:hover .sl-icon-info:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-hover-icon.svg") 0 0 / cover;
}

.sl-icon-insurance {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/insurance-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/insurance-default-icon.svg") 0 0 / cover;
}

.sl-icon-insurance:not(.sl-icon_non-interactive):hover, .sl-icon-insurance:not(.sl-icon_non-interactive):focus, .sl-icon-insurance.sl-icon_active, a:hover .sl-icon-insurance:not(.sl-icon_non-interactive), button:hover .sl-icon-insurance:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/insurance-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/insurance-hover-icon.svg") 0 0 / cover;
}

.sl-icon-investment {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/investment-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/investment-default-icon.svg") 0 0 / cover;
}

.sl-icon-investment:not(.sl-icon_non-interactive):hover, .sl-icon-investment:not(.sl-icon_non-interactive):focus, .sl-icon-investment.sl-icon_active, a:hover .sl-icon-investment:not(.sl-icon_non-interactive), button:hover .sl-icon-investment:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/investment-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/investment-hover-icon.svg") 0 0 / cover;
}

.sl-icon-language {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/language-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/language-default-icon.svg") 0 0 / cover;
}

.sl-icon-language:not(.sl-icon_non-interactive):hover, .sl-icon-language:not(.sl-icon_non-interactive):focus, .sl-icon-language.sl-icon_active, a:hover .sl-icon-language:not(.sl-icon_non-interactive), button:hover .sl-icon-language:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/language-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/language-hover-icon.svg") 0 0 / cover;
}

.sl-icon-link {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/link-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/link-default-icon.svg") 0 0 / cover;
}

.sl-icon-link:not(.sl-icon_non-interactive):hover, .sl-icon-link:not(.sl-icon_non-interactive):focus, .sl-icon-link.sl-icon_active, a:hover .sl-icon-link:not(.sl-icon_non-interactive), button:hover .sl-icon-link:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/link-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/link-hover-icon.svg") 0 0 / cover;
}

.sl-icon-location {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/location-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/location-default-icon.svg") 0 0 / cover;
}

.sl-icon-location:not(.sl-icon_non-interactive):hover, .sl-icon-location:not(.sl-icon_non-interactive):focus, .sl-icon-location.sl-icon_active, a:hover .sl-icon-location:not(.sl-icon_non-interactive), button:hover .sl-icon-location:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/location-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/location-hover-icon.svg") 0 0 / cover;
}

.sl-icon-lock {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/lock-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/lock-default-icon.svg") 0 0 / cover;
}

.sl-icon-lock:not(.sl-icon_non-interactive):hover, .sl-icon-lock:not(.sl-icon_non-interactive):focus, .sl-icon-lock.sl-icon_active, a:hover .sl-icon-lock:not(.sl-icon_non-interactive), button:hover .sl-icon-lock:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/lock-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/lock-hover-icon.svg") 0 0 / cover;
}

.sl-icon-manage-beneficiaries {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/manage-beneficiaries-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/manage-beneficiaries-default-icon.svg") 0 0 / cover;
}

.sl-icon-manage-beneficiaries:not(.sl-icon_non-interactive):hover, .sl-icon-manage-beneficiaries:not(.sl-icon_non-interactive):focus, .sl-icon-manage-beneficiaries.sl-icon_active, a:hover .sl-icon-manage-beneficiaries:not(.sl-icon_non-interactive), button:hover .sl-icon-manage-beneficiaries:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/manage-beneficiaries-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/manage-beneficiaries-hover-icon.svg") 0 0 / cover;
}

.sl-icon-medical {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/medical-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/medical-default-icon.svg") 0 0 / cover;
}

.sl-icon-medical:not(.sl-icon_non-interactive):hover, .sl-icon-medical:not(.sl-icon_non-interactive):focus, .sl-icon-medical.sl-icon_active, a:hover .sl-icon-medical:not(.sl-icon_non-interactive), button:hover .sl-icon-medical:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/medical-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/medical-hover-icon.svg") 0 0 / cover;
}

.sl-icon-minus-circle {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/minus-circle-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/minus-circle-default-icon.svg") 0 0 / cover;
}

.sl-icon-minus-circle:not(.sl-icon_non-interactive):hover, .sl-icon-minus-circle:not(.sl-icon_non-interactive):focus, .sl-icon-minus-circle.sl-icon_active, a:hover .sl-icon-minus-circle:not(.sl-icon_non-interactive), button:hover .sl-icon-minus-circle:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/minus-circle-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/minus-circle-hover-icon.svg") 0 0 / cover;
}

.sl-icon-minus {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/minus-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/minus-default-icon.svg") 0 0 / cover;
}

.sl-icon-minus:not(.sl-icon_non-interactive):hover, .sl-icon-minus:not(.sl-icon_non-interactive):focus, .sl-icon-minus.sl-icon_active, a:hover .sl-icon-minus:not(.sl-icon_non-interactive), button:hover .sl-icon-minus:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/minus-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/minus-hover-icon.svg") 0 0 / cover;
}

.sl-icon-minus-white {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/minus-white-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/minus-white-default-icon.svg") 0 0 / cover;
}

.sl-icon-minus-white:not(.sl-icon_non-interactive):hover, .sl-icon-minus-white:not(.sl-icon_non-interactive):focus, .sl-icon-minus-white.sl-icon_active, a:hover .sl-icon-minus-white:not(.sl-icon_non-interactive), button:hover .sl-icon-minus-white:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/minus-white-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/minus-white-hover-icon.svg") 0 0 / cover;
}

.sl-icon-more {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/more-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/more-default-icon.svg") 0 0 / cover;
}

.sl-icon-more:not(.sl-icon_non-interactive):hover, .sl-icon-more:not(.sl-icon_non-interactive):focus, .sl-icon-more.sl-icon_active, a:hover .sl-icon-more:not(.sl-icon_non-interactive), button:hover .sl-icon-more:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/more-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/more-hover-icon.svg") 0 0 / cover;
}

.sl-icon-new {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/new-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/new-default-icon.svg") 0 0 / cover;
}

.sl-icon-new:not(.sl-icon_non-interactive):hover, .sl-icon-new:not(.sl-icon_non-interactive):focus, .sl-icon-new.sl-icon_active, a:hover .sl-icon-new:not(.sl-icon_non-interactive), button:hover .sl-icon-new:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/new-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/new-hover-icon.svg") 0 0 / cover;
}

.sl-icon-order {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/order-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/order-default-icon.svg") 0 0 / cover;
}

.sl-icon-order:not(.sl-icon_non-interactive):hover, .sl-icon-order:not(.sl-icon_non-interactive):focus, .sl-icon-order.sl-icon_active, a:hover .sl-icon-order:not(.sl-icon_non-interactive), button:hover .sl-icon-order:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/order-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/order-hover-icon.svg") 0 0 / cover;
}

.sl-icon-partially-check {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/partially-check-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/partially-check-default-icon.svg") 0 0 / cover;
}

.sl-icon-partially-check:not(.sl-icon_non-interactive):hover, .sl-icon-partially-check:not(.sl-icon_non-interactive):focus, .sl-icon-partially-check.sl-icon_active, a:hover .sl-icon-partially-check:not(.sl-icon_non-interactive), button:hover .sl-icon-partially-check:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/partially-check-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/partially-check-hover-icon.svg") 0 0 / cover;
}

.sl-icon-pause {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/pause-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/pause-default-icon.svg") 0 0 / cover;
}

.sl-icon-pause:not(.sl-icon_non-interactive):hover, .sl-icon-pause:not(.sl-icon_non-interactive):focus, .sl-icon-pause.sl-icon_active, a:hover .sl-icon-pause:not(.sl-icon_non-interactive), button:hover .sl-icon-pause:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/pause-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/pause-hover-icon.svg") 0 0 / cover;
}

.sl-icon-play {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/play-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/play-default-icon.svg") 0 0 / cover;
}

.sl-icon-play:not(.sl-icon_non-interactive):hover, .sl-icon-play:not(.sl-icon_non-interactive):focus, .sl-icon-play.sl-icon_active, a:hover .sl-icon-play:not(.sl-icon_non-interactive), button:hover .sl-icon-play:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/play-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/play-hover-icon.svg") 0 0 / cover;
}

.sl-icon-prescription {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/prescription-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/prescription-default-icon.svg") 0 0 / cover;
}

.sl-icon-prescription:not(.sl-icon_non-interactive):hover, .sl-icon-prescription:not(.sl-icon_non-interactive):focus, .sl-icon-prescription.sl-icon_active, a:hover .sl-icon-prescription:not(.sl-icon_non-interactive), button:hover .sl-icon-prescription:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/prescription-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/prescription-hover-icon.svg") 0 0 / cover;
}

.sl-icon-print {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/print-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/print-default-icon.svg") 0 0 / cover;
}

.sl-icon-print:not(.sl-icon_non-interactive):hover, .sl-icon-print:not(.sl-icon_non-interactive):focus, .sl-icon-print.sl-icon_active, a:hover .sl-icon-print:not(.sl-icon_non-interactive), button:hover .sl-icon-print:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/print-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/print-hover-icon.svg") 0 0 / cover;
}

.sl-icon-receipt {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/receipt-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/receipt-default-icon.svg") 0 0 / cover;
}

.sl-icon-receipt:not(.sl-icon_non-interactive):hover, .sl-icon-receipt:not(.sl-icon_non-interactive):focus, .sl-icon-receipt.sl-icon_active, a:hover .sl-icon-receipt:not(.sl-icon_non-interactive), button:hover .sl-icon-receipt:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/receipt-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/receipt-hover-icon.svg") 0 0 / cover;
}

.sl-icon-recent-claim {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/recent-claim-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/recent-claim-default-icon.svg") 0 0 / cover;
}

.sl-icon-recent-claim:not(.sl-icon_non-interactive):hover, .sl-icon-recent-claim:not(.sl-icon_non-interactive):focus, .sl-icon-recent-claim.sl-icon_active, a:hover .sl-icon-recent-claim:not(.sl-icon_non-interactive), button:hover .sl-icon-recent-claim:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/recent-claim-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/recent-claim-hover-icon.svg") 0 0 / cover;
}

.sl-icon-refresh {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/refresh-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/refresh-default-icon.svg") 0 0 / cover;
}

.sl-icon-refresh:not(.sl-icon_non-interactive):hover, .sl-icon-refresh:not(.sl-icon_non-interactive):focus, .sl-icon-refresh.sl-icon_active, a:hover .sl-icon-refresh:not(.sl-icon_non-interactive), button:hover .sl-icon-refresh:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/refresh-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/refresh-hover-icon.svg") 0 0 / cover;
}

.sl-icon-resources {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/resources-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/resources-default-icon.svg") 0 0 / cover;
}

.sl-icon-resources:not(.sl-icon_non-interactive):hover, .sl-icon-resources:not(.sl-icon_non-interactive):focus, .sl-icon-resources.sl-icon_active, a:hover .sl-icon-resources:not(.sl-icon_non-interactive), button:hover .sl-icon-resources:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/resources-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/resources-hover-icon.svg") 0 0 / cover;
}

.sl-icon-search {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/search-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/search-default-icon.svg") 0 0 / cover;
}

.sl-icon-search:not(.sl-icon_non-interactive):hover, .sl-icon-search:not(.sl-icon_non-interactive):focus, .sl-icon-search.sl-icon_active, a:hover .sl-icon-search:not(.sl-icon_non-interactive), button:hover .sl-icon-search:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/search-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/search-hover-icon.svg") 0 0 / cover;
}

.sl-icon-settings {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/settings-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/settings-default-icon.svg") 0 0 / cover;
}

.sl-icon-settings:not(.sl-icon_non-interactive):hover, .sl-icon-settings:not(.sl-icon_non-interactive):focus, .sl-icon-settings.sl-icon_active, a:hover .sl-icon-settings:not(.sl-icon_non-interactive), button:hover .sl-icon-settings:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/settings-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/settings-hover-icon.svg") 0 0 / cover;
}

.sl-icon-sign-in {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sign-in-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sign-in-default-icon.svg") 0 0 / cover;
}

.sl-icon-sign-in:not(.sl-icon_non-interactive):hover, .sl-icon-sign-in:not(.sl-icon_non-interactive):focus, .sl-icon-sign-in.sl-icon_active, a:hover .sl-icon-sign-in:not(.sl-icon_non-interactive), button:hover .sl-icon-sign-in:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sign-in-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sign-in-hover-icon.svg") 0 0 / cover;
}

.sl-icon-sign-out {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sign-out-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sign-out-default-icon.svg") 0 0 / cover;
}

.sl-icon-sign-out:not(.sl-icon_non-interactive):hover, .sl-icon-sign-out:not(.sl-icon_non-interactive):focus, .sl-icon-sign-out.sl-icon_active, a:hover .sl-icon-sign-out:not(.sl-icon_non-interactive), button:hover .sl-icon-sign-out:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sign-out-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sign-out-hover-icon.svg") 0 0 / cover;
}

.sl-icon-sorting-ascending {
  background-color: #0000;
  background-image: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sorting-ascending-default-icon.svg");
  background-size: cover;
  width: 1.5rem;
  height: 1.5rem;
}

.sl-icon-sorting-ascending:not(.sl-icon_non-interactive):hover, .sl-icon-sorting-ascending:not(.sl-icon_non-interactive):focus, .sl-icon-sorting-ascending.sl-icon_active, a:hover .sl-icon-sorting-ascending:not(.sl-icon_non-interactive), button:hover .sl-icon-sorting-ascending:not(.sl-icon_non-interactive) {
  background-color: #0000;
  background-image: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sorting-ascending-hover-icon.svg");
  background-size: cover;
}

.sl-icon-sorting-descending {
  background-color: #0000;
  background-image: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sorting-descending-default-icon.svg");
  background-size: cover;
  width: 1.5rem;
  height: 1.5rem;
}

.sl-icon-sorting-descending:not(.sl-icon_non-interactive):hover, .sl-icon-sorting-descending:not(.sl-icon_non-interactive):focus, .sl-icon-sorting-descending.sl-icon_active, a:hover .sl-icon-sorting-descending:not(.sl-icon_non-interactive), button:hover .sl-icon-sorting-descending:not(.sl-icon_non-interactive) {
  background-color: #0000;
  background-image: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sorting-descending-hover-icon.svg");
  background-size: cover;
}

.sl-icon-sorting-none {
  background-color: #0000;
  background-image: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sorting-none-default-icon.svg");
  background-size: cover;
  width: 1.5rem;
  height: 1.5rem;
}

.sl-icon-sorting-none:not(.sl-icon_non-interactive):hover, .sl-icon-sorting-none:not(.sl-icon_non-interactive):focus, .sl-icon-sorting-none.sl-icon_active, a:hover .sl-icon-sorting-none:not(.sl-icon_non-interactive), button:hover .sl-icon-sorting-none:not(.sl-icon_non-interactive) {
  background-color: #0000;
  background-image: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sorting-none-hover-icon.svg");
  background-size: cover;
}

.sl-icon-star-empty {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/star-empty-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/star-empty-default-icon.svg") 0 0 / cover;
}

.sl-icon-star-empty:not(.sl-icon_non-interactive):hover, .sl-icon-star-empty:not(.sl-icon_non-interactive):focus, .sl-icon-star-empty.sl-icon_active, a:hover .sl-icon-star-empty:not(.sl-icon_non-interactive), button:hover .sl-icon-star-empty:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/star-empty-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/star-empty-hover-icon.svg") 0 0 / cover;
}

.sl-icon-star-full {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/star-full-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/star-full-default-icon.svg") 0 0 / cover;
}

.sl-icon-star-full:not(.sl-icon_non-interactive):hover, .sl-icon-star-full:not(.sl-icon_non-interactive):focus, .sl-icon-star-full.sl-icon_active, a:hover .sl-icon-star-full:not(.sl-icon_non-interactive), button:hover .sl-icon-star-full:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/star-full-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/star-full-hover-icon.svg") 0 0 / cover;
}

.sl-icon-star-half {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/star-half-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/star-half-default-icon.svg") 0 0 / cover;
}

.sl-icon-star-half:not(.sl-icon_non-interactive):hover, .sl-icon-star-half:not(.sl-icon_non-interactive):focus, .sl-icon-star-half.sl-icon_active, a:hover .sl-icon-star-half:not(.sl-icon_non-interactive), button:hover .sl-icon-star-half:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/star-half-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/star-half-hover-icon.svg") 0 0 / cover;
}

.sl-icon-submit-claim {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/submit-claim-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/submit-claim-default-icon.svg") 0 0 / cover;
}

.sl-icon-submit-claim:not(.sl-icon_non-interactive):hover, .sl-icon-submit-claim:not(.sl-icon_non-interactive):focus, .sl-icon-submit-claim.sl-icon_active, a:hover .sl-icon-submit-claim:not(.sl-icon_non-interactive), button:hover .sl-icon-submit-claim:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/submit-claim-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/submit-claim-hover-icon.svg") 0 0 / cover;
}

.sl-icon-trash {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/trash-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/trash-default-icon.svg") 0 0 / cover;
}

.sl-icon-trash:not(.sl-icon_non-interactive):hover, .sl-icon-trash:not(.sl-icon_non-interactive):focus, .sl-icon-trash.sl-icon_active, a:hover .sl-icon-trash:not(.sl-icon_non-interactive), button:hover .sl-icon-trash:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/trash-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/trash-hover-icon.svg") 0 0 / cover;
}

.sl-icon-vision {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/vision-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/vision-default-icon.svg") 0 0 / cover;
}

.sl-icon-vision:not(.sl-icon_non-interactive):hover, .sl-icon-vision:not(.sl-icon_non-interactive):focus, .sl-icon-vision.sl-icon_active, a:hover .sl-icon-vision:not(.sl-icon_non-interactive), button:hover .sl-icon-vision:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/vision-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/vision-hover-icon.svg") 0 0 / cover;
}

.sl-icon-volume {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/volume-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/volume-default-icon.svg") 0 0 / cover;
}

.sl-icon-volume:not(.sl-icon_non-interactive):hover, .sl-icon-volume:not(.sl-icon_non-interactive):focus, .sl-icon-volume.sl-icon_active, a:hover .sl-icon-volume:not(.sl-icon_non-interactive), button:hover .sl-icon-volume:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/volume-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/volume-hover-icon.svg") 0 0 / cover;
}

.sl-icon-withdraw {
  width: 1.5rem;
  height: 1.5rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/withdraw-default-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/withdraw-default-icon.svg") 0 0 / cover;
}

.sl-icon-withdraw:not(.sl-icon_non-interactive):hover, .sl-icon-withdraw:not(.sl-icon_non-interactive):focus, .sl-icon-withdraw.sl-icon_active, a:hover .sl-icon-withdraw:not(.sl-icon_non-interactive), button:hover .sl-icon-withdraw:not(.sl-icon_non-interactive) {
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/withdraw-hover-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/withdraw-hover-icon.svg") 0 0 / cover;
}

.sl-icon-info-accomplishment {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-accomplishment-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-accomplishment-icon.svg") 0 0 / cover;
}

.sl-icon-info-achievement-recognition {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-achievement-recognition-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-achievement-recognition-icon.svg") 0 0 / cover;
}

.sl-icon-info-activity {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-activity-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-activity-icon.svg") 0 0 / cover;
}

.sl-icon-info-analysis {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-analysis-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-analysis-icon.svg") 0 0 / cover;
}

.sl-icon-info-apply {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-apply-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-apply-icon.svg") 0 0 / cover;
}

.sl-icon-info-artificial-intelligence {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-artificial-intelligence-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-artificial-intelligence-icon.svg") 0 0 / cover;
}

.sl-icon-info-arts {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-arts-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-arts-icon.svg") 0 0 / cover;
}

.sl-icon-info-booking-appointment {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-booking-appointment-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-booking-appointment-icon.svg") 0 0 / cover;
}

.sl-icon-info-brace-child {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-brace-child-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-brace-child-icon.svg") 0 0 / cover;
}

.sl-icon-info-braces {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-braces-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-braces-icon.svg") 0 0 / cover;
}

.sl-icon-info-building {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-building-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-building-icon.svg") 0 0 / cover;
}

.sl-icon-info-build-wealth {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-build-wealth-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-build-wealth-icon.svg") 0 0 / cover;
}

.sl-icon-info-buy {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-buy-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-buy-icon.svg") 0 0 / cover;
}

.sl-icon-info-calculator {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-calculator-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-calculator-icon.svg") 0 0 / cover;
}

.sl-icon-info-calendar {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-calendar-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-calendar-icon.svg") 0 0 / cover;
}

.sl-icon-info-call {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-call-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-call-icon.svg") 0 0 / cover;
}

.sl-icon-info-camera {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-camera-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-camera-icon.svg") 0 0 / cover;
}

.sl-icon-info-cash-sign {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-cash-sign-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-cash-sign-icon.svg") 0 0 / cover;
}

.sl-icon-info-client {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-client-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-client-icon.svg") 0 0 / cover;
}

.sl-icon-info-clock {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-clock-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-clock-icon.svg") 0 0 / cover;
}

.sl-icon-info-closed-mail {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-closed-mail-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-closed-mail-icon.svg") 0 0 / cover;
}

.sl-icon-info-conduct {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-conduct-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-conduct-icon.svg") 0 0 / cover;
}

.sl-icon-info-confidence {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-confidence-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-confidence-icon.svg") 0 0 / cover;
}

.sl-icon-info-connection {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-connection-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-connection-icon.svg") 0 0 / cover;
}

.sl-icon-info-consider {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-consider-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-consider-icon.svg") 0 0 / cover;
}

.sl-icon-info-conversation {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-conversation-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-conversation-icon.svg") 0 0 / cover;
}

.sl-icon-info-cost {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-cost-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-cost-icon.svg") 0 0 / cover;
}

.sl-icon-info-direction {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-direction-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-direction-icon.svg") 0 0 / cover;
}

.sl-icon-info-disability-linear {
  width: 19.125rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-disability-linear-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-disability-linear-icon.svg") 0 0 / cover;
}

.sl-icon-info-disability-stacked {
  width: 10rem;
  height: 10rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-disability-stacked-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-disability-stacked-icon.svg") 0 0 / cover;
}

.sl-icon-info-diversity-inclusion-flag {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-diversity-inclusion-flag-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-diversity-inclusion-flag-icon.svg") 0 0 / cover;
}

.sl-icon-info-diversity-inclusion {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-diversity-inclusion-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-diversity-inclusion-icon.svg") 0 0 / cover;
}

.sl-icon-info-email {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-email-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-email-icon.svg") 0 0 / cover;
}

.sl-icon-info-emotions-problem {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-emotions-problem-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-emotions-problem-icon.svg") 0 0 / cover;
}

.sl-icon-info-emotions-solution {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-emotions-solution-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-emotions-solution-icon.svg") 0 0 / cover;
}

.sl-icon-info-empathy {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-empathy-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-empathy-icon.svg") 0 0 / cover;
}

.sl-icon-info-energy {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-energy-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-energy-icon.svg") 0 0 / cover;
}

.sl-icon-info-environment {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-environment-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-environment-icon.svg") 0 0 / cover;
}

.sl-icon-info-ethics {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-ethics-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-ethics-icon.svg") 0 0 / cover;
}

.sl-icon-info-family-friends {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-family-friends-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-family-friends-icon.svg") 0 0 / cover;
}

.sl-icon-info-financial-security {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-financial-security-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-financial-security-icon.svg") 0 0 / cover;
}

.sl-icon-info-financial-sustainability {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-financial-sustainability-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-financial-sustainability-icon.svg") 0 0 / cover;
}

.sl-icon-info-find-advisor {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-find-advisor-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-find-advisor-icon.svg") 0 0 / cover;
}

.sl-icon-info-find {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-find-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-find-icon.svg") 0 0 / cover;
}

.sl-icon-info-flexibility {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-flexibility-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-flexibility-icon.svg") 0 0 / cover;
}

.sl-icon-info-focus {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-focus-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-focus-icon.svg") 0 0 / cover;
}

.sl-icon-info-fund-wealth {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-fund-wealth-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-fund-wealth-icon.svg") 0 0 / cover;
}

.sl-icon-info-future-long-term {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-future-long-term-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-future-long-term-icon.svg") 0 0 / cover;
}

.sl-icon-info-giving {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-giving-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-giving-icon.svg") 0 0 / cover;
}

.sl-icon-info-globe {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-globe-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-globe-icon.svg") 0 0 / cover;
}

.sl-icon-info-ground-breaking {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-ground-breaking-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-ground-breaking-icon.svg") 0 0 / cover;
}

.sl-icon-info-group-plan {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-group-plan-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-group-plan-icon.svg") 0 0 / cover;
}

.sl-icon-info-growth-development {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-growth-development-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-growth-development-icon.svg") 0 0 / cover;
}

.sl-icon-info-idea-generation {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-idea-generation-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-idea-generation-icon.svg") 0 0 / cover;
}

.sl-icon-info-injury {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-injury-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-injury-icon.svg") 0 0 / cover;
}

.sl-icon-info-innovation {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-innovation-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-innovation-icon.svg") 0 0 / cover;
}

.sl-icon-info-insurance {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-insurance-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-insurance-icon.svg") 0 0 / cover;
}

.sl-icon-info-investment {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-investment-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-investment-icon.svg") 0 0 / cover;
}

.sl-icon-info-investments {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-investments-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-investments-icon.svg") 0 0 / cover;
}

.sl-icon-info-lab-analysis {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-lab-analysis-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-lab-analysis-icon.svg") 0 0 / cover;
}

.sl-icon-info-layers {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-layers-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-layers-icon.svg") 0 0 / cover;
}

.sl-icon-info-leadership {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-leadership-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-leadership-icon.svg") 0 0 / cover;
}

.sl-icon-info-leads-ecommerce {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-leads-ecommerce-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-leads-ecommerce-icon.svg") 0 0 / cover;
}

.sl-icon-info-life-insurance {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-life-insurance-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-life-insurance-icon.svg") 0 0 / cover;
}

.sl-icon-info-listening-customer-service {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-listening-customer-service-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-listening-customer-service-icon.svg") 0 0 / cover;
}

.sl-icon-info-listening-ear {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-listening-ear-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-listening-ear-icon.svg") 0 0 / cover;
}

.sl-icon-info-local-health {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-local-health-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-local-health-icon.svg") 0 0 / cover;
}

.sl-icon-info-location {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-location-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-location-icon.svg") 0 0 / cover;
}

.sl-icon-info-manage {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-manage-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-manage-icon.svg") 0 0 / cover;
}

.sl-icon-info-medical {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-medical-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-medical-icon.svg") 0 0 / cover;
}

.sl-icon-info-meet-advisor {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-meet-advisor-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-meet-advisor-icon.svg") 0 0 / cover;
}

.sl-icon-info-meet-ella {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-meet-ella-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-meet-ella-icon.svg") 0 0 / cover;
}

.sl-icon-info-megaphone {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-megaphone-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-megaphone-icon.svg") 0 0 / cover;
}

.sl-icon-info-mental-wellness {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-mental-wellness-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-mental-wellness-icon.svg") 0 0 / cover;
}

.sl-icon-info-mobile {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-mobile-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-mobile-icon.svg") 0 0 / cover;
}

.sl-icon-info-moon {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-moon-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-moon-icon.svg") 0 0 / cover;
}

.sl-icon-info-mortgage {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-mortgage-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-mortgage-icon.svg") 0 0 / cover;
}

.sl-icon-info-music {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-music-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-music-icon.svg") 0 0 / cover;
}

.sl-icon-info-mutual {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-mutual-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-mutual-icon.svg") 0 0 / cover;
}

.sl-icon-info-networth {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-networth-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-networth-icon.svg") 0 0 / cover;
}

.sl-icon-info-notification {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-notification-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-notification-icon.svg") 0 0 / cover;
}

.sl-icon-info-office-employee {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-office-employee-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-office-employee-icon.svg") 0 0 / cover;
}

.sl-icon-info-office {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-office-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-office-icon.svg") 0 0 / cover;
}

.sl-icon-info-online {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-online-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-online-icon.svg") 0 0 / cover;
}

.sl-icon-info-operational {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-operational-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-operational-icon.svg") 0 0 / cover;
}

.sl-icon-info-opportunities {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-opportunities-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-opportunities-icon.svg") 0 0 / cover;
}

.sl-icon-info-options {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-options-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-options-icon.svg") 0 0 / cover;
}

.sl-icon-info-organization {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-organization-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-organization-icon.svg") 0 0 / cover;
}

.sl-icon-info-overview-oversee {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-overview-oversee-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-overview-oversee-icon.svg") 0 0 / cover;
}

.sl-icon-info-pattern {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-pattern-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-pattern-icon.svg") 0 0 / cover;
}

.sl-icon-info-payout-ratio {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-payout-ratio-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-payout-ratio-icon.svg") 0 0 / cover;
}

.sl-icon-info-performance {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-performance-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-performance-icon.svg") 0 0 / cover;
}

.sl-icon-info-performance-management {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-performance-management-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-performance-management-icon.svg") 0 0 / cover;
}

.sl-icon-info-personalization {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-personalization-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-personalization-icon.svg") 0 0 / cover;
}

.sl-icon-info-physical-health-1 {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-physical-health-1-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-physical-health-1-icon.svg") 0 0 / cover;
}

.sl-icon-info-physical-health-2 {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-physical-health-2-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-physical-health-2-icon.svg") 0 0 / cover;
}

.sl-icon-info-physical-wellness {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-physical-wellness-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-physical-wellness-icon.svg") 0 0 / cover;
}

.sl-icon-info-premium-special {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-premium-special-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-premium-special-icon.svg") 0 0 / cover;
}

.sl-icon-info-presentation-advocacy {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-presentation-advocacy-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-presentation-advocacy-icon.svg") 0 0 / cover;
}

.sl-icon-info-presentation {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-presentation-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-presentation-icon.svg") 0 0 / cover;
}

.sl-icon-info-privacy-security {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-privacy-security-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-privacy-security-icon.svg") 0 0 / cover;
}

.sl-icon-info-problem {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-problem-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-problem-icon.svg") 0 0 / cover;
}

.sl-icon-info-products {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-products-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-products-icon.svg") 0 0 / cover;
}

.sl-icon-info-professionalism {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-professionalism-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-professionalism-icon.svg") 0 0 / cover;
}

.sl-icon-info-progression {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-progression-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-progression-icon.svg") 0 0 / cover;
}

.sl-icon-info-protection {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-protection-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-protection-icon.svg") 0 0 / cover;
}

.sl-icon-info-reach {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-reach-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-reach-icon.svg") 0 0 / cover;
}

.sl-icon-info-remove {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-remove-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-remove-icon.svg") 0 0 / cover;
}

.sl-icon-info-research-explore {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-research-explore-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-research-explore-icon.svg") 0 0 / cover;
}

.sl-icon-info-resolution {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-resolution-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-resolution-icon.svg") 0 0 / cover;
}

.sl-icon-info-resource {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-resource-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-resource-icon.svg") 0 0 / cover;
}

.sl-icon-info-results {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-results-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-results-icon.svg") 0 0 / cover;
}

.sl-icon-info-retire {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-retire-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-retire-icon.svg") 0 0 / cover;
}

.sl-icon-info-risk {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-risk-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-risk-icon.svg") 0 0 / cover;
}

.sl-icon-info-risk-2 {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-risk-2-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-risk-2-icon.svg") 0 0 / cover;
}

.sl-icon-info-road-map {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-road-map-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-road-map-icon.svg") 0 0 / cover;
}

.sl-icon-info-rrsp-mortgage-calculator {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-rrsp-mortgage-calculator-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-rrsp-mortgage-calculator-icon.svg") 0 0 / cover;
}

.sl-icon-info-sick {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-sick-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-sick-icon.svg") 0 0 / cover;
}

.sl-icon-info-social-media {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-social-media-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-social-media-icon.svg") 0 0 / cover;
}

.sl-icon-info-sort {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-sort-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-sort-icon.svg") 0 0 / cover;
}

.sl-icon-info-sort-2 {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-sort-2-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-sort-2-icon.svg") 0 0 / cover;
}

.sl-icon-info-sports {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-sports-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-sports-icon.svg") 0 0 / cover;
}

.sl-icon-info-stability {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-stability-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-stability-icon.svg") 0 0 / cover;
}

.sl-icon-info-starting {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-starting-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-starting-icon.svg") 0 0 / cover;
}

.sl-icon-info-storage {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-storage-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-storage-icon.svg") 0 0 / cover;
}

.sl-icon-info-strategy {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-strategy-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-strategy-icon.svg") 0 0 / cover;
}

.sl-icon-info-stress-management {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-stress-management-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-stress-management-icon.svg") 0 0 / cover;
}

.sl-icon-info-submit-claim {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-submit-claim-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-submit-claim-icon.svg") 0 0 / cover;
}

.sl-icon-info-sustainability {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-sustainability-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-sustainability-icon.svg") 0 0 / cover;
}

.sl-icon-info-sustainability-2 {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-sustainability-2-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-sustainability-2-icon.svg") 0 0 / cover;
}

.sl-icon-info-talk-advisor {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-talk-advisor-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-talk-advisor-icon.svg") 0 0 / cover;
}

.sl-icon-info-target {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-target-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-target-icon.svg") 0 0 / cover;
}

.sl-icon-info-teamwork-collaboration {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-teamwork-collaboration-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-teamwork-collaboration-icon.svg") 0 0 / cover;
}

.sl-icon-info-transform {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-transform-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-transform-icon.svg") 0 0 / cover;
}

.sl-icon-info-transportation {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-transportation-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-transportation-icon.svg") 0 0 / cover;
}

.sl-icon-info-travel-luggage {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-travel-luggage-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-travel-luggage-icon.svg") 0 0 / cover;
}

.sl-icon-info-travel-passport {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-travel-passport-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-travel-passport-icon.svg") 0 0 / cover;
}

.sl-icon-info-underwriting {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-underwriting-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-underwriting-icon.svg") 0 0 / cover;
}

.sl-icon-info-unique {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-unique-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-unique-icon.svg") 0 0 / cover;
}

.sl-icon-info-video {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-video-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-video-icon.svg") 0 0 / cover;
}

.sl-icon-info-workplace {
  width: 4rem;
  height: 4rem;
  -webkit-mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-workplace-icon.svg") 0 0 / cover;
  mask: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-workplace-icon.svg") 0 0 / cover;
}

.sl-icon_size_sm {
  width: 1.25rem;
  height: 1.25rem;
}

.sl-icon_size_md {
  width: 1.5rem;
  height: 1.5rem;
}

.sl-icon_size_lg {
  width: 1.75rem;
  height: 1.75rem;
}

.sl-icon_size_xl {
  width: 2rem;
  height: 2rem;
}

.sl-icon_color_information {
  background: var(--brand-secondary-800);
}

.sl-icon_color_success {
  background: var(--green-800);
}

.sl-icon_color_warning {
  background: var(--brand-primary-800);
}

.sl-icon_color_error {
  background: var(--red-800);
}

:root {
  --sl-icon-account: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/account-default-icon.svg");
  --sl-icon-account-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/account-hover-icon.svg");
  --sl-icon-add-circle: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/add-circle-default-icon.svg");
  --sl-icon-add-circle-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/add-circle-hover-icon.svg");
  --sl-icon-add-coverage: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/add-coverage-default-icon.svg");
  --sl-icon-add-coverage-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/add-coverage-hover-icon.svg");
  --sl-icon-add-prescription: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/add-prescription-default-icon.svg");
  --sl-icon-add-prescription-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/add-prescription-hover-icon.svg");
  --sl-icon-arrow-down: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/arrow-down-default-icon.svg");
  --sl-icon-arrow-down-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/arrow-down-hover-icon.svg");
  --sl-icon-arrow-up: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/arrow-up-default-icon.svg");
  --sl-icon-arrow-up-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/arrow-up-hover-icon.svg");
  --sl-icon-bars: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bars-default-icon.svg");
  --sl-icon-bars-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bars-hover-icon.svg");
  --sl-icon-bell-no: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bell-no-default-icon.svg");
  --sl-icon-bell-no-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bell-no-hover-icon.svg");
  --sl-icon-bell-yes: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bell-yes-default-icon.svg");
  --sl-icon-bell-yes-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bell-yes-hover-icon.svg");
  --sl-icon-bookmark: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bookmark-default-icon.svg");
  --sl-icon-bookmark-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/bookmark-hover-icon.svg");
  --sl-icon-briefcase: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/briefcase-default-icon.svg");
  --sl-icon-briefcase-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/briefcase-hover-icon.svg");
  --sl-icon-calendar: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/calendar-default-icon.svg");
  --sl-icon-calendar-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/calendar-hover-icon.svg");
  --sl-icon-call: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/call-default-icon.svg");
  --sl-icon-call-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/call-hover-icon.svg");
  --sl-icon-caret-down: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-down-default-icon.svg");
  --sl-icon-caret-down-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-down-hover-icon.svg");
  --sl-icon-caret-left: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-left-default-icon.svg");
  --sl-icon-caret-left-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-left-hover-icon.svg");
  --sl-icon-caret-right: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-right-default-icon.svg");
  --sl-icon-caret-right-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-right-hover-icon.svg");
  --sl-icon-caret-up: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-up-default-icon.svg");
  --sl-icon-caret-up-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/caret-up-hover-icon.svg");
  --sl-icon-chat: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chat-default-icon.svg");
  --sl-icon-chat-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chat-hover-icon.svg");
  --sl-icon-check: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-default-icon.svg");
  --sl-icon-check-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-hover-icon.svg");
  --sl-icon-check-circle: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-circle-default-icon.svg");
  --sl-icon-check-circle-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-circle-hover-icon.svg");
  --sl-icon-check-circle-active: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-circle-active-default-icon.svg");
  --sl-icon-check-grey: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-grey-default-icon.svg");
  --sl-icon-check-grey-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-grey-hover-icon.svg");
  --sl-icon-check-white: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-white-default-icon.svg");
  --sl-icon-check-white-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/check-white-hover-icon.svg");
  --sl-icon-chevron-circle-down: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-down-default-icon.svg");
  --sl-icon-chevron-circle-down-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-down-hover-icon.svg");
  --sl-icon-chevron-circle-left: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-left-default-icon.svg");
  --sl-icon-chevron-circle-left-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-left-hover-icon.svg");
  --sl-icon-chevron-circle-right: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-right-default-icon.svg");
  --sl-icon-chevron-circle-right-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-right-hover-icon.svg");
  --sl-icon-chevron-circle-up: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-up-default-icon.svg");
  --sl-icon-chevron-circle-up-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-circle-up-hover-icon.svg");
  --sl-icon-chevron-double-left: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-double-left-default-icon.svg");
  --sl-icon-chevron-double-left-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-double-left-hover-icon.svg");
  --sl-icon-chevron-double-right: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-double-right-default-icon.svg");
  --sl-icon-chevron-double-right-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-double-right-hover-icon.svg");
  --sl-icon-chevron-down: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-down-default-icon.svg");
  --sl-icon-chevron-down-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-down-hover-icon.svg");
  --sl-icon-chevron-last-left: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-last-left-default-icon.svg");
  --sl-icon-chevron-last-left-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-last-left-hover-icon.svg");
  --sl-icon-chevron-last-right: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-last-right-default-icon.svg");
  --sl-icon-chevron-last-right-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-last-right-hover-icon.svg");
  --sl-icon-chevron-left: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-left-default-icon.svg");
  --sl-icon-chevron-left-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-left-hover-icon.svg");
  --sl-icon-chevron-right: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-right-default-icon.svg");
  --sl-icon-chevron-right-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-right-hover-icon.svg");
  --sl-icon-chevron-up: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-up-default-icon.svg");
  --sl-icon-chevron-up-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/chevron-up-hover-icon.svg");
  --sl-icon-circle-indicator: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/circle-indicator-default-icon.svg");
  --sl-icon-circle-indicator-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/circle-indicator-hover-icon.svg");
  --sl-icon-claim-coverage: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/claim-coverage-default-icon.svg");
  --sl-icon-claim-coverage-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/claim-coverage-hover-icon.svg");
  --sl-icon-claim: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/claim-default-icon.svg");
  --sl-icon-claim-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/claim-hover-icon.svg");
  --sl-icon-close-circle: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/close-circle-default-icon.svg");
  --sl-icon-close-circle-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/close-circle-hover-icon.svg");
  --sl-icon-close: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/close-default-icon.svg");
  --sl-icon-close-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/close-hover-icon.svg");
  --sl-icon-contribution: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/contribution-default-icon.svg");
  --sl-icon-contribution-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/contribution-hover-icon.svg");
  --sl-icon-coverage-card: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/coverage-card-default-icon.svg");
  --sl-icon-coverage-card-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/coverage-card-hover-icon.svg");
  --sl-icon-coverage-info: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/coverage-info-default-icon.svg");
  --sl-icon-coverage-info-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/coverage-info-hover-icon.svg");
  --sl-icon-credit: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/credit-default-icon.svg");
  --sl-icon-credit-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/credit-hover-icon.svg");
  --sl-icon-dashboard: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/dashboard-default-icon.svg");
  --sl-icon-dashboard-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/dashboard-hover-icon.svg");
  --sl-icon-dental: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/dental-default-icon.svg");
  --sl-icon-dental-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/dental-hover-icon.svg");
  --sl-icon-disability: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/disability-default-icon.svg");
  --sl-icon-disability-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/disability-hover-icon.svg");
  --sl-icon-dollar-sign: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/dollar-sign-default-icon.svg");
  --sl-icon-dollar-sign-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/dollar-sign-hover-icon.svg");
  --sl-icon-download: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/download-default-icon.svg");
  --sl-icon-download-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/download-hover-icon.svg");
  --sl-icon-drug: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/drug-default-icon.svg");
  --sl-icon-drug-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/drug-hover-icon.svg");
  --sl-icon-edit: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/edit-default-icon.svg");
  --sl-icon-edit-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/edit-hover-icon.svg");
  --sl-icon-ellipsis-horizontal: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/ellipsis-horizontal-default-icon.svg");
  --sl-icon-ellipsis-horizontal-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/ellipsis-horizontal-hover-icon.svg");
  --sl-icon-ellipsis-vertical: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/ellipsis-vertical-default-icon.svg");
  --sl-icon-ellipsis-vertical-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/ellipsis-vertical-hover-icon.svg");
  --sl-icon-email: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/email-default-icon.svg");
  --sl-icon-email-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/email-hover-icon.svg");
  --sl-icon-exclamation-circle: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/exclamation-circle-default-icon.svg");
  --sl-icon-exclamation-circle-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/exclamation-circle-hover-icon.svg");
  --sl-icon-exclamation-triangle: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/exclamation-triangle-default-icon.svg");
  --sl-icon-exclamation-triangle-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/exclamation-triangle-hover-icon.svg");
  --sl-icon-external-link: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/external-link-default-icon.svg");
  --sl-icon-external-link-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/external-link-hover-icon.svg");
  --sl-icon-eye-reveal: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/eye-reveal-default-icon.svg");
  --sl-icon-eye-reveal-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/eye-reveal-hover-icon.svg");
  --sl-icon-eye-unreveal: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/eye-unreveal-default-icon.svg");
  --sl-icon-eye-unreveal-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/eye-unreveal-hover-icon.svg");
  --sl-icon-file-upload: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/file-upload-default-icon.svg");
  --sl-icon-file-upload-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/file-upload-hover-icon.svg");
  --sl-icon-filter: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/filter-default-icon.svg");
  --sl-icon-filter-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/filter-hover-icon.svg");
  --sl-icon-find-an-advisor: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/find-an-advisor-default-icon.svg");
  --sl-icon-find-an-advisor-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/find-an-advisor-hover-icon.svg");
  --sl-icon-fitness: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/fitness-default-icon.svg");
  --sl-icon-fitness-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/fitness-hover-icon.svg");
  --sl-icon-grip-lines: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/grip-lines-default-icon.svg");
  --sl-icon-grip-lines-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/grip-lines-hover-icon.svg");
  --sl-icon-home: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/home-default-icon.svg");
  --sl-icon-home-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/home-hover-icon.svg");
  --sl-icon-info: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-default-icon.svg");
  --sl-icon-info-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-hover-icon.svg");
  --sl-icon-insurance: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/insurance-default-icon.svg");
  --sl-icon-insurance-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/insurance-hover-icon.svg");
  --sl-icon-investment: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/investment-default-icon.svg");
  --sl-icon-investment-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/investment-hover-icon.svg");
  --sl-icon-language: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/language-default-icon.svg");
  --sl-icon-language-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/language-hover-icon.svg");
  --sl-icon-link: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/link-default-icon.svg");
  --sl-icon-link-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/link-hover-icon.svg");
  --sl-icon-location: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/location-default-icon.svg");
  --sl-icon-location-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/location-hover-icon.svg");
  --sl-icon-lock: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/lock-default-icon.svg");
  --sl-icon-lock-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/lock-hover-icon.svg");
  --sl-icon-manage-beneficiaries: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/manage-beneficiaries-default-icon.svg");
  --sl-icon-manage-beneficiaries-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/manage-beneficiaries-hover-icon.svg");
  --sl-icon-medical: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/medical-default-icon.svg");
  --sl-icon-medical-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/medical-hover-icon.svg");
  --sl-icon-minus-circle: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/minus-circle-default-icon.svg");
  --sl-icon-minus-circle-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/minus-circle-hover-icon.svg");
  --sl-icon-minus: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/minus-default-icon.svg");
  --sl-icon-minus-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/minus-hover-icon.svg");
  --sl-icon-minus-white: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/minus-white-default-icon.svg");
  --sl-icon-minus-white-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/minus-white-hover-icon.svg");
  --sl-icon-more: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/more-default-icon.svg");
  --sl-icon-more-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/more-hover-icon.svg");
  --sl-icon-new: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/new-default-icon.svg");
  --sl-icon-new-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/new-hover-icon.svg");
  --sl-icon-order: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/order-default-icon.svg");
  --sl-icon-order-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/order-hover-icon.svg");
  --sl-icon-partially-check: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/partially-check-default-icon.svg");
  --sl-icon-partially-check-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/partially-check-hover-icon.svg");
  --sl-icon-pause: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/pause-default-icon.svg");
  --sl-icon-pause-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/pause-hover-icon.svg");
  --sl-icon-play: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/play-default-icon.svg");
  --sl-icon-play-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/play-hover-icon.svg");
  --sl-icon-prescription: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/prescription-default-icon.svg");
  --sl-icon-prescription-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/prescription-hover-icon.svg");
  --sl-icon-print: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/print-default-icon.svg");
  --sl-icon-print-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/print-hover-icon.svg");
  --sl-icon-receipt: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/receipt-default-icon.svg");
  --sl-icon-receipt-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/receipt-hover-icon.svg");
  --sl-icon-recent-claim: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/recent-claim-default-icon.svg");
  --sl-icon-recent-claim-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/recent-claim-hover-icon.svg");
  --sl-icon-refresh: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/refresh-default-icon.svg");
  --sl-icon-refresh-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/refresh-hover-icon.svg");
  --sl-icon-resources: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/resources-default-icon.svg");
  --sl-icon-resources-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/resources-hover-icon.svg");
  --sl-icon-search: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/search-default-icon.svg");
  --sl-icon-search-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/search-hover-icon.svg");
  --sl-icon-settings: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/settings-default-icon.svg");
  --sl-icon-settings-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/settings-hover-icon.svg");
  --sl-icon-sign-in: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sign-in-default-icon.svg");
  --sl-icon-sign-in-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sign-in-hover-icon.svg");
  --sl-icon-sign-out: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sign-out-default-icon.svg");
  --sl-icon-sign-out-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sign-out-hover-icon.svg");
  --sl-icon-sorting-ascending: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sorting-ascending-default-icon.svg");
  --sl-icon-sorting-ascending-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sorting-ascending-hover-icon.svg");
  --sl-icon-sorting-descending: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sorting-descending-default-icon.svg");
  --sl-icon-sorting-descending-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sorting-descending-hover-icon.svg");
  --sl-icon-sorting-none: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sorting-none-default-icon.svg");
  --sl-icon-sorting-none-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/sorting-none-hover-icon.svg");
  --sl-icon-star-empty: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/star-empty-default-icon.svg");
  --sl-icon-star-empty-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/star-empty-hover-icon.svg");
  --sl-icon-star-full: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/star-full-default-icon.svg");
  --sl-icon-star-full-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/star-full-hover-icon.svg");
  --sl-icon-star-half: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/star-half-default-icon.svg");
  --sl-icon-star-half-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/star-half-hover-icon.svg");
  --sl-icon-submit-claim: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/submit-claim-default-icon.svg");
  --sl-icon-submit-claim-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/submit-claim-hover-icon.svg");
  --sl-icon-trash: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/trash-default-icon.svg");
  --sl-icon-trash-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/trash-hover-icon.svg");
  --sl-icon-vision: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/vision-default-icon.svg");
  --sl-icon-vision-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/vision-hover-icon.svg");
  --sl-icon-volume: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/volume-default-icon.svg");
  --sl-icon-volume-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/volume-hover-icon.svg");
  --sl-icon-withdraw: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/withdraw-default-icon.svg");
  --sl-icon-withdraw-hover: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/withdraw-hover-icon.svg");
  --sl-icon-info-accomplishment: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-accomplishment-icon.svg");
  --sl-icon-info-achievement-recognition: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-achievement-recognition-icon.svg");
  --sl-icon-info-activity: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-activity-icon.svg");
  --sl-icon-info-analysis: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-analysis-icon.svg");
  --sl-icon-info-apply: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-apply-icon.svg");
  --sl-icon-info-artificial-intelligence: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-artificial-intelligence-icon.svg");
  --sl-icon-info-arts: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-arts-icon.svg");
  --sl-icon-info-booking-appointment: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-booking-appointment-icon.svg");
  --sl-icon-info-brace-child: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-brace-child-icon.svg");
  --sl-icon-info-braces: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-braces-icon.svg");
  --sl-icon-info-building: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-building-icon.svg");
  --sl-icon-info-build-wealth: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-build-wealth-icon.svg");
  --sl-icon-info-buy: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-buy-icon.svg");
  --sl-icon-info-calculator: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-calculator-icon.svg");
  --sl-icon-info-calendar: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-calendar-icon.svg");
  --sl-icon-info-call: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-call-icon.svg");
  --sl-icon-info-camera: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-camera-icon.svg");
  --sl-icon-info-cash-sign: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-cash-sign-icon.svg");
  --sl-icon-info-client: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-client-icon.svg");
  --sl-icon-info-clock: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-clock-icon.svg");
  --sl-icon-info-closed-mail: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-closed-mail-icon.svg");
  --sl-icon-info-conduct: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-conduct-icon.svg");
  --sl-icon-info-confidence: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-confidence-icon.svg");
  --sl-icon-info-connection: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-connection-icon.svg");
  --sl-icon-info-consider: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-consider-icon.svg");
  --sl-icon-info-conversation: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-conversation-icon.svg");
  --sl-icon-info-cost: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-cost-icon.svg");
  --sl-icon-info-direction: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-direction-icon.svg");
  --sl-icon-info-disability-linear: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-disability-linear-icon.svg");
  --sl-icon-info-disability-stacked: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-disability-stacked-icon.svg");
  --sl-icon-info-diversity-inclusion-flag: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-diversity-inclusion-flag-icon.svg");
  --sl-icon-info-diversity-inclusion: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-diversity-inclusion-icon.svg");
  --sl-icon-info-email: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-email-icon.svg");
  --sl-icon-info-emotions-problem: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-emotions-problem-icon.svg");
  --sl-icon-info-emotions-solution: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-emotions-solution-icon.svg");
  --sl-icon-info-empathy: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-empathy-icon.svg");
  --sl-icon-info-energy: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-energy-icon.svg");
  --sl-icon-info-environment: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-environment-icon.svg");
  --sl-icon-info-ethics: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-ethics-icon.svg");
  --sl-icon-info-family-friends: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-family-friends-icon.svg");
  --sl-icon-info-financial-security: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-financial-security-icon.svg");
  --sl-icon-info-financial-sustainability: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-financial-sustainability-icon.svg");
  --sl-icon-info-find-advisor: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-find-advisor-icon.svg");
  --sl-icon-info-find: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-find-icon.svg");
  --sl-icon-info-flexibility: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-flexibility-icon.svg");
  --sl-icon-info-focus: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-focus-icon.svg");
  --sl-icon-info-fund-wealth: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-fund-wealth-icon.svg");
  --sl-icon-info-future-long-term: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-future-long-term-icon.svg");
  --sl-icon-info-giving: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-giving-icon.svg");
  --sl-icon-info-globe: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-globe-icon.svg");
  --sl-icon-info-ground-breaking: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-ground-breaking-icon.svg");
  --sl-icon-info-group-plan: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-group-plan-icon.svg");
  --sl-icon-info-growth-development: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-growth-development-icon.svg");
  --sl-icon-info-idea-generation: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-idea-generation-icon.svg");
  --sl-icon-info-injury: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-injury-icon.svg");
  --sl-icon-info-innovation: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-innovation-icon.svg");
  --sl-icon-info-insurance: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-insurance-icon.svg");
  --sl-icon-info-investment: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-investment-icon.svg");
  --sl-icon-info-investments: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-investments-icon.svg");
  --sl-icon-info-lab-analysis: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-lab-analysis-icon.svg");
  --sl-icon-info-layers: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-layers-icon.svg");
  --sl-icon-info-leadership: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-leadership-icon.svg");
  --sl-icon-info-leads-ecommerce: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-leads-ecommerce-icon.svg");
  --sl-icon-info-life-insurance: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-life-insurance-icon.svg");
  --sl-icon-info-listening-customer-service: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-listening-customer-service-icon.svg");
  --sl-icon-info-listening-ear: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-listening-ear-icon.svg");
  --sl-icon-info-local-health: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-local-health-icon.svg");
  --sl-icon-info-location: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-location-icon.svg");
  --sl-icon-info-manage: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-manage-icon.svg");
  --sl-icon-info-medical: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-medical-icon.svg");
  --sl-icon-info-meet-advisor: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-meet-advisor-icon.svg");
  --sl-icon-info-meet-ella: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-meet-ella-icon.svg");
  --sl-icon-info-megaphone: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-megaphone-icon.svg");
  --sl-icon-info-mental-wellness: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-mental-wellness-icon.svg");
  --sl-icon-info-mobile: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-mobile-icon.svg");
  --sl-icon-info-moon: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-moon-icon.svg");
  --sl-icon-info-mortgage: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-mortgage-icon.svg");
  --sl-icon-info-music: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-music-icon.svg");
  --sl-icon-info-mutual: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-mutual-icon.svg");
  --sl-icon-info-networth: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-networth-icon.svg");
  --sl-icon-info-notification: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-notification-icon.svg");
  --sl-icon-info-office-employee: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-office-employee-icon.svg");
  --sl-icon-info-office: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-office-icon.svg");
  --sl-icon-info-online: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-online-icon.svg");
  --sl-icon-info-operational: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-operational-icon.svg");
  --sl-icon-info-opportunities: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-opportunities-icon.svg");
  --sl-icon-info-options: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-options-icon.svg");
  --sl-icon-info-organization: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-organization-icon.svg");
  --sl-icon-info-overview-oversee: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-overview-oversee-icon.svg");
  --sl-icon-info-pattern: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-pattern-icon.svg");
  --sl-icon-info-payout-ratio: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-payout-ratio-icon.svg");
  --sl-icon-info-performance: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-performance-icon.svg");
  --sl-icon-info-performance-management: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-performance-management-icon.svg");
  --sl-icon-info-personalization: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-personalization-icon.svg");
  --sl-icon-info-physical-health-1: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-physical-health-1-icon.svg");
  --sl-icon-info-physical-health-2: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-physical-health-2-icon.svg");
  --sl-icon-info-physical-wellness: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-physical-wellness-icon.svg");
  --sl-icon-info-premium-special: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-premium-special-icon.svg");
  --sl-icon-info-presentation-advocacy: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-presentation-advocacy-icon.svg");
  --sl-icon-info-presentation: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-presentation-icon.svg");
  --sl-icon-info-privacy-security: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-privacy-security-icon.svg");
  --sl-icon-info-problem: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-problem-icon.svg");
  --sl-icon-info-products: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-products-icon.svg");
  --sl-icon-info-professionalism: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-professionalism-icon.svg");
  --sl-icon-info-progression: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-progression-icon.svg");
  --sl-icon-info-protection: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-protection-icon.svg");
  --sl-icon-info-reach: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-reach-icon.svg");
  --sl-icon-info-remove: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-remove-icon.svg");
  --sl-icon-info-research-explore: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-research-explore-icon.svg");
  --sl-icon-info-resolution: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-resolution-icon.svg");
  --sl-icon-info-resource: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-resource-icon.svg");
  --sl-icon-info-results: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-results-icon.svg");
  --sl-icon-info-retire: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-retire-icon.svg");
  --sl-icon-info-risk: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-risk-icon.svg");
  --sl-icon-info-risk-2: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-risk-2-icon.svg");
  --sl-icon-info-road-map: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-road-map-icon.svg");
  --sl-icon-info-rrsp-mortgage-calculator: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-rrsp-mortgage-calculator-icon.svg");
  --sl-icon-info-sick: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-sick-icon.svg");
  --sl-icon-info-social-media: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-social-media-icon.svg");
  --sl-icon-info-sort: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-sort-icon.svg");
  --sl-icon-info-sort-2: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-sort-2-icon.svg");
  --sl-icon-info-sports: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-sports-icon.svg");
  --sl-icon-info-stability: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-stability-icon.svg");
  --sl-icon-info-starting: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-starting-icon.svg");
  --sl-icon-info-storage: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-storage-icon.svg");
  --sl-icon-info-strategy: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-strategy-icon.svg");
  --sl-icon-info-stress-management: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-stress-management-icon.svg");
  --sl-icon-info-submit-claim: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-submit-claim-icon.svg");
  --sl-icon-info-sustainability: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-sustainability-icon.svg");
  --sl-icon-info-sustainability-2: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-sustainability-2-icon.svg");
  --sl-icon-info-talk-advisor: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-talk-advisor-icon.svg");
  --sl-icon-info-target: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-target-icon.svg");
  --sl-icon-info-teamwork-collaboration: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-teamwork-collaboration-icon.svg");
  --sl-icon-info-transform: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-transform-icon.svg");
  --sl-icon-info-transportation: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-transportation-icon.svg");
  --sl-icon-info-travel-luggage: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-travel-luggage-icon.svg");
  --sl-icon-info-travel-passport: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-travel-passport-icon.svg");
  --sl-icon-info-underwriting: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-underwriting-icon.svg");
  --sl-icon-info-unique: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-unique-icon.svg");
  --sl-icon-info-video: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-video-icon.svg");
  --sl-icon-info-workplace: url("https://cdn.sunlife.com/content/dam/sunlife/global/shared-assets/graphics/info-workplace-icon.svg");
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius)  - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #0d6efd40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme="dark"] .accordion-button:after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.sl-accordion, .sl-accordion .h2 {
  margin: 0 0 1.5rem;
}

.sl-accordion .accordion-item:hover {
  background-color: var(--grey-100);
}

.sl-accordion .accordion-item .accordion-header .accordion-button {
  padding: 1.5rem;
}

.sl-accordion .accordion-item .accordion-header .accordion-button:hover {
  background-color: var(--grey-100);
}

.sl-accordion .accordion-item .accordion-header .accordion-button:focus {
  border: .125rem solid var(--brand-tertiary-500);
  box-shadow: none;
}

.sl-accordion .accordion-item .accordion-header .accordion-button .accordion-icon-container {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--grey-800);
  justify-content: space-around;
  align-items: center;
  margin: 0 1rem 0 0;
  display: flex;
}

.sl-accordion .accordion-item .accordion-header .accordion-button:after {
  display: none;
}

.sl-accordion .accordion-item .accordion-header .accordion-button .accordion-item-heading {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
}

.sl-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  color: var(--grey-800);
  box-shadow: none;
  background-color: #0000;
}

.sl-accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):after {
  display: none;
}

.sl-accordion .accordion-item .accordion-body {
  margin: 0 1.5rem 1.5rem 4rem;
  padding: 0;
}

@media (width <= 599.98px) {
  .sl-accordion .accordion-item .accordion-header .accordion-button {
    padding: 1.5rem 1rem;
  }

  .sl-accordion .accordion-item .accordion-header .accordion-button .accordion-icon-container {
    margin: 0 .5rem 0 0;
  }

  .sl-accordion .accordion-item .accordion-body {
    margin: 0 1rem 1.5rem 3rem;
  }
}

.sl-avatar img {
  border: .0625rem solid var(--grey-400);
  object-fit: cover;
  border-radius: 50%;
}

.sl-avatar .small {
  width: 4rem;
  height: 4rem;
  margin: 1rem;
}

.sl-avatar .medium {
  width: 5.5rem;
  height: 5.5rem;
  margin: 1.5rem;
}

.sl-avatar .large {
  width: 10rem;
  height: 10rem;
  margin: 1.5rem;
}

.sl-avatar-blocks .card {
  --bs-card-spacer-y: 0rem;
  --bs-card-spacer-x: 0rem;
  --bs-card-border-width: 0rem;
  --bs-card-border-color: none;
  background: none;
  flex-flow: column wrap;
  display: flex;
}

@media (width >= 600px) {
  .sl-avatar-blocks .card {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@media (width >= 1240px) {
  .sl-avatar-blocks .card.avatar-body {
    width: 66.67%;
  }
}

.sl-avatar-blocks .card .sl-avatar .large, .sl-avatar-blocks .card .sl-avatar .medium {
  margin: 0 0 1.5rem;
}

@media (width >= 600px) {
  .sl-avatar-blocks .card .sl-avatar .large, .sl-avatar-blocks .card .sl-avatar .medium {
    margin: 0 1.5rem 0 0;
  }
}

.sl-avatar-blocks .card .card-body .card-title {
  --bs-card-title-spacer-y: .5rem;
  --bs-card-title-color: var(--grey-900);
}

.sl-avatar-blocks .card .card-body .card-text {
  color: var(--grey-700);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-avatar-blocks .card .card-body .avatar-link {
  margin-top: .5rem;
}

.sl-avatar-blocks .card .body-quote {
  color: var(--grey-900);
  margin-top: 1rem;
  font-family: Sun Life New Text Italic, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-avatar-blocks.multi {
  flex-flow: column wrap;
  justify-content: space-between;
  row-gap: 3.5rem;
  display: flex;
}

@media (width >= 1240px) {
  .sl-avatar-blocks.multi {
    flex-direction: row;
  }

  .sl-avatar-blocks.multi .card {
    min-width: 35.25rem;
  }
}

.badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.sl-badge {
  white-space: pre-wrap;
  word-break: break-word;
  min-width: 3rem;
  max-width: 12.5rem;
  min-height: 1.75rem;
  color: var(--grey-900);
  border-radius: 1.875rem;
  align-items: center;
  padding: .375rem 1rem;
  font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1rem;
  display: flex;
}

.sl-badge .badge-icon-container {
  align-items: center;
  min-width: 1.25rem;
  height: 1.25rem;
  margin: 0 .25rem 0 0;
  display: flex;
}

.sl-badge .badge-icon-container .badge-icon {
  vertical-align: middle;
}

.sl-badge .badge-label {
  text-align: left;
  margin: auto;
}

.sl-badge.sl-badge-with-icon {
  padding: .25rem 1rem;
}

.sl-badge.bg-success {
  color: var(--green-900);
  border: .0625rem solid var(--green-900);
  background-color: var(--green-200) !important;
}

.sl-badge.bg-warning {
  color: var(--orange-900);
  border: .0625rem solid var(--orange-900);
  background-color: var(--orange-200) !important;
}

.sl-badge.bg-error {
  color: var(--red-900);
  background-color: var(--red-200);
  border: .0625rem solid var(--red-900);
}

.sl-badge.bg-info {
  color: var(--brand-secondary-900);
  border: .0625rem solid var(--brand-secondary-900);
  background-color: var(--brand-secondary-100) !important;
}

.sl-badge.bg-disabled {
  color: var(--grey-700);
  background-color: var(--grey-200);
}

.sl-banner-content-block {
  background-color: var(--brand-primary-50);
  width: 100vw;
  margin: 2.5rem 0;
  position: fixed;
  left: 0;
  right: 0;
}

.sl-banner-content-block .card {
  --bs-card-spacer-y: 0rem;
  --bs-card-spacer-x: 0rem;
  --bs-card-border-width: 0rem;
  --bs-card-border-color: none;
}

.sl-banner-content-block .card .card-body {
  background-color: var(--brand-primary-50);
  padding: 2.5rem 0;
}

@media (width <= 904.98px) {
  .sl-banner-content-block .card .card-body {
    padding: 1.5rem 0;
  }
}

.sl-banner-content-block .card .card-body .card-text {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-banner-content-block .card .card-body .banner-header {
  color: var(--grey-900);
  margin-bottom: .5rem;
}

.sl-banner-content-block .card .card-body .grouped-form {
  flex-direction: column;
  width: 100%;
  margin: 1.5rem 0 0;
  display: flex;
}

@media (width >= 905px) {
  .sl-banner-content-block .card .card-body .grouped-form {
    flex-direction: row;
  }
}

.sl-banner-content-block .card .card-body .grouped-form .textfield-container {
  width: 100%;
}

@media (width >= 905px) {
  .sl-banner-content-block .card .card-body .grouped-form .textfield-container {
    width: calc(50vw - 3.75rem - 12px);
  }
}

@media (width >= 1240px) {
  .sl-banner-content-block .card .card-body .grouped-form .textfield-container {
    width: 368px;
  }
}

.sl-banner-content-block .card .card-body .grouped-form .button-container {
  margin: 0 0 0 1.5rem;
}

@media (width <= 904.98px) {
  .sl-banner-content-block .card .card-body .grouped-form .button-container {
    width: 100%;
    margin: 1.5rem 0;
  }
}

.sl-banner-content-block .card .card-body .caption-container {
  color: var(--grey-900);
  margin: 1.5rem 0;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  display: flex;
}

.sl-banner-content-block .card .card-body .caption-container .popover-container {
  margin-left: .25rem;
}

.sl-banner-content-block .card .card-body .icon-container {
  margin-bottom: 1.5rem;
}

.sl-banner-image-block.card {
  --bs-card-spacer-y: 1.5rem;
  --bs-card-spacer-x: 1.5rem;
  --bs-card-title-spacer-y: 1rem;
  --bs-card-border-width: 0rem;
}

.sl-banner-image-block .card-title {
  --bs-card-title-color: var(--grey-900);
}

.sl-banner-image-block .card-text {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-banner-image-block .body-container {
  align-items: center;
  display: flex;
}

.sl-banner-image-block.banner-image-block-left, .sl-banner-image-block.banner-image-block-right {
  background-color: var(--brand-primary-50);
  padding: 1.5rem;
}

@media (width <= 904.98px) {
  .sl-banner-image-block.banner-image-block-left .card-body, .sl-banner-image-block.banner-image-block-right .card-body {
    padding: 1.5rem 0 0;
  }
}

.sl-banner-image-block.banner-image-block-left .card-body .card-text .caption-text, .sl-banner-image-block.banner-image-block-right .card-body .card-text .caption-text {
  color: var(--grey-700);
  margin-top: .5rem;
  margin-bottom: 0;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

.sl-banner-image-block.banner-image-block-left .card-body button, .sl-banner-image-block.banner-image-block-left .card-body a, .sl-banner-image-block.banner-image-block-right .card-body button, .sl-banner-image-block.banner-image-block-right .card-body a {
  margin-top: 1.5rem;
  display: block;
}

.sl-banner-image-block.banner-image-block-left picture img, .sl-banner-image-block.banner-image-block-right picture img {
  object-fit: cover;
  height: 100%;
}

@media (width <= 904.98px) {
  .sl-banner-image-block.banner-image-block-right .banner-image-block-row {
    flex-direction: column-reverse;
  }
}

.sl-breadcrumbs {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: inline-block;
}

@media (width <= 904.98px) {
  .sl-breadcrumbs {
    display: none;
  }
}

@media (width >= 905px) {
  .sl-breadcrumbs {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
}

@media (width >= 1240px) {
  .sl-breadcrumbs {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }
}

@media (width >= 1440px) {
  .sl-breadcrumbs {
    padding-left: 9rem;
    padding-right: 9rem;
  }
}

.sl-breadcrumbs .breadcrumb-item {
  justify-content: center;
  align-items: center;
  margin-top: .25rem;
  margin-bottom: .25rem;
  display: inline-flex;
}

.sl-breadcrumbs .breadcrumb-component-item {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.sl-breadcrumbs .breadcrumb-separator {
  width: .375rem;
  height: 1.3125rem;
  color: var(--grey-300);
  margin-bottom: 0;
  margin-left: .75rem;
  margin-right: .75rem;
  font-size: .875rem;
  line-height: 1.3125rem;
}

.sl-breadcrumbs .current-item {
  color: var(--grey-900);
  justify-content: center;
  align-items: center;
  margin-top: .25rem;
  margin-bottom: .25rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  display: inline-flex;
}

.btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.sl-btn {
  --bs-btn-padding-x: 2rem;
  --bs-btn-padding-y: .75rem;
  text-align: center;
  border-width: 0;
  justify-content: center;
  font-weight: 700;
  text-decoration: none;
}

.sl-btn .icon-before {
  padding-right: .5rem;
}

.sl-btn .icon-after {
  padding-left: .5rem;
}

.sl-btn.full {
  width: 100%;
}

.sl-btn.btn {
  color: var(--grey-900);
  align-items: center;
  height: 3rem;
  font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  display: flex;
}

.sl-btn.btn.btn-primary {
  background-color: var(--brand-primary-400);
  color: var(--brand-tertiary-900);
}

.sl-btn.btn.btn-primary:hover {
  background-color: var(--brand-primary-500);
  border-color: var(--brand-primary-500);
}

.sl-btn.btn.btn-secondary, .sl-btn.btn.btn-secondary-small {
  border: .125rem solid var(--brand-tertiary-800);
  color: var(--brand-tertiary-900);
  background-color: #0000;
}

.sl-btn.btn.btn-secondary:hover:not(:disabled), .sl-btn.btn.btn-secondary-small:hover:not(:disabled) {
  background-color: var(--brand-tertiary-50);
  border-color: var(--brand-tertiary-900);
}

.sl-btn.btn.btn-secondary:disabled, .sl-btn.btn.btn-secondary-small:disabled {
  border-color: var(--grey-700);
  background-color: var(--white);
}

.sl-btn.btn.btn-secondary:focus, .sl-btn.btn.btn-secondary:focus-visible, .sl-btn.btn.btn-secondary-small:focus, .sl-btn.btn.btn-secondary-small:focus-visible {
  box-shadow: 0 0 0 .125rem var(--brand-tertiary-500);
  border-color: var(--brand-tertiary-900);
}

.sl-btn.btn.btn-tertiary {
  color: var(--brand-tertiary-900);
  background: none;
}

.sl-btn.btn.btn-tertiary:hover:not(:disabled) {
  background-color: var(--brand-tertiary-50);
}

.sl-btn.btn.btn-tertiary:disabled {
  background-color: var(--white);
}

.sl-btn.btn.btn-danger {
  background-color: var(--red-800);
  color: var(--white);
}

.sl-btn.btn.btn-danger:hover {
  background-color: var(--red-900);
  border-color: var(--red-900);
}

.sl-btn.btn.btn-secondary-small {
  height: 2.5rem;
  color: var(--brand-tertiary-900);
  padding: .5rem 1rem;
}

.sl-btn.btn.btn-secondary-small:hover:not(:disabled), .sl-btn.btn.btn-secondary-small:focus, .sl-btn.btn.btn-secondary-small:focus-visible {
  border-color: var(--brand-tertiary-900);
}

.sl-btn.btn.btn-fab {
  color: var(--brand-tertiary-900);
  background: var(--white);
  border-radius: 1.25rem;
  min-width: 1.5rem;
  height: 2.5rem;
  padding: .5rem;
  line-height: 1.5rem;
  box-shadow: 0 1px 3px 2px #0000001a;
}

.sl-btn.btn.btn-fab.sl-btn-has-children {
  padding-left: 1rem;
  padding-right: 1rem;
}

.sl-btn.btn.btn-fab.sl-btn-has-children.sl-btn-has-icon-before {
  padding-left: .75rem;
}

.sl-btn.btn.btn-fab.sl-btn-has-children.sl-btn-has-icon-after {
  padding-right: .75rem;
}

.sl-btn.btn.btn-fab > .icon-before, .sl-btn.btn.btn-fab > .icon-after {
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  display: flex;
}

.sl-btn.btn.btn-fab > .icon-before {
  margin-right: .5rem;
}

.sl-btn.btn.btn-fab > .icon-after {
  margin-left: .5rem;
}

.sl-btn.btn.btn-fab:hover {
  background-color: var(--brand-tertiary-50);
}

.sl-btn.btn:focus, .sl-btn.btn:focus-visible {
  box-shadow: 0 0 0 .125rem var(--brand-tertiary-500);
}

.sl-btn.btn:disabled {
  background-color: var(--grey-200);
  color: var(--grey-700);
}

@media (width <= 904.98px) {
  .sl-btn.btn {
    width: 100%;
  }
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius)  - (var(--bs-border-width)));
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), .03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 12px;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  color: var(--bs-card-subtitle-color);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (width >= 600px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.sl-card {
  --bs-card-spacer-y: 1.5rem;
  --bs-card-spacer-x: 1.5rem;
  --bs-card-title-spacer-y: 1rem;
  --bs-card-border-width: .063rem;
  --bs-card-border-color: var(--grey-400);
  --bs-card-border-radius: 0;
}

.sl-card .card-body .card-badge {
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.sl-card .card-body .card-badge .category {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

.sl-card .card-body .card-badge .sl-badge {
  margin-left: auto;
}

.sl-card .card-body .card-title {
  --bs-card-title-color: var(--grey-900);
}

.sl-card .card-body .card-text {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-card .card-body .card-text-space {
  margin-bottom: 1rem;
}

.sl-card .card-body .caption {
  color: var(--grey-600);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

.sl-card .card-body .caption-space {
  margin-bottom: 1rem;
}

.sl-card .card-img-top {
  object-fit: cover;
  border-radius: 0;
  height: 15rem;
}

.sl-card .card-img-left {
  object-fit: cover;
  height: 100%;
}

.sl-card .badge-container {
  padding: 1.5rem 1.5rem 0;
}

.sl-card .card-link span {
  padding: .25rem;
}

.sl-card .card-link span svg {
  font-size: 18px;
}

.sl-card.clickable {
  cursor: pointer;
}

.sl-card.clickable:hover {
  box-shadow: 0 4px 8px 3px #00000026, 0 1px 3px #0000001a;
}

.sl-card.clickable:focus {
  outline: .125rem solid var(--brand-tertiary-500);
}

.sl-card.cut-out .cut-out-container {
  border: .125rem solid var(--white);
  background: var(--brand-secondary-800);
  text-align: center;
  border-radius: 50%;
  align-items: center;
  width: 7.5rem;
  height: 7.5rem;
  margin: auto;
  padding: 1.875rem;
  display: flex;
  position: relative;
  bottom: 3.75rem;
  box-shadow: 0 4px 8px 3px #00000014;
}

.sl-card.cut-out .cut-out-container svg {
  color: var(--white);
  margin: 0 auto;
  font-size: 2.75em;
}

.sl-card.cut-out .card-body {
  margin-top: -3.75rem;
}

.sl-card.cut-out .card-body a {
  font-size: 1.125rem;
}

.sl-card.show-icon .horizontal .icon-col {
  height: 3.75rem;
}

.sl-card.show-icon .horizontal .icon-container {
  border: .125rem solid var(--white);
  background: var(--brand-secondary-800);
  text-align: center;
  border-radius: 50%;
  align-items: center;
  width: 7.5rem;
  height: 7.5rem;
  margin: auto;
  padding: 1.875rem;
  display: flex;
  position: relative;
  bottom: 3.75rem;
  box-shadow: 0 4px 8px 3px #00000014;
}

.sl-card.show-icon .horizontal .icon-container svg {
  color: var(--white);
  margin: 0 auto;
  font-size: 2.75em;
}

@media (width >= 600px) {
  .sl-card.show-icon .horizontal .icon-container {
    background: var(--brand-secondary-800);
    text-align: center;
    width: 5rem;
    height: 5rem;
    box-shadow: none;
    border: none;
    border-radius: 50%;
    align-items: center;
    margin: 1.5rem 0 1.5rem 1.5rem;
    padding: 0;
    display: flex;
    position: relative;
    bottom: 0;
  }

  .sl-card.show-icon .horizontal .icon-container svg {
    color: var(--white);
    margin: 0 auto;
    font-size: 1.375em;
  }
}

.sl-card.show-icon .horizontal .card-body {
  margin-top: 0;
}

.sl-card.show-icon .horizontal .card-body a {
  font-size: 1.125rem;
}

.sl-card .card-title {
  margin-bottom: 1rem;
}

.sl-card .horizontal .card-img {
  object-fit: cover;
  border-radius: 0;
  height: 100%;
}

@media (width <= 599.98px) {
  .sl-card .horizontal .card-img {
    object-fit: cover;
    border-radius: 0;
    height: 15rem;
  }
}

@media (width >= 600px) {
  .sl-card .col:nth-child(2):last-child .card-body {
    padding-left: 0;
  }
}

.card-grid {
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  display: grid;
}

.sl-banner {
  --bs-card-spacer-y: 1.5rem;
  --bs-card-spacer-x: 1.5rem;
  --bs-card-title-spacer-y: 1rem;
  --bs-card-border-width: .063rem;
  --bs-card-border-color: var(--grey-400);
  --bs-card-border-radius: 0;
  border: none;
  padding: 0;
}

.sl-banner.lg img.card-img {
  object-fit: cover;
  width: 100%;
  height: 27.1875rem;
}

@media (width >= 600px) {
  .sl-banner.lg img.card-img {
    height: 32.5rem;
  }
}

.sl-banner.sm img.card-img {
  object-fit: cover;
  width: 100%;
  height: 20rem;
}

@media (width >= 600px) {
  .sl-banner.sm img.card-img {
    height: 25rem;
  }
}

.sl-banner.default .card-img-overlay {
  position: unset;
  padding: 0;
}

@media (width >= 905px) and (width <= 1239.98px) {
  .sl-banner.default .card-img-overlay {
    padding: 0 3.75rem;
  }
}

@media (width >= 905px) {
  .sl-banner.default .card-img-overlay {
    position: absolute;
  }
}

.sl-banner.default .card-img-overlay .bright {
  position: unset;
  opacity: .92;
  background-color: var(--brand-primary-50);
  height: 100%;
  color: var(--brand-tertiary-900);
  border-radius: 0;
  flex-direction: column;
  place-content: center;
  padding: 1.5rem 1rem;
  display: flex;
}

.sl-banner.default .card-img-overlay .bright .card-title {
  color: var(--brand-secondary-800);
  font-family: Sun Life New Display Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 2.75rem;
}

.sl-banner.default .card-img-overlay .bright p {
  color: var(--brand-secondary-900);
}

.sl-banner.default .card-img-overlay .bright a {
  font-size: 1.125rem;
}

.sl-banner.default .card-img-overlay .bright button {
  width: 100%;
  margin-right: auto;
}

@media (width >= 600px) {
  .sl-banner.default .card-img-overlay .bright {
    padding: 1.5rem 2rem;
  }

  .sl-banner.default .card-img-overlay .bright button {
    width: auto;
  }
}

@media (width >= 905px) {
  .sl-banner.default .card-img-overlay .bright {
    border-radius: 0;
    padding: 1.5rem;
  }
}

.sl-banner.default .card-img-overlay .dark {
  position: unset;
  opacity: .92;
  background-color: var(--brand-secondary-900);
  height: 100%;
  color: var(--white);
  border-radius: 0;
  flex-direction: column;
  place-content: center;
  padding: 1.5rem 1rem;
  display: flex;
}

.sl-banner.default .card-img-overlay .dark .card-title {
  color: var(--white);
  font-family: Sun Life New Display Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 2.75rem;
}

.sl-banner.default .card-img-overlay .dark .card-text {
  color: var(--white);
}

.sl-banner.default .card-img-overlay .dark a {
  font-size: 1.125rem;
}

.sl-banner.default .card-img-overlay .dark button {
  width: 100%;
  margin-right: auto;
}

@media (width >= 600px) {
  .sl-banner.default .card-img-overlay .dark {
    padding: 1.5rem 2rem;
  }

  .sl-banner.default .card-img-overlay .dark button {
    width: auto;
  }
}

@media (width >= 905px) {
  .sl-banner.default .card-img-overlay .dark {
    border-radius: 0;
    padding: 1.5rem;
  }
}

.sl-banner.default .card-img-overlay .dark a {
  color: var(--brand-primary-400);
}

.sl-banner.default .card-img-overlay .neutral {
  position: unset;
  opacity: .92;
  background-color: var(--white);
  height: 100%;
  color: var(--grey-900);
  border-radius: 0;
  flex-direction: column;
  place-content: center;
  padding: 1.5rem 1rem;
  display: flex;
}

.sl-banner.default .card-img-overlay .neutral .card-title {
  color: var(--grey-800);
  font-family: Sun Life New Display Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 2.75rem;
}

.sl-banner.default .card-img-overlay .neutral a {
  font-size: 1.125rem;
}

.sl-banner.default .card-img-overlay .neutral button {
  width: 100%;
  margin-right: auto;
}

@media (width >= 600px) {
  .sl-banner.default .card-img-overlay .neutral {
    padding: 1.5rem 2rem;
  }

  .sl-banner.default .card-img-overlay .neutral button {
    width: auto;
  }
}

@media (width >= 905px) {
  .sl-banner.default .card-img-overlay .neutral {
    border-radius: 0;
    padding: 1.5rem;
  }
}

.sl-banner.default .card-title, .sl-banner.default .card-text {
  margin: 0 0 1rem;
}

.sl-banner.default .card-text {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-banner.default .card-img, .sl-banner.default img {
  object-position: top;
  border-radius: 0;
}

.sl-banner.login {
  background: linear-gradient(to bottom, var(--grey-50), var(--white));
}

.sl-banner.login .login-container {
  z-index: 99;
}

.sl-banner.login .card-img-overlay {
  position: unset;
}

@media (width >= 905px) {
  .sl-banner.login .card-img-overlay {
    position: absolute;
  }
}

.sl-banner.login .login-form {
  position: unset;
  opacity: .92;
  background-color: var(--grey-50);
  opacity: .95;
  border-radius: 0;
  flex-direction: column;
  place-content: center;
  height: 100%;
  padding: 1.5rem 1rem .5rem;
  display: flex;
}

.sl-banner.login .login-form button {
  width: 100%;
  margin-right: auto;
}

@media (width >= 600px) {
  .sl-banner.login .login-form {
    padding: 1.5rem 2rem;
  }

  .sl-banner.login .login-form button {
    width: auto;
  }
}

@media (width >= 905px) {
  .sl-banner.login .login-form {
    border-radius: 0;
    padding: 1.5rem;
  }
}

@media (width <= 904.98px) {
  .sl-banner.login .login-form {
    border-radius: 0;
  }
}

.sl-banner.login .login-form .sl-btn {
  width: 100%;
}

.sl-banner.login .login-form .error-icon-container {
  margin: 0 .5rem 0 0;
}

.sl-banner.login .content-overlay {
  background-color: var(--white);
  height: max-content;
  position: unset;
  border-radius: 0;
  padding: 1.5rem 1rem;
}

@media (width >= 905px) {
  .sl-banner.login .content-overlay {
    padding: 1.5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.sl-banner.login .content-overlay .card-title {
  color: var(--grey-900);
  font-family: Sun Life New Display Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 2.75rem;
}

.sl-banner.login .content-overlay .card-text {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-banner.login .card-img, .sl-banner.login img {
  border-radius: 0;
}

@media (width >= 905px) {
  .sl-banner {
    min-width: 56.5625rem;
  }
}

.sl-date-picker {
  width: 100%;
  min-width: 8.5rem;
}

.sl-date-picker .sl-date-picker-input.form-floating input {
  background-color: var(--white);
}

.sl-date-picker .sl-date-picker-input.form-floating input:disabled, .sl-date-picker .sl-date-picker-input.form-floating input[disabled] {
  background-color: var(--grey-200);
  border-color: var(--grey-700);
}

.sl-date-picker .sl-date-picker-input.form-floating .form-control {
  height: 3rem;
  color: var(--grey-900);
  border-color: var(--grey-700);
  min-height: initial;
  padding: .75rem 2.75rem .75rem 1rem;
  font-size: 1rem;
  line-height: 1rem;
}

.sl-date-picker .sl-date-picker-input.form-floating .form-control:focus {
  box-shadow: 0 0 0 .125rem var(--brand-tertiary-500);
  border-color: var(--brand-tertiary-900);
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.sl-date-picker .sl-date-picker-input.form-floating .form-control:focus ~ label {
  color: var(--brand-tertiary-800);
  opacity: 1;
  padding-top: .5rem;
  font-size: .75rem;
  line-height: .875rem;
  transform: scale(.85)translateY(0)translate(.15rem);
}

.sl-date-picker .sl-date-picker-input.form-floating .form-control:focus ~ label:after {
  background: none;
}

.sl-date-picker .sl-date-picker-input.form-floating .form-control:active {
  box-shadow: none;
}

.sl-date-picker .sl-date-picker-input.form-floating .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
  line-height: 1rem;
}

.sl-date-picker .sl-date-picker-input.form-floating .form-control:not(:placeholder-shown) ~ label {
  opacity: 1;
  padding-top: .5rem;
  font-size: .75rem;
  line-height: .75rem;
  transform: scale(.85)translateY(0)translate(.15rem);
}

.sl-date-picker .sl-date-picker-input.form-floating .form-control:not(:placeholder-shown) ~ label:after {
  background: none;
}

.sl-date-picker .sl-date-picker-input.form-floating .form-control:focus::placeholder {
  color: var(--grey-700);
}

.sl-date-picker .sl-date-picker-input.form-floating label {
  color: var(--grey-700);
  width: 100%;
  padding: .75rem 2.75rem .75rem 1rem;
  font-size: 1rem;
}

.sl-date-picker .sl-date-picker-input.form-floating.sl-input-error .form-control {
  background: var(--red-50);
  border-color: var(--red-800) !important;
}

.sl-date-picker .sl-date-picker-input.form-floating.sl-input-error label {
  color: var(--red-800) !important;
}

.sl-date-picker .sl-date-picker-input.form-floating .no-label.form-control {
  padding: .5rem;
}

.sl-date-picker .sl-date-picker-input.form-floating button.icon-container {
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  width: 2.75rem;
  height: 100%;
  padding: 0;
  font-size: 1.25rem;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.sl-date-picker .sl-date-picker-input.form-floating button.icon-container:focus-visible {
  border: solid .125rem var(--brand-tertiary-500);
  border-radius: 0;
  outline: none;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog {
  z-index: 1000;
  width: 368px;
  color: var(--grey-900);
  background-color: #fff;
  border-radius: 0;
  margin-top: .125rem;
  padding: 0 1rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  position: absolute;
  box-shadow: 0 4px 8px 3px #00000026, 0 1px 3px #0000001a;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header {
  cursor: default;
  align-items: center;
  height: 3rem;
  display: flex;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .view-toggle {
  color: var(--grey-900);
  text-transform: none;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  display: flex;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .view-toggle .sl-icon {
  margin-left: .25rem;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .outer-icon-button, .sl-date-picker .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .inner-icon-button {
  color: var(--grey-900);
  border-radius: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .outer-icon-button:disabled .sl-icon, .sl-date-picker .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .outer-icon-button[disabled] .sl-icon, .sl-date-picker .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .inner-icon-button:disabled .sl-icon, .sl-date-picker .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .inner-icon-button[disabled] .sl-icon {
  background: var(--grey-500);
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .outer-icon-button .icon-container, .sl-date-picker .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .inner-icon-button .icon-container {
  display: flex;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .inner-icon-button {
  margin: 0 .5rem;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header button:focus-visible {
  outline: solid .125rem var(--brand-tertiary-500);
  border-radius: 0;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog button {
  background: none;
  border: 0;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog button::-moz-focus-inner {
  border: 0;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog .table-wrap {
  justify-content: center;
  display: flex;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates {
  width: 100%;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates tr {
  justify-content: space-between;
  display: flex;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates th, .sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates td {
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  display: inline-flex;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates th {
  width: 3rem;
  height: 1.5rem;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates td {
  line-height: inherit;
  width: 3rem;
  height: 3rem;
  color: var(--grey-900);
  border: .125rem solid #0000;
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates .next-month-cell, .sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates .prev-month-cell, .sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates td[aria-disabled="true"] {
  color: var(--grey-500);
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates td[aria-disabled="true"]:hover {
  cursor: default;
  background-color: #0000;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates td[aria-disabled="true"]:focus-visible, .sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates td[aria-disabled="true"]:focus {
  border-color: #0000;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates td:hover {
  background-color: var(--grey-100);
  cursor: pointer;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates td:focus-visible, .sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates td:focus {
  border-color: var(--brand-tertiary-500);
  outline: none;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates td[aria-selected] {
  background: var(--brand-tertiary-800);
  color: #fff;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates td.current-day, .sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates td.current-month, .sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates td.current-year {
  color: var(--brand-tertiary-800);
  background: none;
  font-weight: 700;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates td.current-day[aria-selected], .sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates td.current-month[aria-selected], .sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates td.current-year[aria-selected] {
  background: var(--brand-tertiary-800);
  color: #fff;
  font-weight: 400;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates td[aria-selected]:focus, .sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.dates td[aria-selected]:focus-visible {
  border-color: var(--brand-tertiary-500);
  color: #fff;
  border-radius: 0;
  outline: none;
  font-weight: 700;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.month-view td.month-cell {
  margin: 1.25rem 0;
}

.sl-date-picker .sl-date-picker-dropdown .date-picker-dialog table.year-view td.year-cell {
  margin: .5625rem 0;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog {
  z-index: 1000;
  width: 368px;
  color: var(--grey-900);
  background-color: #fff;
  border-radius: 0;
  margin-top: .125rem;
  padding: 0 1rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  position: absolute;
  box-shadow: 0 4px 8px 3px #00000026, 0 1px 3px #0000001a;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header {
  cursor: default;
  align-items: center;
  height: 3rem;
  display: flex;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .view-toggle {
  color: var(--grey-900);
  text-transform: none;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  display: flex;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .view-toggle .sl-icon {
  margin-left: .25rem;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .outer-icon-button, .sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .inner-icon-button {
  color: var(--grey-900);
  border-radius: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .outer-icon-button:disabled .sl-icon, .sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .outer-icon-button[disabled] .sl-icon, .sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .inner-icon-button:disabled .sl-icon, .sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .inner-icon-button[disabled] .sl-icon {
  background: var(--grey-500);
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .outer-icon-button .icon-container, .sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .inner-icon-button .icon-container {
  display: flex;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header .inner-icon-button {
  margin: 0 .5rem;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog .date-picker-dropdown-header button:focus-visible {
  outline: solid .125rem var(--brand-tertiary-500);
  border-radius: 0;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog button {
  background: none;
  border: 0;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog button::-moz-focus-inner {
  border: 0;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog .table-wrap {
  justify-content: center;
  display: flex;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates {
  width: 100%;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates tr {
  justify-content: space-between;
  display: flex;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates th, .sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates td {
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  display: inline-flex;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates th {
  width: 3rem;
  height: 1.5rem;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates td {
  line-height: inherit;
  width: 3rem;
  height: 3rem;
  color: var(--grey-900);
  border: .125rem solid #0000;
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates .next-month-cell, .sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates .prev-month-cell, .sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates td[aria-disabled="true"] {
  color: var(--grey-500);
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates td[aria-disabled="true"]:hover {
  cursor: default;
  background-color: #0000;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates td[aria-disabled="true"]:focus-visible, .sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates td[aria-disabled="true"]:focus {
  border-color: #0000;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates td:hover {
  background-color: var(--grey-100);
  cursor: pointer;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates td:focus-visible, .sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates td:focus {
  border-color: var(--brand-tertiary-500);
  outline: none;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates td[aria-selected] {
  background: var(--brand-tertiary-800);
  color: #fff;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates td.current-day, .sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates td.current-month, .sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates td.current-year {
  color: var(--brand-tertiary-800);
  background: none;
  font-weight: 700;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates td.current-day[aria-selected], .sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates td.current-month[aria-selected], .sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates td.current-year[aria-selected] {
  background: var(--brand-tertiary-800);
  color: #fff;
  font-weight: 400;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates td[aria-selected]:focus, .sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates td[aria-selected]:focus-visible {
  border-color: var(--brand-tertiary-500);
  color: #fff;
  border-radius: 0;
  outline: none;
  font-weight: 700;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.month-view td.month-cell {
  margin: 1.25rem 0;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.year-view td.year-cell {
  margin: .5625rem 0;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog {
  box-shadow: none;
  width: 100%;
  margin-top: 0;
  padding: 1.5rem 0 1rem;
  position: relative;
}

.sl-date-picker-modal.sl-modal .no-selection {
  color: var(--grey-700);
}

.sl-date-picker-modal.sl-modal .sl-date-picker-modal__actions {
  flex-wrap: wrap;
  align-items: center;
  column-gap: 1.5rem;
  margin-top: .5rem;
  display: flex;
}

.sl-date-picker-modal.sl-modal .sl-date-picker-modal__cancel-btn, .sl-date-picker-modal.sl-modal .sl-date-picker-modal__apply-btn {
  width: auto;
}

@media (width >= 600px) {
  .sl-date-picker-modal.sl-modal .modal-dialog {
    max-width: 33.5rem;
  }
}

@media (width <= 599.98px) {
  .sl-date-picker-modal.sl-modal {
    padding-left: 0;
    padding-right: 0;
  }

  .sl-date-picker-modal.sl-modal .modal-dialog {
    max-width: unset;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog {
    padding: 1.5rem 0 1rem;
  }

  .sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates td, .sl-date-picker-modal.sl-modal .sl-date-picker-dropdown .date-picker-dialog table.dates th {
    width: 100%;
    max-width: 3rem;
  }

  .sl-date-picker-modal.sl-modal .modal-dialog .modal-content {
    padding: 2rem 1rem;
  }
}

.sl-divider {
  color: var(--grey-300);
  opacity: 1;
}

.sl-divider.hr {
  border-top: .063rem solid;
  width: 100%;
  margin: .5rem 0;
}

.sl-divider.hr.th-2 {
  border-top: .125rem solid;
}

.sl-divider.vr {
  height: 100%;
}

.sl-divider.vr.th-2 {
  width: .125rem;
}

.sl-drawer {
  display: flex;
}

.sl-drawer .sl-drawer__offcanvas-header {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3.5rem;
  padding: 1.5rem 1.5rem 2.5rem;
}

@media (width <= 599.98px) {
  .sl-drawer .sl-drawer__offcanvas-header {
    padding: 1.5rem 1rem 2rem;
  }
}

.sl-drawer .sl-drawer__offcanvas-header .sl-drawer__heading {
  color: var(--grey-900);
  margin-bottom: 0;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.5rem;
}

.sl-drawer .sl-drawer__offcanvas-header .sl-drawer__subheading {
  color: var(--grey-900);
  margin-top: 1.5rem;
  margin-bottom: 0;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 2.25rem;
}

.sl-drawer .offcanvas-body {
  margin-top: 1.5rem;
  padding: 0 1.5rem 1.5rem;
}

@media (width >= 600px) and (width <= 904.98px) {
  .sl-drawer .offcanvas-body {
    padding: 0 2rem 1.5rem;
  }
}

@media (width <= 599.98px) {
  .sl-drawer .offcanvas-body {
    padding: 0 1rem 1.5rem;
  }
}

.sl-drawer .sl-drawer__footer {
  margin-top: auto;
  padding: 1.5rem;
}

@media (width >= 600px) and (width <= 904.98px) {
  .sl-drawer .sl-drawer__footer {
    padding: 1.5rem 2rem;
  }
}

@media (width <= 599.98px) {
  .sl-drawer .sl-drawer__footer {
    padding: 1.5rem 1rem;
  }
}

.sl-drawer .drawer-dismiss-button {
  z-index: 1;
  background: none;
  border: none;
  width: 2rem;
  height: 2rem;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.sl-drawer .drawer-dismiss-button:focus-visible {
  box-shadow: 0 0 0 .125rem var(--brand-tertiary-500);
  outline: 0;
}

@media (width >= 600px) and (width <= 904.98px) {
  .sl-drawer .drawer-dismiss-button {
    right: 2rem;
  }
}

@media (width <= 599.98px) {
  .sl-drawer .drawer-dismiss-button {
    right: 1rem;
  }
}

.sl-drawer .sl-divider {
  margin: 0;
}

@media (width >= 1440px) {
  .sl-drawer.large {
    transition: transform .2s ease-out, transform .2s ease-out;
  }

  .sl-drawer.large.offcanvas {
    width: calc(50vw + 478px);
  }
}

@media (width >= 1240px) and (width <= 1439.98px) {
  .sl-drawer.large {
    transition: transform .2s ease-out, transform .2s ease-out;
  }

  .sl-drawer.large.offcanvas {
    width: calc(50vw + 478px);
  }
}

@media (width >= 905px) and (width <= 1239.98px) {
  .sl-drawer.large {
    transition: transform .2s ease-out, transform .2s ease-out;
  }

  .sl-drawer.large.offcanvas {
    width: calc(91.6667vw - 3.125rem - 2px);
  }
}

@media (width >= 600px) and (width <= 904.98px) {
  .sl-drawer.large {
    transition: transform .2s ease-out, transform .2s ease-out;
  }

  .sl-drawer.large.offcanvas {
    width: 100%;
  }
}

@media (width <= 599.98px) {
  .sl-drawer.large {
    transition: transform .15s ease-out, transform .15s ease-out;
  }

  .sl-drawer.large.offcanvas {
    width: 100%;
  }
}

.sl-drawer.large .sl-drawer__actions {
  gap: 1.5rem;
  display: flex;
}

@media (width <= 904.98px) {
  .sl-drawer.large .sl-drawer__actions {
    flex-direction: column;
    align-items: stretch;
  }
}

@media (width >= 600px) {
  .sl-drawer.large.drawer-open {
    transition: transform .3s ease-out, transform .3s ease-out;
  }
}

@media (width <= 599.98px) {
  .sl-drawer.large.drawer-open {
    transition: transform .2s ease-out, transform .2s ease-out;
  }
}

@media (width >= 600px) and (width <= 904.98px) {
  .sl-drawer.large.drawer-open {
    transition: transform .25s ease-out, transform .25s ease-out;
  }
}

@media (width >= 1440px) {
  .sl-drawer.medium {
    transition: transform .2s ease-out, transform .2s ease-out;
  }

  .sl-drawer.medium.offcanvas {
    width: calc(50vw - 12px);
  }
}

@media (width >= 1240px) and (width <= 1439.98px) {
  .sl-drawer.medium {
    transition: transform .2s ease-out, transform .2s ease-out;
  }

  .sl-drawer.medium.offcanvas {
    width: calc(50vw - 12px);
  }
}

@media (width >= 905px) and (width <= 1239.98px) {
  .sl-drawer.medium {
    transition: transform .2s ease-out, transform .2s ease-out;
  }

  .sl-drawer.medium.offcanvas {
    width: calc(50vw - 12px);
  }
}

@media (width <= 904.98px) {
  .sl-drawer.medium {
    transition: transform .15s ease-out, transform .15s ease-out;
  }

  .sl-drawer.medium.offcanvas {
    width: 100%;
  }
}

.sl-drawer.medium .sl-drawer__actions {
  gap: 1.5rem;
  display: flex;
}

@media (width <= 904.98px) {
  .sl-drawer.medium .sl-drawer__actions {
    flex-direction: column;
    align-items: stretch;
  }
}

@media (width >= 600px) {
  .sl-drawer.medium.drawer-open {
    transition: transform .25s ease-out, transform .25s ease-out;
  }
}

@media (width >= 600px) and (width <= 904.98px) {
  .sl-drawer.medium.drawer-open {
    transition: transform .25s ease-out, transform .25s ease-out;
  }
}

@media (width <= 599.98px) {
  .sl-drawer.medium.drawer-open {
    transition: transform .2s ease-out, transform .2s ease-out;
  }
}

@media (width >= 1440px) {
  .sl-drawer.small {
    transition: transform .15s ease-out, transform .15s ease-out;
  }

  .sl-drawer.small.offcanvas {
    width: calc(50vw - 208px);
  }
}

@media (width >= 1240px) and (width <= 1439.98px) {
  .sl-drawer.small {
    transition: transform .15s ease-out, transform .15s ease-out;
  }

  .sl-drawer.small.offcanvas {
    width: calc(50vw - 208px);
  }
}

@media (width >= 905px) and (width <= 1239.98px) {
  .sl-drawer.small {
    transition: transform .15s ease-out, transform .15s ease-out;
  }

  .sl-drawer.small.offcanvas {
    width: calc(33.3333vw + 1.25rem - 16px);
  }
}

@media (width <= 904.98px) {
  .sl-drawer.small.offcanvas {
    width: 100%;
  }

  .sl-drawer.small {
    transition: transform .15s ease-out, transform .15s ease-out;
  }
}

.sl-drawer.small .sl-drawer__actions {
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  display: flex;
}

@media (width <= 1439.98px) {
  .sl-drawer.small.drawer-open {
    transition: transform .2s ease-out, transform .2s ease-out;
  }
}

.sl-drawer_fullscreen .offcanvas-body {
  padding: 0;
}

.offcanvas-backdrop.sl-drawer-backdrop {
  background-color: #202223bf;
}

.sl-dropdown .combo {
  width: 100%;
  display: block;
  position: relative;
}

.sl-dropdown .combo-input {
  border: .063rem solid var(--grey-700);
  text-align: left;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3rem;
  font-size: 1rem;
  display: flex;
  position: relative;
}

.sl-dropdown .combo-input:focus, .sl-dropdown .combo-input:focus-visible {
  outline: solid .125rem var(--brand-tertiary-500);
}

.sl-dropdown .combo-input .sl-icon {
  margin: 0 1rem 0 .5rem;
}

.sl-dropdown .combo-input .combo-input-selected {
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 100%;
  color: var(--grey-900);
  flex: 1;
  padding: .75rem 0 .75rem 1rem;
  line-height: 1.5rem;
  display: block;
  overflow: hidden;
}

.sl-dropdown .combo-input .combo-input-selected.placeholder {
  color: var(--grey-700);
}

.sl-dropdown .combo-input label {
  color: var(--grey-700);
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: .063rem solid #0000;
  width: calc(100% - 2rem);
  height: 100%;
  padding: .75rem 1rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.sl-dropdown .combo-input label.raised {
  color: var(--grey-700);
  opacity: 1;
  padding-top: .5rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .75rem;
  font-weight: 400;
  line-height: .75rem;
  transform: scale(.85)translateY(0)translate(.15rem);
}

.sl-dropdown .combo-input label.raised ~ .combo-input-selected {
  color: var(--grey-900);
  padding-top: 1.313rem;
  padding-bottom: .5rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
}

.sl-dropdown .combo-input label.active {
  color: var(--brand-tertiary-800);
}

.sl-dropdown .combo-input.sl-input-error {
  border-color: var(--red-800);
  background: var(--red-50);
}

.sl-dropdown .combo-input.sl-input-error label {
  color: var(--red-800);
}

.sl-dropdown .disabled .combo-input {
  background-color: var(--grey-200);
  pointer-events: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sl-dropdown .combo-menu {
  z-index: 1000;
  background-color: #fff;
  border-radius: 0;
  width: 100%;
  max-height: 30rem;
  display: none;
  position: absolute;
  top: 105%;
  left: 0;
  overflow-y: auto;
  box-shadow: 0 4px 8px 3px #00000026, 0 1px 3px #0000001a;
}

.sl-dropdown .open .combo-menu {
  display: block;
}

.sl-dropdown .open .sl-icon-chevron-down {
  transform: rotate(180deg);
}

.sl-dropdown .combo-option {
  color: var(--grey-900);
  border: .125rem solid #0000;
  align-items: center;
  min-height: 3rem;
  padding: .75rem 1rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  display: flex;
  position: relative;
}

.sl-dropdown .combo-option:hover {
  background-color: var(--grey-100);
}

.sl-dropdown .combo-option.option-current {
  border-color: var(--brand-tertiary-500);
}

.sl-dropdown .combo-option.option-selected {
  color: var(--grey-900);
  font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-dropdown .combo-option.option-selected:before {
  content: "";
  background: var(--brand-tertiary-800);
  width: .125rem;
  height: calc(100% + .25rem);
  position: absolute;
  top: -.125rem;
  left: -.125rem;
}

.sl-dropdown .combo-option.option-current.option-selected {
  border-color: var(--brand-tertiary-500);
}

.sl-dropdown .combo-msg {
  margin-top: .5rem;
}

@media (width <= 599.98px) {
  .sl-dropdown {
    width: 100%;
  }
}

.sl-footer {
  width: 100%;
}

.sl-footer .container {
  padding-top: 4rem;
  padding-bottom: 2.5rem;
}

.sl-footer .sl-divider {
  margin: 0;
}

.sl-footer ul {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.sl-footer .footer-menu-items {
  margin: 0;
  line-height: 1.313rem;
}

.sl-footer .footer-menus-container {
  width: 100%;
}

.sl-footer .footer-menus-container .footer-menus {
  margin: 1.5rem 0;
}

.sl-footer .footer-menus-container .footer-menus .footer-menu {
  margin: 0;
  padding: 0;
}

.sl-footer .footer-menus-container .footer-menus .footer-menu .footer-menu-header {
  color: var(--grey-900);
  margin-bottom: 1rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-footer .footer-menus-container .footer-menus .footer-menu-items {
  padding: 0 0 .5rem;
}

.sl-footer .footer-menus-container .footer-menus .footer-menu-items:last-child {
  padding: 0;
}

.sl-footer .footer-menus-container .footer-menus .footer-menu-items a {
  cursor: pointer;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding: .25rem .5rem;
  position: relative;
  left: -.5rem;
}

.sl-footer .footer-menus-container .footer-menu.footer-menus-dropdown {
  border-bottom: .063rem solid;
  border-color: var(--grey-300);
}

.sl-footer .footer-menus-container .footer-menu.footer-menus-dropdown:last-child {
  border: none;
}

.sl-footer .footer-menus-container .footer-menu.footer-menus-dropdown .footer-menu-header {
  text-align: left;
  background-color: #0000;
  border: none;
  border-radius: 0;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 1.5rem 0;
  display: flex;
}

.sl-footer .footer-menus-container .footer-menu.footer-menus-dropdown .footer-menu-header .menu-content {
  flex: 1;
}

.sl-footer .footer-menus-container .footer-menu.footer-menus-dropdown .footer-menu-header .fm-icon-container {
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  padding: 0;
  display: flex;
}

.sl-footer .footer-menus-container .footer-menu.footer-menus-dropdown .footer-menu-header[aria-expanded="true"] .fm-icon-container {
  transform: rotate(180deg);
}

.sl-footer .footer-menus-container .footer-menu.footer-menus-dropdown .footer-menu-header:focus-visible {
  outline: solid .125rem var(--brand-tertiary-500);
}

.sl-footer .footer-menus-container .footer-menu.footer-menus-dropdown ul {
  margin-bottom: 1.5rem;
}

.sl-footer .footer-menus-container .footer-menu.footer-menus-dropdown .footer-menu-items {
  padding: .5rem 0;
}

.sl-footer .footer-menus-container .footer-menu.footer-menus-dropdown .footer-menu-items a {
  padding: 0 .5rem;
}

@media (width <= 1239.98px) {
  .sl-footer .footer-menus-container .footer-menus {
    margin: 0;
  }
}

.sl-footer .footer-content-container .footer-content {
  margin: 1.5rem 0;
}

.sl-footer .footer-content-container .footer-content .footer-tagline {
  color: var(--grey-900);
  margin: 0 0 1rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 1.75rem;
}

.sl-footer .footer-content-container .footer-content .footer-body, .sl-footer .footer-content-container .footer-content .footer-body p {
  color: var(--grey-900);
  margin: 0 0 .5rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

.sl-footer .footer-content-container .footer-content .footer-copy {
  color: var(--grey-700);
  margin: 0;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

.sl-footer .footer-utility-menu-container .footer-utility-menu {
  flex-flow: wrap;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.sl-footer .footer-utility-menu-container .footer-utility-menu .footer-utility-submenu .footer-menu-items {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  display: flex;
}

.sl-footer .footer-utility-menu-container .footer-utility-menu .footer-utility-submenu .footer-menu-items a:focus-visible {
  margin: -4px -8px;
  padding: 4px 8px;
}

.sl-footer .footer-utility-menu-container .footer-utility-menu .footer-utility-submenu .footer-menu-items .sl-divider.vr {
  margin: auto 1.5rem;
}

.sl-footer .footer-utility-menu-container .footer-utility-menu .footer-utility-submenu, .sl-footer .footer-utility-menu-container .footer-utility-menu .footer-icons-container {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: .5rem;
  display: flex;
}

.sl-footer .footer-utility-menu-container .footer-utility-menu .footer-menu-items, .sl-footer .footer-utility-menu-container .footer-utility-menu .footer-icons-container .sl-social-media {
  margin-top: 1rem;
}

@media (width <= 1239.98px) {
  .sl-footer .footer-utility-menu-container .footer-utility-menu {
    flex-direction: column;
    align-items: baseline;
  }

  .sl-footer .footer-utility-menu-container .footer-utility-menu .footer-utility-submenu, .sl-footer .footer-utility-menu-container .footer-utility-menu .footer-icons-container {
    justify-content: initial;
    align-items: center;
  }
}

.sl-footer .footer-lang-dropdown.footer-lang-dropdown {
  margin: 0 0 24px;
  padding: 0;
}

@media (width <= 1239.98px) {
  .sl-footer .footer-lang-dropdown.footer-lang-dropdown .sl-menu-dropdown {
    width: 100%;
  }
}

.sl-footer .footer-lang-dropdown.footer-lang-dropdown .dropdown-toggle.nav-link.utility-nav-item-container {
  color: var(--grey-900);
  padding: 0;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-footer .footer-lang-dropdown.footer-lang-dropdown .dropdown-toggle.nav-link.utility-nav-item-container .sl-dropdown-icon {
  width: 20px;
  height: 20px;
  padding: .063rem;
}

@media (width <= 1239.98px) {
  .sl-footer .footer-lang-dropdown.footer-lang-dropdown .dropdown-toggle.nav-link.utility-nav-item-container {
    padding: 16px 0;
  }
}

.sl-footer .footer-lang-dropdown.footer-lang-dropdown .dropdown-menu {
  top: calc(100% + 16px);
}

.sl-footer .footer-lang-dropdown.footer-lang-dropdown .dropdown-menu a.dropdown-item, .sl-footer .footer-lang-dropdown.footer-lang-dropdown .dropdown-menu button.dropdown-item {
  color: var(--grey-900);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

.sl-footer .footer-lang-dropdown.footer-lang-dropdown .dropdown-menu a.dropdown-item.active, .sl-footer .footer-lang-dropdown.footer-lang-dropdown .dropdown-menu a.dropdown-item:active, .sl-footer .footer-lang-dropdown.footer-lang-dropdown .dropdown-menu button.dropdown-item.active, .sl-footer .footer-lang-dropdown.footer-lang-dropdown .dropdown-menu button.dropdown-item:active {
  color: var(--grey-900);
  padding-left: 22px;
  padding-right: 22px;
  font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

@media (width <= 1239.98px) {
  .sl-footer .footer-lang-dropdown.footer-lang-dropdown .dropdown-menu {
    padding: 0;
    position: relative;
    top: 100%;
  }

  .sl-footer .footer-lang-dropdown.footer-lang-dropdown {
    margin: 0;
  }

  .sl-footer .container {
    padding-top: 5.5rem;
    padding-bottom: 2.5rem;
  }
}

@media (width <= 904.98px) {
  .sl-footer .container {
    padding-top: 5rem;
    padding-bottom: 2rem;
  }
}

.sl-text-field.form-floating input {
  background-color: var(--white);
}

.sl-text-field.form-floating input:disabled, .sl-text-field.form-floating input[disabled] {
  background-color: var(--grey-200);
  border-color: var(--grey-700);
}

.sl-text-field.form-floating .form-control {
  height: 3rem;
  color: var(--grey-900);
  border-color: var(--grey-700);
  min-height: initial;
  padding: .75rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
}

.sl-text-field.form-floating .form-control:focus {
  box-shadow: 0 0 0 .125rem var(--brand-tertiary-500);
  border-color: var(--brand-tertiary-900);
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.sl-text-field.form-floating .form-control:focus ~ label {
  color: var(--brand-tertiary-800);
  opacity: 1;
  padding-top: .5rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .75rem;
  font-weight: 400;
  line-height: .75rem;
  transform: scale(1)translateY(0);
}

.sl-text-field.form-floating .form-control:focus ~ label:after {
  background: none;
}

.sl-text-field.form-floating .form-control:active {
  box-shadow: none;
}

.sl-text-field.form-floating .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
  line-height: 16px;
}

.sl-text-field.form-floating .form-control:not(:placeholder-shown) ~ label {
  color: var(--brand-tertiary-800);
  opacity: 1;
  padding-top: .5rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .75rem;
  font-weight: 400;
  line-height: .75rem;
  transform: scale(1)translateY(0);
}

.sl-text-field.form-floating .form-control:not(:placeholder-shown) ~ label:after {
  background: none;
}

.sl-text-field.form-floating label {
  color: var(--grey-700);
  width: 100%;
  padding: .75rem 1rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-text-field.form-floating.sl-input-error .form-control {
  background: var(--red-50);
  border-color: var(--red-800) !important;
}

.sl-text-field.form-floating.sl-input-error label {
  color: var(--red-800) !important;
}

.sl-text-field.form-floating.icon-before label {
  padding-left: 3rem;
}

.sl-text-field.form-floating.icon-before div.icon-container {
  color: var(--grey-700);
  font-size: 1.25rem;
  display: inline-flex;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.sl-text-field.form-floating.icon-before .form-control {
  padding-left: 3rem;
}

.sl-text-field.form-floating.icon-after div.icon-container {
  color: var(--grey-700);
  font-size: 1.25rem;
  display: inline-flex;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.sl-text-field.form-floating.icon-after button.icon-container {
  background: none;
  border: none;
  width: 2.75rem;
  height: 100%;
  font-size: 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
}

.sl-text-field.form-floating.icon-after button.icon-container .icon-custom {
  width: 1rem;
  height: 1rem;
  color: var(--grey-700);
  vertical-align: middle;
  display: inline-block;
}

.sl-text-field.form-floating.icon-after button.icon-container:focus-visible {
  border: solid 2px var(--brand-tertiary-500);
  border-radius: 0;
  outline: none;
}

.sl-text-field.form-floating.icon-after .form-control, .sl-text-field.form-floating.icon-after label {
  padding-right: 3rem;
}

.sl-checkbox {
  margin-bottom: 0;
  padding-top: .75rem;
  padding-bottom: .75rem;
  padding-left: 2.25rem;
}

.sl-checkbox.form-check-inline {
  margin-right: 1.5rem;
}

.sl-checkbox .form-check-input {
  border: .0625rem solid var(--grey-700);
  border-radius: 0;
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0;
  margin-left: -2.25rem;
}

.sl-checkbox .form-check-input:checked {
  background-color: var(--brand-tertiary-800);
}

.was-validated .sl-checkbox .form-check-input:invalid, .sl-checkbox .form-check-input.is-invalid {
  border-color: var(--red-800);
  background-color: var(--red-50);
}

.was-validated .sl-checkbox .form-check-input:invalid:checked, .sl-checkbox .form-check-input.is-invalid:checked {
  background-color: var(--brand-tertiary-800);
  border-color: .0625rem solid var(--grey-700);
}

.was-validated .sl-checkbox .form-check-input:invalid:focus, .sl-checkbox .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .125rem var(--brand-tertiary-500);
}

.was-validated .sl-checkbox .form-check-input:invalid ~ .form-check-label, .sl-checkbox .form-check-input.is-invalid ~ .form-check-label {
  color: var(--grey-900);
}

.sl-checkbox .form-check-input:disabled, .sl-checkbox .form-check-input[disabled] {
  border: .0625rem solid var(--grey-700);
  opacity: 1;
  background-color: var(--grey-200);
}

.sl-checkbox .form-check-input:disabled ~ .form-check-label, .sl-checkbox .form-check-input[disabled] ~ .form-check-label {
  opacity: 1;
  color: var(--grey-700);
}

.sl-checkbox .form-check-input:disabled:checked, .sl-checkbox .form-check-input[disabled]:checked {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='%235B6062' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>");
}

.sl-checkbox .form-check-input:focus {
  box-shadow: 0 0 0 .125rem var(--brand-tertiary-500);
  border: .0625rem solid var(--grey-700);
  outline: none;
}

.sl-checkbox label {
  vertical-align: middle;
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-radio {
  margin-bottom: 0;
  padding-top: .75rem;
  padding-bottom: .75rem;
  padding-left: 2.25rem;
}

.sl-radio.form-check-inline {
  margin-right: 1.5rem;
}

.sl-radio .form-check-input {
  border: .0625rem solid var(--grey-700);
  appearance: none;
  background-color: #fff;
  place-items: center;
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0;
  margin-left: -2.25rem;
  display: grid;
}

.sl-radio .form-check-input ~ .form-check-label {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-radio .form-check-input:before {
  content: "";
  background-color: var(--brand-tertiary-800);
  border-radius: 50%;
  width: 1.125rem;
  height: 1.125rem;
  transform: scale(0);
}

.sl-radio .form-check-input:checked {
  border-color: var(--grey-700);
  background-image: none;
}

.sl-radio .form-check-input:checked:before {
  transform: scale(1);
}

.sl-radio .form-check-input:disabled {
  opacity: 1;
  background-color: var(--grey-200);
}

.sl-radio .form-check-input:disabled ~ .form-check-label {
  opacity: 1;
  color: var(--grey-700);
}

.sl-radio .form-check-input:focus {
  box-shadow: 0 0 0 .125rem var(--brand-tertiary-500);
  border: .0625rem solid var(--grey-700);
}

.was-validated .sl-radio .form-check-input:invalid, .sl-radio .form-check-input.is-invalid {
  border-color: var(--red-800);
  background-color: var(--red-50);
}

.was-validated .sl-radio .form-check-input:invalid ~ .form-check-label, .sl-radio .form-check-input.is-invalid ~ .form-check-label {
  color: var(--grey-900);
}

.sl-group-label legend {
  margin-bottom: 0;
  font-size: 1rem;
}

.sl-guided-experience .sl-guided-experience__body {
  flex-direction: row;
  display: flex;
}

.sl-guided-experience .sl-guided-experience__body .sl-guided-experience__content {
  width: 100%;
  height: fit-content;
  padding-bottom: 1.5rem;
}

@media (width >= 600px) and (width <= 904.98px) {
  .sl-guided-experience .sl-guided-experience__body .sl-guided-experience__content {
    width: 100%;
    height: fit-content;
  }
}

@media (width >= 905px) and (width <= 1239.98px) {
  .sl-guided-experience .sl-guided-experience__body .sl-guided-experience__content {
    width: calc(66.6667vw - 5rem - 8px);
  }
}

@media (width >= 1240px) and (width <= 1439.98px) {
  .sl-guided-experience .sl-guided-experience__body .sl-guided-experience__content {
    width: 760px;
  }
}

@media (width >= 1440px) {
  .sl-guided-experience .sl-guided-experience__body .sl-guided-experience__content {
    width: 760px;
  }
}

.sl-guided-experience .sl-guided-experience__body .sl-guided-experience__content .sl-guided-experience__heading {
  color: var(--grey-900);
  margin-bottom: 1.5rem;
  padding-top: 3.5rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 2.75rem;
}

@media (width <= 599.98px) {
  .sl-guided-experience .sl-guided-experience__body .sl-guided-experience__content .sl-guided-experience__heading {
    margin-bottom: 1rem;
    padding-top: 3rem;
  }
}

.sl-guided-experience .sl-guided-experience__body .sl-guided-experience__content .sl-guided-experience__title {
  z-index: 3;
  background-color: #fff;
  padding-top: 1rem;
  position: sticky;
}

.sl-guided-experience .sl-guided-experience__body .sl-guided-experience__content .sl-guided-experience__title .sl-guided-experience__progress-bar {
  background-color: #dcdedf;
  flex-direction: column;
  height: 2px;
  display: flex;
}

.sl-guided-experience .sl-guided-experience__body .sl-guided-experience__content .sl-guided-experience__title .sl-guided-experience__progress-bar__filler {
  background-color: #00588b;
  height: 100%;
}

.sl-guided-experience .sl-guided-experience__body .sl-guided-experience__content .sl-guided-experience__title .sl-guided-experience__step-title {
  color: var(--grey-900);
  background-color: #fff;
  margin: 0;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.5rem;
}

.sl-guided-experience .sl-guided-experience__body .sl-guided-experience__content .sl-guided-experience__title .sl-divider {
  margin: 1.5rem 0 0;
  padding: 0 0 1.5rem;
}

.sl-guided-experience .sl-guided-experience__body .sl-guided-experience__content .sl-guided-experience__title .sl-guided-experience__step-info-container {
  flex-direction: column;
  justify-content: space-between;
  gap: .5rem;
  margin: 1.5rem 0;
  padding: 0;
  display: flex;
}

.sl-guided-experience .sl-guided-experience__body .sl-guided-experience__content .sl-guided-experience__title .sl-guided-experience__step-info-container .sl-guided-experience__step-label {
  color: var(--grey-700);
  font-size: .875rem;
  line-height: 1.3125rem;
}

.sl-guided-experience .sl-guided-experience__body .sl-guided-experience__content .sl-guided-experience__title .sl-guided-experience__step-info-container .sl-divider-override {
  margin: 0;
  padding: 0;
}

@media (width >= 905px) and (width <= 1239.98px) {
  .sl-guided-experience .sl-guided-experience__body .sl-guided-experience__content.sl-guided-experience__content--small {
    width: calc(66.6667vw - 5rem - 8px);
  }
}

@media (width >= 1240px) and (width <= 1439.98px) {
  .sl-guided-experience .sl-guided-experience__body .sl-guided-experience__content.sl-guided-experience__content--small {
    width: 858px;
  }
}

@media (width >= 1440px) {
  .sl-guided-experience .sl-guided-experience__body .sl-guided-experience__content.sl-guided-experience__content--small {
    width: 858px;
  }
}

.sl-guided-experience .sl-guided-experience__action {
  flex-direction: column;
  display: flex;
}

.sl-guided-experience .sl-guided-experience__action .sl-divider {
  margin: 0 0 1.5rem;
}

.sl-guided-experience .sl-guided-experience__action .sl-guided-experience__button {
  flex-direction: row;
  gap: 1.5rem;
  display: flex;
}

@media (width <= 904.98px) {
  .sl-guided-experience .sl-guided-experience__action .sl-guided-experience__button {
    flex-direction: column;
  }
}

.sl-guided-experience .sl-guided-experience__menu {
  background: var(--brand-secondary-100);
  margin-bottom: 0;
  margin-left: auto;
  padding: 3.5rem 0 1.5rem;
}

.sl-guided-experience .sl-guided-experience__menu li {
  list-style-type: none;
}

.sl-guided-experience .sl-guided-experience__menu li button {
  background: var(--brand-secondary-100);
  border: none;
  border: .125rem solid var(--brand-secondary-100);
  align-items: flex-start;
  gap: .25rem;
  width: 100%;
  padding: .875rem 1.375rem;
  display: flex;
}

.sl-guided-experience .sl-guided-experience__menu li button:focus-visible {
  border: .125rem solid var(--brand-tertiary-500);
  outline: none;
}

.sl-guided-experience .sl-guided-experience__menu li button:hover {
  background: var(--brand-secondary-200);
}

.sl-guided-experience .sl-guided-experience__menu li .sl-ge-step {
  border: .125rem solid var(--brand-secondary-100);
  align-items: flex-start;
  gap: .25rem;
  padding: .875rem 1.375rem;
  display: flex;
}

.sl-guided-experience .sl-guided-experience__menu li .sl-ge-step__button {
  display: flex;
}

.sl-guided-experience .sl-guided-experience__menu li .sl-ge-step_active {
  background: var(--brand-secondary-200);
}

.sl-guided-experience .sl-guided-experience__menu li .sl-ge-step_active .sl-ge-step__label {
  color: var(--grey-900);
  font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-guided-experience .sl-guided-experience__menu li .sl-ge-step__content {
  text-align: left;
  padding-left: .25rem;
  line-height: 1.5rem;
}

.sl-guided-experience .sl-guided-experience__menu li .sl-ge-step__description--individual {
  display: block;
}

.sl-guided-experience .sl-guided-experience__menu .sl-divider {
  margin: .25rem 0;
}

.sl-guided-experience .sl-guided-experience__menu .active-step button, .sl-guided-experience .sl-guided-experience__menu .active-step .text {
  background: var(--brand-secondary-200);
}

@media (width >= 905px) and (width <= 1239.98px) {
  .sl-guided-experience .sl-guided-experience__menu {
    width: calc(33.3333vw - 2.5rem - 16px);
    margin-left: 1.5rem;
    padding: 3rem 0 0;
  }
}

@media (width >= 1240px) and (width <= 1439.98px) {
  .sl-guided-experience .sl-guided-experience__menu {
    width: 368px;
    margin-left: 1.5rem;
  }
}

@media (width >= 1440px) {
  .sl-guided-experience .sl-guided-experience__menu {
    width: 368px;
    margin-left: 1.5rem;
  }
}

@media (width >= 905px) and (width <= 1239.98px) {
  .sl-guided-experience .sl-guided-experience__menu.sl-guided-experience__menu-small {
    width: calc(33.3333vw - 2.5rem - 16px);
    margin-left: 1.5rem;
  }
}

@media (width >= 1240px) and (width <= 1439.98px) {
  .sl-guided-experience .sl-guided-experience__menu.sl-guided-experience__menu-small {
    width: 270px;
    margin-left: 1.5rem;
  }
}

@media (width >= 1440px) {
  .sl-guided-experience .sl-guided-experience__menu.sl-guided-experience__menu-small {
    width: 270px;
    margin-left: 1.5rem;
  }
}

.sl-guided-experience__offcanvas {
  background: var(--brand-secondary-100);
  overflow: auto;
  border-left: none !important;
  width: 100% !important;
}

.sl-guided-experience__offcanvas .offcanvas-header {
  padding: 1.5rem 2rem 3rem;
  display: flex;
}

.sl-guided-experience__offcanvas .offcanvas-header .drawer-dismiss-button {
  background: none;
  border: none;
  width: 2rem;
  height: 2rem;
  margin: 0 0 0 auto;
  padding: 0;
}

.sl-guided-experience__offcanvas .offcanvas-header .drawer-dismiss-button:focus-visible {
  box-shadow: 0 0 0 .125rem var(--brand-tertiary-500);
  outline: 0;
}

.sl-guided-experience__offcanvas .sl-guided-experience__menu {
  margin: 0;
  padding: 0 0 1.5rem;
}

.sl-guided-experience__offcanvas li {
  list-style-type: none;
}

.sl-guided-experience__offcanvas li button {
  background: var(--brand-secondary-100);
  border: none;
  border: .125rem solid var(--brand-secondary-100);
  align-items: flex-start;
  gap: .25rem;
  width: 100%;
  padding: .875rem 1.375rem;
  display: flex;
}

.sl-guided-experience__offcanvas li button:focus-visible {
  border: .125rem solid var(--brand-tertiary-500);
  outline: none;
}

.sl-guided-experience__offcanvas li button:hover {
  background: var(--brand-secondary-200);
}

.sl-guided-experience__offcanvas li .sl-ge-step {
  border: .125rem solid var(--brand-secondary-100);
  align-items: flex-start;
  gap: .25rem;
  padding: .875rem 1.375rem;
  display: flex;
}

.sl-guided-experience__offcanvas li .sl-ge-step__button {
  display: flex;
}

.sl-guided-experience__offcanvas li .sl-ge-step_active {
  background: var(--brand-secondary-200);
}

.sl-guided-experience__offcanvas li .sl-ge-step_active .sl-ge-step__label {
  color: var(--grey-900);
  font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-guided-experience__offcanvas li .sl-ge-step__content {
  text-align: left;
  padding-left: .25rem;
  line-height: 1.5rem;
}

.sl-guided-experience__offcanvas li .sl-ge-step__description--individual {
  display: block;
}

.sl-guided-experience__offcanvas .sl-divider {
  margin: .25rem 0;
}

.sl-guided-experience__offcanvas .active-step button, .sl-guided-experience__offcanvas .active-step .text {
  background: var(--brand-secondary-200);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: .5;
  --bs-btn-close-hover-opacity: .75;
  --bs-btn-close-focus-shadow: 0 0 0 .25rem #0d6efd40;
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: .25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center / 1em auto no-repeat;
  opacity: var(--bs-btn-close-opacity);
  border: 0;
  border-radius: 0;
  padding: .25em;
}

.btn-close:hover {
  color: var(--bs-btn-close-color);
  opacity: var(--bs-btn-close-hover-opacity);
  text-decoration: none;
}

.btn-close:focus {
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
  outline: 0;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white, [data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), .65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), .8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), .3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), .15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-xs, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-text a, .navbar-text a:hover, .navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (width >= 600px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 905px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1240px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1440px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  width: auto !important;
  height: auto !important;
  transform: none !important;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-dark, .navbar[data-bs-theme="dark"] {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme="dark"] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.offcanvas, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform .3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (width <= 599.98px) {
  .offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 599.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (width <= 599.98px) {
  .offcanvas-sm.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translate(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translate(100%);
  }

  .offcanvas-sm.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (width >= 600px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 904.98px) {
  .offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 904.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (width <= 904.98px) {
  .offcanvas-md.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translate(-100%);
  }

  .offcanvas-md.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translate(100%);
  }

  .offcanvas-md.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (width >= 905px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 1239.98px) {
  .offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 1239.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (width <= 1239.98px) {
  .offcanvas-lg.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translate(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translate(100%);
  }

  .offcanvas-lg.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (width >= 1240px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 1439.98px) {
  .offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    transition: var(--bs-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 1439.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (width <= 1439.98px) {
  .offcanvas-xl.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translate(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translate(100%);
  }

  .offcanvas-xl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (width >= 1440px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  transition: var(--bs-offcanvas-transition);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translate(-100%);
}

.offcanvas.offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translate(100%);
}

.offcanvas.offcanvas-top {
  height: var(--bs-offcanvas-height);
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  max-height: 100%;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  z-index: 1040;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .5;
}

.offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  line-height: var(--bs-offcanvas-title-line-height);
  margin-bottom: 0;
}

.offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.sl-header {
  border-bottom: .063rem solid var(--grey-300);
  background: var(--white);
  z-index: 999;
  position: sticky;
  top: 0;
}

.sl-header .sl-nav {
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-padding-y: 0;
  --bs-offcanvas-width: 100%;
  --bs-offcanvas-height: 100%;
  background-color: var(--white);
  flex-wrap: wrap;
}

.sl-header .sl-nav .sl-logo {
  flex: 1;
  padding: 0;
}

@media (width >= 1240px) {
  .sl-header .sl-nav .sl-logo {
    flex: none;
  }
}

.sl-header .sl-nav .sl-logo img {
  width: 8rem;
  height: 2rem;
}

.sl-header .sl-nav .sl-logo a:focus-visible {
  outline: .125rem solid var(--brand-tertiary-500);
  outline-offset: -.125rem;
  box-shadow: none;
}

.sl-header .sl-nav .sl-logo.navbar-brand {
  margin-right: 0;
}

.sl-header .sl-nav .nav-item {
  margin-right: 1.5rem;
}

.sl-header .sl-nav .main-nav-item-container {
  flex-flow: column wrap;
  place-content: center;
  height: 5rem;
  display: flex;
}

.sl-header .sl-nav .main-nav-item-container.nav-link {
  color: var(--grey-900);
  padding: 1.906rem .5rem 1.406rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.6875rem;
}

@media (width >= 905px) {
  .sl-header .sl-nav .main-nav-item-container.nav-link {
    height: 5rem;
  }
}

.sl-header .sl-nav .main-nav-item-container.nav-link:focus-visible {
  outline: .125rem solid var(--brand-tertiary-500);
  outline-offset: -.125rem;
  box-shadow: none;
}

.sl-header .sl-nav .main-nav-item-container.nav-link:hover, .sl-header .sl-nav .main-nav-item-container.nav-link:active, .sl-header .sl-nav .main-nav-item-container.nav-link.active {
  border-bottom: .125rem solid var(--grey-800);
  padding: 1.969rem .5rem 1.344rem;
}

.sl-header .sl-nav .utility-nav-item-container {
  flex-flow: column wrap;
  place-content: center;
  height: 5rem;
  display: flex;
}

.sl-header .sl-nav .utility-nav-item-container.nav-link {
  color: var(--grey-800);
  padding: 1.906rem .5rem 1.406rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

@media (width >= 905px) {
  .sl-header .sl-nav .utility-nav-item-container.nav-link {
    height: 5rem;
  }
}

.sl-header .sl-nav .utility-nav-item-container.nav-link:hover {
  text-decoration: underline;
}

.sl-header .sl-nav .utility-nav-item-container.nav-link:focus-visible {
  outline: .125rem solid var(--brand-tertiary-500);
  outline-offset: -.125rem;
  box-shadow: none;
}

.sl-header .sl-nav .sl-main-nav li:last-of-type, .sl-header .sl-nav .sl-utility-nav li:last-of-type {
  margin-right: 0;
}

.sl-header .sl-nav .navbar-toggler {
  border: none;
}

.sl-header .sl-nav .navbar-toggler:focus-visible {
  outline: .125rem solid var(--brand-tertiary-500);
  outline-offset: -.125rem;
  box-shadow: none;
}

.sl-header .sl-nav .navbar-toggler:active, .sl-header .sl-nav .navbar-toggler:focus {
  outline: .125rem solid var(--brand-tertiary-500);
  box-shadow: none;
}

.sl-header .sl-nav .search-btn {
  color: var(--grey-800);
  background: none;
  border: none;
  outline: none;
  padding: .5rem;
}

.sl-header .sl-nav .search-btn:focus-visible {
  outline: .125rem solid var(--brand-tertiary-500);
  outline-offset: -.125rem;
  box-shadow: none;
}

.sl-header.sl-header-sponsor {
  border-top: .25rem solid var(--brand-sponsor-header);
}

.sl-header.sl-header-sponsor .sl-utility-nav .nav-item .dropdown .dropdown-menu {
  border-top: .25rem solid var(--brand-sponsor-header);
  margin-top: .063rem;
}

.sl-header .sl-logo-container.top-logo, .sl-header .sl-logo-container.full-logo {
  flex: 0 0 100%;
  justify-content: space-between;
  display: flex;
}

.sl-header .sl-logo-container.top-logo a, .sl-header .sl-logo-container.full-logo a {
  height: auto;
}

.sl-header .sl-logo-container.top-logo a:focus-visible, .sl-header .sl-logo-container.full-logo a:focus-visible {
  outline: .125rem solid var(--brand-tertiary-500);
  outline-offset: -.125rem;
  box-shadow: none;
}

.sl-header .sl-logo-container.top-logo a img, .sl-header .sl-logo-container.full-logo a img {
  height: auto;
}

.sl-header .sl-logo-container.top-logo {
  padding: 1.5rem 0;
}

.sl-header .sl-logo-container.top-logo .center-logo {
  flex-grow: 2;
}

.sl-header .sl-logo-container.full-logo img {
  width: auto;
}

.sl-header .sl-logo-container.inline-logo {
  margin-right: 4.5rem;
}

.sl-header-offcanvas {
  --bs-offcanvas-width: 100%;
}

.sl-header-offcanvas .offcanvas-header {
  height: 3.5rem;
  padding: 0 1rem;
}

.sl-header-offcanvas .offcanvas-header .canvas-close {
  width: 2rem;
  color: var(--grey-800);
  background: none;
  border: none;
  outline: none;
  padding: .5rem;
  font-size: 1.125rem;
}

.sl-header-offcanvas .offcanvas-header .canvas-close:focus-visible, .sl-header-offcanvas .offcanvas-header a:focus-visible {
  outline: .125rem solid var(--brand-tertiary-500);
  outline-offset: -.125rem;
  box-shadow: none;
}

.sl-header-offcanvas .offcanvas-header img {
  width: 7rem;
}

.sl-header-offcanvas .offcanvas-header .offcanvas-title {
  text-align: center;
  flex-grow: 1;
}

.sl-header-offcanvas .offcanvas-body {
  padding: 0;
}

@media (width <= 1239.98px) {
  .sl-header-offcanvas .offcanvas-body {
    position: relative;
  }

  .sl-header-offcanvas .offcanvas-body .nav-section-container {
    width: 100%;
    position: absolute;
    overflow-y: scroll;
  }
}

.sl-header-offcanvas .offcanvas-body .sl-utility-nav {
  margin-top: 4rem;
}

@media (width >= 1240px) {
  .sl-header-offcanvas .offcanvas-body .sl-utility-nav {
    margin-top: 0;
  }
}

@media (width <= 1239.98px) {
  .sl-header-offcanvas .offcanvas-body .nav-item {
    border-bottom: .063rem solid var(--grey-300);
    flex-flow: column wrap;
    justify-content: center;
    margin: 0 1rem;
    display: flex;
  }

  .sl-header-offcanvas .offcanvas-body .nav-item .lang-dropdown ul a:active, .sl-header-offcanvas .offcanvas-body .nav-item .lang-dropdown ul a.active {
    border-left: .125rem solid var(--brand-tertiary-800);
  }

  .sl-header-offcanvas .offcanvas-body .sl-main-nav li:last-of-type, .sl-header-offcanvas .offcanvas-body .sl-utility-nav li:last-of-type {
    border-bottom: none;
  }

  .sl-header-offcanvas .offcanvas-body .sl-main-nav .nav-link {
    color: var(--grey-900);
    font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.6875rem;
  }

  .sl-header-offcanvas .offcanvas-body .sl-utility-nav .nav-link {
    color: var(--grey-800);
    font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }

  .sl-header-offcanvas .offcanvas-body .nav-link {
    margin: 1.5rem 0;
    padding: 0;
  }

  .sl-header-offcanvas .offcanvas-body .nav-link:focus-visible {
    outline: .125rem solid var(--brand-tertiary-500);
    box-shadow: none;
  }

  .sl-header-offcanvas .offcanvas-body .nav-link svg {
    margin-right: .5rem;
  }

  .sl-header-offcanvas .offcanvas-body .nav-link.dropdown-toggle.nav-link.utility-nav-item-container {
    padding: 0;
  }
}

.sl-helper-text {
  color: var(--grey-900);
  margin: .5rem 0 0;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  display: inline-flex;
}

.sl-helper-text svg {
  margin: .125rem 0 0;
}

.sl-helper-text ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sl-helper-text.hint-text {
  color: var(--grey-700);
}

.sl-helper-text.error-text {
  color: var(--red-800);
}

.sl-helper-text.error-text .error-icon-container {
  text-align: center;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 .25rem 0 0;
}

.sl-helper-text.error-text .error-icon-container .error-icon {
  width: .875rem;
}

.sl-link.sl-link-tertiary, .sl-footer .footer-menu-items a, .sl-footer .footer-content-container .footer-content .footer-body a {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  text-decoration: none;
}

.sl-link.sl-link-tertiary:hover, .sl-footer .footer-menu-items a:hover, .sl-footer .footer-content-container .footer-content .footer-body a:hover {
  color: var(--grey-900);
  text-decoration-line: underline;
}

.sl-link.sl-link-tertiary:focus-visible, .sl-footer .footer-menu-items a:focus-visible, .sl-footer .footer-content-container .footer-content .footer-body a:focus-visible {
  outline: .125rem solid var(--brand-tertiary-500);
}

.sl-link {
  color: var(--brand-tertiary-800);
  cursor: pointer;
  white-space: nowrap;
  font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-decoration: none;
}

.sl-link:focus-visible {
  outline: .125rem solid var(--brand-tertiary-500);
}

.sl-link:hover {
  color: var(--brand-tertiary-900);
  text-decoration: underline;
}

.sl-link:visited {
  color: var(--brand-tertiary-800);
}

.sl-link.sl-link-secondary {
  color: var(--brand-primary-400);
}

.sl-link.sl-link-secondary:hover {
  color: var(--brand-primary-500);
}

.sl-link.sl-link-secondary:visited {
  color: var(--purple-300);
}

.sl-link.sl-link-sm {
  font-size: .875rem;
  line-height: 21px;
}

.sl-link.sl-link-lg {
  font-size: 1.125rem;
  line-height: 27px;
}

.sl-link.sl-link-disabled {
  opacity: .5;
}

.sl-link.sl-link-disabled:hover {
  text-decoration: none;
}

.sl-link .sl-icon-container {
  margin-left: .25rem;
  padding: .375rem;
}

.sl-media-block.card {
  --bs-card-spacer-y: 0rem;
  --bs-card-spacer-x: 0rem;
  --bs-card-title-spacer-y: 1rem;
  --bs-card-border-width: 0rem;
}

@media (width >= 905px) {
  .sl-media-block.card {
    flex-direction: row;
    align-items: center;
  }
}

.sl-media-block.card picture {
  flex: 0 50%;
  align-self: normal;
}

.sl-media-block.card picture img {
  object-fit: cover;
  height: 11.5rem;
}

@media (width >= 600px) and (width <= 904.98px) {
  .sl-media-block.card picture img {
    height: 18.875rem;
  }
}

@media (width >= 905px) and (width <= 1239.98px) {
  .sl-media-block.card picture img {
    min-height: 13.375rem;
  }
}

@media (width >= 1240px) {
  .sl-media-block.card picture img {
    min-height: 19.875rem;
  }
}

@media (width >= 905px) {
  .sl-media-block.card picture img {
    height: 100%;
  }
}

.sl-media-block.card .video-container {
  flex: 0 50%;
}

.sl-media-block.card .card-body {
  padding-top: 1.5rem;
}

@media (width >= 905px) {
  .sl-media-block.card .card-body {
    flex: 0 50%;
  }
}

.sl-media-block.card .card-body .card-title {
  --bs-card-title-color: var(--grey-900);
}

.sl-media-block.card .card-body .card-text {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-media-block.card .card-body .media-cta-container {
  margin-top: 1.5rem;
}

.sl-media-block.card .card-body.body-with-section .card-text {
  padding-bottom: 1rem;
}

.sl-media-block.card .card-body.body-with-section .media-cta-container {
  margin-top: 2.5rem;
}

.sl-media-block.card .card-body.body-with-section .media-section-container {
  margin-top: 1.5rem;
}

.sl-media-block.card .card-body.body-with-section .media-section-container.card-text {
  padding-bottom: 0;
}

.sl-media-block.card .card-body.body-with-section .media-section-container .card-title {
  color: var(--grey-800);
  margin-bottom: .25rem;
  font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-media-block.card .card-body.body-with-section .media-section-container .section-body {
  color: var(--grey-900);
  margin-bottom: .5rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

@media (width >= 905px) {
  .sl-media-block.card.media-block-left .card-body {
    padding: 0 0 0 1.5rem;
  }
}

.sl-media-block.card.media-block-right {
  flex-direction: column-reverse;
}

@media (width >= 905px) {
  .sl-media-block.card.media-block-right {
    flex-direction: row;
  }

  .sl-media-block.card.media-block-right .card-body {
    padding: 0 1.5rem 0 0;
  }
}

.sl-media-block.card.video-block .video-container, .sl-media-block.card.video-block iframe {
  height: 11.5rem;
}

@media (width >= 600px) and (width <= 904.98px) {
  .sl-media-block.card.video-block .video-container, .sl-media-block.card.video-block iframe {
    height: 18.875rem;
  }
}

@media (width >= 905px) and (width <= 1239.98px) {
  .sl-media-block.card.video-block .video-container, .sl-media-block.card.video-block iframe {
    height: 11rem;
  }
}

@media (width >= 1240px) {
  .sl-media-block.card.video-block .video-container, .sl-media-block.card.video-block iframe {
    height: 16.375rem;
  }
}

.sl-media-block.card.video-block .card-body .card-title {
  margin-bottom: .5rem;
}

.sl-media-block.card.video-block .card-body .watch-time {
  color: var(--grey-600);
  margin-bottom: 1rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius)  - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (width >= 600px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 905px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1240px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1440px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
  background-color: #0000;
  border: 0;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.sl-menu-dropdown.sl-menu-dropdown ul .dropdown-item:active, .sl-menu-dropdown.sl-menu-dropdown ul .dropdown-item.active {
  border-left: .125rem solid var(--brand-tertiary-800);
}

.sl-menu-dropdown.sl-menu-dropdown ul .dropdown-item:focus, .sl-menu-dropdown.sl-menu-dropdown ul .dropdown-item:hover {
  background-color: var(--grey-100) !important;
}

.sl-menu-dropdown.sl-menu-dropdown .dropdown-toggle.nav-link.utility-nav-item-container {
  color: var(--grey-800);
  background: none;
  border: none;
  align-items: center;
  padding: 1.906rem .5rem 1.406rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  display: flex;
}

@media (width <= 1239.98px) {
  .sl-menu-dropdown.sl-menu-dropdown .dropdown-toggle.nav-link.utility-nav-item-container {
    text-align: left;
    width: 100%;
  }

  .sl-menu-dropdown.sl-menu-dropdown .dropdown-toggle.nav-link.utility-nav-item-container svg {
    float: right;
  }
}

.sl-menu-dropdown.sl-menu-dropdown .dropdown-toggle.nav-link.utility-nav-item-container:hover {
  text-decoration: underline;
}

.sl-menu-dropdown.sl-menu-dropdown .dropdown-toggle.nav-link.utility-nav-item-container:active {
  background: inherit;
  color: inherit;
}

.sl-menu-dropdown.sl-menu-dropdown .dropdown-toggle.nav-link.utility-nav-item-container:focus-visible {
  outline: .125rem solid var(--brand-tertiary-500);
  box-shadow: none;
}

.sl-menu-dropdown.sl-menu-dropdown .dropdown-toggle.nav-link.utility-nav-item-container:after {
  content: none;
}

.sl-menu-dropdown.sl-menu-dropdown .dropdown-toggle.nav-link.utility-nav-item-container[aria-expanded="true"] .fa-chevron-down {
  transform-origin: 50%;
  transform: rotate(180deg);
}

.sl-menu-dropdown.sl-menu-dropdown .dropdown-toggle.nav-link.utility-nav-item-container .sl-dropdown-icon {
  margin-left: auto;
}

.sl-menu-dropdown.sl-menu-dropdown .dropdown-toggle.nav-link.utility-nav-item-container .dropdown-label {
  padding-right: .5rem;
}

.sl-menu-dropdown.sl-menu-dropdown .utility-notification-indicator {
  float: right;
  background: var(--brand-tertiary-800);
  border-radius: 50%;
  width: .375rem;
  height: .375rem;
  padding: .188rem;
  display: inline-block;
}

.sl-menu-dropdown.sl-menu-dropdown .dropdown-menu {
  background: var(--white);
  opacity: .98;
  border: none;
  border-radius: 4px;
  width: 100%;
  padding: 1rem 0;
  box-shadow: 0 4px 8px 3px #00000026;
}

@media (width >= 1240px) {
  .sl-menu-dropdown.sl-menu-dropdown .dropdown-menu {
    width: 16.875rem;
  }
}

.sl-menu-dropdown.sl-menu-dropdown .dropdown-menu ul {
  padding-left: 0;
  list-style: none;
}

.sl-menu-dropdown.sl-menu-dropdown .dropdown-menu .dropdown-item {
  white-space: break-spaces;
  color: var(--grey-700);
  justify-content: space-between;
  padding: .625rem 1.5rem .625rem 2.375rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  display: flex;
}

@media (width >= 1240px) {
  .sl-menu-dropdown.sl-menu-dropdown .dropdown-menu .dropdown-item {
    color: var(--grey-900);
    padding: .5rem 1.5rem;
  }
}

.sl-menu-dropdown.sl-menu-dropdown .dropdown-menu .dropdown-item:focus, .sl-menu-dropdown.sl-menu-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: var(--grey-100);
  color: var(--grey-900);
  font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

.sl-menu-dropdown.sl-menu-dropdown .dropdown-menu .dropdown-item:active, .sl-menu-dropdown.sl-menu-dropdown .dropdown-menu .dropdown-item.active {
  color: var(--grey-900);
  background: var(--white);
  padding: .625rem 1.5rem .625rem 2.375rem;
}

@media (width >= 1240px) {
  .sl-menu-dropdown.sl-menu-dropdown .dropdown-menu .dropdown-item:active, .sl-menu-dropdown.sl-menu-dropdown .dropdown-menu .dropdown-item.active {
    padding: .5rem 1.375rem;
  }
}

.sl-menu-dropdown.sl-menu-dropdown .dropdown-menu .dropdown-item:focus-visible {
  box-shadow: 0 0 0 .125rem var(--brand-tertiary-500);
  outline: none;
}

.sl-menu-dropdown.sl-menu-dropdown .dropdown-menu .dropdown-item .label-description {
  color: var(--grey-700);
  margin-top: .25rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .6875rem;
  font-weight: 400;
  line-height: 1rem;
  display: block;
}

.sl-menu-dropdown.sl-menu-dropdown .dropdown-menu .dropdown-item .menu-notification-indicator {
  float: right;
  background: var(--brand-tertiary-800);
  border-radius: 50%;
  width: .375rem;
  height: .375rem;
  margin-top: .469rem;
  padding: .188rem;
  display: inline-block;
}

.sl-menu-dropdown.sl-menu-dropdown .dropdown-menu .dropdown-menu-title {
  white-space: break-spaces;
  margin-bottom: 1rem;
  padding: 0 1.5rem 0 2.375rem;
}

@media (width >= 1240px) {
  .sl-menu-dropdown.sl-menu-dropdown .dropdown-menu .dropdown-menu-title {
    padding: 0 1.5rem;
  }
}

.sl-menu-dropdown.sl-menu-dropdown .dropdown-menu .dropdown-header {
  color: var(--grey-800);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

@media (width >= 1240px) {
  .sl-menu-dropdown.sl-menu-dropdown .dropdown-menu .dropdown-header {
    color: var(--grey-900);
    font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

.sl-mega-menu .dropdown {
  position: unset;
}

.sl-mega-menu .dropdown .sl-dropdown-icon {
  margin-left: .5rem;
}

.sl-mega-menu .dropdown .dropdown-toggle:after {
  display: none;
}

.sl-mega-menu .dropdown .main-nav-item-container {
  background: none;
  border: none;
  display: block;
  line-height: 1.906rem !important;
}

.sl-mega-menu .dropdown .main-nav-item-container[aria-expanded="true"] .fa-chevron-down {
  transform-origin: 50%;
  transform: rotate(180deg);
}

@media (width >= 1240px) {
  .sl-mega-menu .dropdown .main-nav-item-container:active, .sl-mega-menu .dropdown .main-nav-item-container .active {
    border-bottom: .125rem solid var(--grey-800);
    padding: 1.969rem .5rem 1.344rem;
  }
}

@media (width <= 1239.98px) {
  .sl-mega-menu .dropdown .main-nav-item-container {
    text-align: left;
    justify-content: space-between;
    width: 100%;
    display: flex;
  }
}

.sl-mega-menu .dropdown .dropdown-menu {
  border: 0;
  width: 100%;
  padding: 0;
}

.sl-mega-menu .dropdown .dropdown-menu .sl-divider {
  display: none;
}

@media (width >= 1240px) {
  .sl-mega-menu .dropdown .dropdown-menu.limited {
    margin: .0625rem 0 0;
    position: absolute;
  }

  .sl-mega-menu .dropdown .dropdown-menu.limited .sl-divider {
    border-top: .0625rem solid;
    width: 100%;
    margin: 0;
    display: block;
    position: fixed;
    left: 0;
  }
}

.sl-mega-menu .dropdown .dropdown-menu .limited-menu {
  padding: 0;
  list-style: none;
}

@media (width >= 1240px) {
  .sl-mega-menu .dropdown .dropdown-menu .limited-menu {
    margin: .75rem 0;
    display: flex;
  }
}

.sl-mega-menu .dropdown .dropdown-menu .limited-menu li {
  margin-bottom: .25rem;
  padding: .625rem 0;
}

@media (width >= 1240px) {
  .sl-mega-menu .dropdown .dropdown-menu .limited-menu li {
    margin: 0 2.5rem 0 0;
    padding: 0;
  }
}

.sl-mega-menu .dropdown .dropdown-menu .limited-menu li .dropdown-item {
  color: var(--grey-900);
  padding: 0;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

@media (width >= 1240px) {
  .sl-mega-menu .dropdown .dropdown-menu .limited-menu li .dropdown-item {
    color: var(--grey-900);
    padding: .25rem .5rem;
    font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

.sl-mega-menu .dropdown .dropdown-menu .limited-menu li .dropdown-item:focus-visible {
  outline: .125rem solid var(--brand-tertiary-500);
  outline-offset: -.125rem;
  box-shadow: none;
}

.sl-mega-menu .dropdown .dropdown-menu .limited-menu li .dropdown-item:hover {
  background: none;
  text-decoration: underline;
}

.sl-mega-menu .dropdown .dropdown-menu .limited-menu li .dropdown-item:active, .sl-mega-menu .dropdown .dropdown-menu .limited-menu li .dropdown-item.active {
  color: var(--grey-900);
  background: none;
  font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

@media (width >= 1240px) {
  .sl-mega-menu .dropdown .dropdown-menu .limited-menu li .dropdown-item:active, .sl-mega-menu .dropdown .dropdown-menu .limited-menu li .dropdown-item.active {
    color: var(--grey-900);
    font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

.sl-mega-menu .dropdown .dropdown-menu .extensive-menu {
  opacity: .98;
}

@media (width >= 1240px) {
  .sl-mega-menu .dropdown .dropdown-menu .extensive-menu {
    padding: 2.5rem 2.75rem;
    box-shadow: 0 4px 8px 3px #00000014;
  }

  .sl-mega-menu .dropdown .dropdown-menu .extensive-menu .menu-header-container {
    margin-bottom: 2.5rem;
    padding: 0;
  }
}

@media (width <= 1239.98px) {
  .sl-mega-menu .dropdown .dropdown-menu .extensive-menu .menu-header-container {
    justify-content: space-between;
    align-items: center;
    margin-bottom: .5rem;
    padding: .5rem 0;
    display: flex;
  }
}

.sl-mega-menu .dropdown .dropdown-menu .extensive-menu .menu-header-container .menu-header {
  margin-bottom: 0;
  display: inline;
}

.sl-mega-menu .dropdown .dropdown-menu .extensive-menu .menu-header-container .menu-header a {
  color: inherit;
  text-decoration: none;
}

.sl-mega-menu .dropdown .dropdown-menu .extensive-menu .menu-header-container .menu-header a:hover {
  background: none;
  text-decoration: underline;
}

.sl-mega-menu .dropdown .dropdown-menu .extensive-menu .menu-header-container .menu-header a:focus-visible {
  outline: .125rem solid var(--brand-tertiary-500);
  outline-offset: -.125rem;
  box-shadow: none;
}

.sl-mega-menu .dropdown .dropdown-menu .extensive-menu .menu-header-container .sl-dropdown-icon {
  margin-right: .5rem;
}

.sl-mega-menu .dropdown .dropdown-menu .extensive-menu .sub-menu-container {
  flex-flow: column wrap;
  justify-content: space-between;
  display: flex;
}

@media (width >= 1240px) {
  .sl-mega-menu .dropdown .dropdown-menu .extensive-menu .sub-menu-container {
    flex-direction: row;
    row-gap: 2.5rem;
  }

  .sl-mega-menu .dropdown .dropdown-menu .extensive-menu .sub-menu-container .extensive-sub-menu {
    width: 25%;
  }
}

.sl-mega-menu .dropdown .dropdown-menu .extensive-menu .sub-menu-container .extensive-sub-menu .sub-header-container {
  margin-bottom: 0;
  padding: 1rem 0;
}

@media (width >= 1240px) {
  .sl-mega-menu .dropdown .dropdown-menu .extensive-menu .sub-menu-container .extensive-sub-menu .sub-header-container {
    padding: 0;
  }
}

.sl-mega-menu .dropdown .dropdown-menu .extensive-menu .sub-menu-container .extensive-sub-menu .sub-header-container a {
  color: inherit;
  text-decoration: none;
}

.sl-mega-menu .dropdown .dropdown-menu .extensive-menu .sub-menu-container .extensive-sub-menu .sub-header-container a:hover {
  background: none;
  text-decoration: underline;
}

.sl-mega-menu .dropdown .dropdown-menu .extensive-menu .sub-menu-container .extensive-sub-menu .sub-header-container a:focus-visible {
  outline: .125rem solid var(--brand-tertiary-500);
  outline-offset: -.125rem;
  box-shadow: none;
}

.sl-mega-menu .dropdown .dropdown-menu .extensive-menu .sub-menu-container .extensive-sub-menu ul {
  padding: 0;
  list-style: none;
}

.sl-mega-menu .dropdown .dropdown-menu .extensive-menu .sub-menu-container .extensive-sub-menu ul li {
  margin-bottom: .25rem;
  padding: .625rem 0;
}

@media (width >= 1240px) {
  .sl-mega-menu .dropdown .dropdown-menu .extensive-menu .sub-menu-container .extensive-sub-menu ul li {
    margin: 1rem 0 0;
    padding: 0;
  }
}

.sl-mega-menu .dropdown .dropdown-menu .extensive-menu .sub-menu-container .extensive-sub-menu ul li a {
  color: inherit;
  text-decoration: none;
}

.sl-mega-menu .dropdown .dropdown-menu .extensive-menu .sub-menu-container .extensive-sub-menu ul li a:hover {
  background: none;
  text-decoration: underline;
}

.sl-mega-menu .dropdown .dropdown-menu .extensive-menu .sub-menu-container .extensive-sub-menu ul li a:focus-visible {
  outline: .125rem solid var(--brand-tertiary-500);
  outline-offset: -.125rem;
  box-shadow: none;
}

.sl-mega-menu .dropdown .dropdown-menu .extensive-menu .sub-menu-container .extensive-sub-menu ul li .dropdown-item {
  color: var(--grey-900);
  padding: 0;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  display: inline;
}

.sl-mega-menu .dropdown .dropdown-menu .extensive-menu .sub-menu-container .extensive-sub-menu ul li .dropdown-item:active, .sl-mega-menu .dropdown .dropdown-menu .extensive-menu .sub-menu-container .extensive-sub-menu ul li .dropdown-item.active {
  color: var(--grey-900);
  background: none;
  font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg)  - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  z-index: var(--bs-modal-zindex);
  outline: 0;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden auto;
}

.modal-dialog {
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translateY(-50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.modal-content {
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  background-color: var(--bs-backdrop-bg);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (width >= 600px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (width >= 1240px) {
  .modal-lg, .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (width >= 1440px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
}

.modal-fullscreen .modal-header, .modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (width <= 599.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-sm-down .modal-header, .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 904.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-md-down .modal-header, .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 1239.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-lg-down .modal-header, .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 1439.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xl-down .modal-header, .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

.sl-modal {
  --bs-modal-border-radius: 0;
  --bs-modal-margin: .5rem;
  --bs-modal-header-padding: 0;
  --bs-modal-header-border-width: 0;
  --bs-modal-footer-border-width: 0;
  --bs-modal-width: 47.5rem;
  --bs-modal-padding: 0;
  --bs-backdrop-bg: var(--grey-900);
  --bs-backdrop-opacity: .75;
}

@media (width >= 905px) and (width <= 1239.98px) {
  .sl-modal .modal-dialog, .sl-modal.modal-dialog {
    width: 32.1875rem;
  }
}

@media (width >= 600px) and (width <= 904.98px) {
  .sl-modal .modal-dialog, .sl-modal.modal-dialog {
    width: 33.5rem;
  }
}

.sl-modal .modal-dialog .modal-content, .sl-modal.modal-dialog .modal-content {
  padding: 2rem;
}

@media (width >= 600px) {
  .sl-modal .modal-dialog .modal-content, .sl-modal.modal-dialog .modal-content {
    padding: 2.5rem;
  }
}

.sl-modal .modal-dialog.modal-dialog-scrollable .modal-content, .sl-modal.modal-dialog.modal-dialog-scrollable .modal-content {
  min-height: 14.6875rem;
  max-height: 80%;
}

@media (width >= 905px) {
  .sl-modal .modal-dialog.modal-dialog-scrollable .modal-content, .sl-modal.modal-dialog.modal-dialog-scrollable .modal-content {
    max-height: 40.625rem;
  }
}

.sl-modal .modal-dialog.modal-dialog-scrollable .modal-header button, .sl-modal.modal-dialog.modal-dialog-scrollable .modal-header button {
  background: none;
  border: none;
  width: 2rem;
  height: 2rem;
  margin-bottom: auto;
  line-height: 0;
}

.sl-modal .modal-dialog .modal-title, .sl-modal.modal-dialog .modal-title {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.5rem;
}

.sl-modal .modal-dialog .modal-body, .sl-modal.modal-dialog .modal-body {
  margin: 1.5rem 0 0;
  padding-top: 0;
  padding-bottom: 0;
}

.sl-modal .modal-dialog .modal-body > *, .sl-modal.modal-dialog .modal-body > * {
  margin-bottom: 0;
}

.sl-modal .modal-dialog .modal-footer, .sl-modal.modal-dialog .modal-footer {
  padding-top: 1.5rem;
}

.sl-modal .modal-dialog .modal-footer button, .sl-modal.modal-dialog .modal-footer button {
  margin: 0;
}

.sl-modal .modal-dialog .modal-footer button:first-of-type, .sl-modal.modal-dialog .modal-footer button:first-of-type {
  margin: 0 0 1.5rem;
}

@media (width >= 905px) {
  .sl-modal .modal-dialog .modal-footer button, .sl-modal.modal-dialog .modal-footer button {
    margin: 0;
  }

  .sl-modal .modal-dialog .modal-footer button:first-of-type, .sl-modal.modal-dialog .modal-footer button:first-of-type {
    margin: 0 1.5rem 0 0;
  }
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  color: var(--bs-alert-link-color);
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

.sl-notification {
  --bs-alert-padding-x: 0;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: .5rem;
  border: none;
  flex-direction: column;
  display: flex;
  box-shadow: 0 4px 8px 3px #00000026, 0 1px 3px #0000001a;
}

.sl-notification .notification-component {
  align-items: baseline;
  display: flex;
}

.sl-notification .btn-close {
  width: 1.5rem;
  color: var(--grey-900);
  background-color: #0000;
  background-image: none;
  border: none;
  margin: auto;
}

.sl-notification .notification-buttons {
  margin-left: auto;
  display: flex;
}

.sl-notification .notification-heading {
  word-break: break-word;
  margin: 0 1.5rem 0 0;
}

.sl-notification .multiline-notification-text {
  color: var(--grey-900);
  word-break: break-word;
  margin: 1rem 2.5rem 0 2rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-notification .notification-text {
  color: var(--grey-900);
  word-break: break-word;
  margin: 0 1.5rem 0 0;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-notification .multiline-action-button {
  margin-top: 1rem;
  margin-left: 2rem;
}

@media (width <= 904.98px) {
  .sl-notification .multiline-action-button {
    margin-left: 0;
  }
}

.sl-notification .oneline-action-button {
  margin: auto 1.5rem auto 0;
}

.sl-notification.alert-info {
  border-left: solid .25rem var(--brand-secondary-800);
  --bs-alert-bg: var(--brand-secondary-100);
}

.sl-notification.alert-info .icon-color {
  color: var(--brand-secondary-800);
}

.sl-notification.alert-success {
  border-left: solid .25rem var(--green-800);
  --bs-alert-bg: var(--green-50);
}

.sl-notification.alert-success .icon-color {
  color: var(--green-800);
}

.sl-notification.alert-warning {
  border-left: solid .25rem var(--brand-primary-800);
  --bs-alert-bg: var(--brand-primary-50);
}

.sl-notification.alert-warning .icon-color {
  color: var(--brand-primary-800);
}

.sl-notification.alert-danger {
  border-left: solid .25rem var(--red-800);
  --bs-alert-bg: var(--red-50);
}

.sl-notification.alert-danger .icon-color {
  color: var(--red-800);
}

.sl-notification.alert-danger, .sl-notification.alert-warning, .sl-notification.alert-success, .sl-notification.alert-info {
  --bs-alert-color: var(--grey-900);
}

.sl-notification.alert-danger .notification-icon, .sl-notification.alert-warning .notification-icon, .sl-notification.alert-success .notification-icon, .sl-notification.alert-info .notification-icon {
  text-align: center;
  width: 1.5rem;
  margin: 0 .5rem 0 0;
}

.sl-notification.alert-danger .notification-icon .icon-color, .sl-notification.alert-warning .notification-icon .icon-color, .sl-notification.alert-success .notification-icon .icon-color, .sl-notification.alert-info .notification-icon .icon-color {
  width: 1rem;
}

.sl-pagination {
  width: 100%;
  margin: 0;
  padding: 0;
}

.sl-pagination .sl-basic-pagination {
  justify-content: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.sl-pagination .sl-basic-pagination .sl-pagination-item {
  justify-content: center;
  align-items: center;
  min-width: 2rem;
  height: 2rem;
  margin: 0 .125rem;
  display: inline-flex;
}

.sl-pagination .sl-basic-pagination .sl-pagination-item:first-child {
  margin-left: 0;
  margin-right: .375rem;
}

.sl-pagination .sl-basic-pagination .sl-pagination-item:last-child {
  margin-left: .375rem;
  margin-right: 0;
}

.sl-pagination .sl-basic-pagination .sl-pagination-item:not(.ellipsis) button, .sl-pagination .sl-basic-pagination .sl-pagination-item:not(.ellipsis) a {
  color: var(--grey-900);
  background-color: #0000;
  border: 2px solid #0000;
  border-radius: 0;
  outline: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 2px 0;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-decoration: none;
  display: flex;
  overflow: hidden;
}

.sl-pagination .sl-basic-pagination .sl-pagination-item:not(.ellipsis) button:hover, .sl-pagination .sl-basic-pagination .sl-pagination-item:not(.ellipsis) a:hover {
  background-color: var(--grey-100);
}

.sl-pagination .sl-basic-pagination .sl-pagination-item:not(.ellipsis) button:focus-visible, .sl-pagination .sl-basic-pagination .sl-pagination-item:not(.ellipsis) a:focus-visible {
  border-color: var(--brand-tertiary-500);
}

.sl-pagination .sl-basic-pagination .sl-pagination-item:not(.ellipsis).selected-item button, .sl-pagination .sl-basic-pagination .sl-pagination-item:not(.ellipsis).selected-item a {
  color: #fff;
  background-color: var(--brand-tertiary-800);
  font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-pagination .sl-basic-pagination .sl-pagination-item:not(.ellipsis).selected-item button:hover, .sl-pagination .sl-basic-pagination .sl-pagination-item:not(.ellipsis).selected-item a:hover {
  background-color: var(--brand-tertiary-800);
}

.sl-pagination .sl-basic-pagination .sl-pagination-item:not(.ellipsis).selected-item button:focus, .sl-pagination .sl-basic-pagination .sl-pagination-item:not(.ellipsis).selected-item a:focus {
  border-color: #0000;
}

.sl-pagination .sl-basic-pagination .sl-pagination-item:not(.ellipsis).disabled button:disabled, .sl-pagination .sl-basic-pagination .sl-pagination-item:not(.ellipsis).disabled a {
  color: var(--grey-500);
  pointer-events: none;
  border-color: #0000;
}

.sl-pagination .sl-basic-pagination ~ .load-more-pagination {
  display: none;
}

@media (width <= 904.98px) {
  .sl-pagination .sl-basic-pagination {
    display: none;
  }

  .sl-pagination .sl-basic-pagination ~ .load-more-pagination {
    display: block;
  }
}

.sl-pagination .sl-advanced-pagination {
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 1.5rem;
  margin: 0;
  padding: 0;
  display: flex;
}

.sl-pagination .sl-advanced-pagination .sl-pagination-item {
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 .75rem;
  display: inline-flex;
}

.sl-pagination .sl-advanced-pagination .sl-pagination-item button {
  color: var(--grey-900);
  background-color: #0000;
  border: .125rem solid #0000;
  border-radius: 0;
  outline: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-decoration: none;
  display: flex;
  overflow: hidden;
}

.sl-pagination .sl-advanced-pagination .sl-pagination-item button:hover {
  background-color: var(--grey-100);
}

.sl-pagination .sl-advanced-pagination .sl-pagination-item button:focus-visible {
  border-color: var(--brand-tertiary-500);
}

.sl-pagination .sl-advanced-pagination .sl-pagination-item button .icon-container {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.sl-pagination .sl-advanced-pagination .sl-pagination-item.disabled button:disabled, .sl-pagination .sl-advanced-pagination .sl-pagination-item.disabled a {
  color: var(--grey-500);
  pointer-events: none;
  border-color: #0000;
}

.sl-pagination .sl-advanced-pagination .sl-adp-left .sl-dropdown {
  min-width: 5.25rem;
}

.sl-pagination .sl-advanced-pagination .sl-adp-right {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  row-gap: 1.5rem;
  display: flex;
}

.sl-pagination .sl-advanced-pagination .sl-adp-right .sl-dropdown {
  min-width: 7.5rem;
}

.sl-pagination .sl-advanced-pagination .adp-content {
  align-items: center;
  display: flex;
}

.sl-pagination .sl-advanced-pagination .adp-range {
  white-space: nowrap;
  color: var(--grey-900);
  margin-right: .75rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

.sl-pagination .sl-advanced-pagination .sl-adp-dropdown {
  justify-content: center;
  align-items: center;
  display: flex;
}

.sl-pagination .sl-advanced-pagination .sl-adp-dropdown .adp-label {
  color: var(--grey-900);
  white-space: nowrap;
  margin-right: 1rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

.sl-pagination .sl-advanced-pagination .sl-adp-dropdown .sl-dropdown .combo-input .combo-input-selected {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

.sl-pagination .sl-advanced-pagination .sl-adp-dropdown .sl-dropdown .combo-option {
  padding: .5rem;
}

@media (width <= 599.98px) {
  .sl-pagination .sl-advanced-pagination, .sl-pagination .sl-advanced-pagination .sl-adp-right {
    justify-content: flex-start;
  }
}

.sl-pagination .sl-advanced-pagination ~ .load-more-pagination {
  display: none;
}

@media (width <= 904.98px) {
  .sl-pagination .sl-advanced-pagination.adp-disabled {
    display: none;
  }

  .sl-pagination .sl-advanced-pagination ~ .load-more-pagination {
    display: block;
  }
}

.sl-pagination .load-more-pagination {
  width: 100%;
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg)  - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.popover .popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.popover .popover-arrow:before, .popover .popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.sl-popover {
  filter: drop-shadow(0 1px 3px #0000001a);
  --bs-popover-border-color: transparent;
  border: none;
  border-radius: 0;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem;
  display: flex;
  box-shadow: 0 4px 8px 3px #00000026;
}

@media (width >= 600px) {
  .sl-popover {
    max-width: 24rem;
  }
}

.sl-popover .popover-component {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.sl-popover .popover-component .popover-heading {
  flex-direction: row;
  display: flex;
}

.sl-popover .popover-component .popover-heading .heading-text {
  color: var(--grey-900);
  margin-bottom: 1rem;
  margin-right: auto;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 1.75rem;
}

.sl-popover .popover-component .popover-heading .popover-close-container {
  padding-left: .5rem;
}

.sl-popover .popover-component .popover-heading .popover-close-container .btn-close {
  color: var(--grey-900);
  background: none;
  border: none;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  font-size: 1.125rem;
}

.sl-popover .popover-component .popover-heading .popover-close-container .btn-close:active, .sl-popover .popover-component .popover-heading .popover-close-container .btn-close:focus {
  box-shadow: none;
}

.sl-popover .popover-component .popover-heading .popover-close-container .btn-close:focus-visible {
  outline: solid .125rem var(--brand-tertiary-500);
}

.sl-popover .popover-component .popover-body {
  padding: inherit;
  color: var(--grey-900);
  margin-right: 2rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-popover-container {
  vertical-align: middle;
  margin: .0625rem;
  display: inline-block;
}

.sl-popover-container .popover-icon {
  color: var(--brand-tertiary-800);
  display: flex;
}

.sl-popover-container .popover-button.btn-primary {
  --bs-btn-active-bg: none;
  --bs-btn-focus-shadow-rgb: none;
  background: none;
  border: none;
  width: 1.5rem;
  height: 1.5rem;
  padding: .125rem;
}

.sl-popover-container .popover-button.btn-primary .sl-icon-container {
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: auto;
}

.sl-popover-container .popover-button.btn-primary:focus-visible {
  border: .125rem solid var(--brand-tertiary-500);
  outline: none;
}

.sl-popover-container .popover-button.btn-primary:first-child:active {
  border: none;
}

:root {
  --progress-backdrop: #202223;
  --progress-backdrop-opacity: .75;
}

.sl-progress .progress-indicator {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sl-progress .progress-indicator .indeterminate svg {
  color: var(--brand-tertiary-800);
  stroke-width: 4px;
  animation: 1s linear infinite spin;
}

.sl-progress .progress-indicator .determinate {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.sl-progress .progress-indicator .determinate:after {
  color: var(--brand-tertiary-800);
  content: attr(data-value);
  text-align: center;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  display: flex;
  position: absolute;
}

.sl-progress .progress-indicator .determinate .progress {
  stroke-width: 4px;
  transform: rotate(-90deg);
}

.sl-progress .progress-indicator .determinate .progress-meter, .sl-progress .progress-indicator .determinate .progress-value {
  fill: none;
}

.sl-progress .progress-indicator .determinate .progress-meter {
  stroke: #dcdedf;
}

.sl-progress .progress-indicator .determinate .progress-value {
  stroke: var(--brand-tertiary-800);
  stroke-dashoffset: 188px;
  transition: all .3s;
}

.sl-progress .progress-indicator .progress-label {
  color: var(--grey-900);
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: .5rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  display: flex;
}

.sl-progress .progress-indicator.light .indeterminate svg, .sl-progress .progress-indicator.light .determinate:after {
  color: var(--brand-tertiary-300);
}

.sl-progress .progress-indicator.light .determinate .progress-meter {
  stroke: var(--grey-300);
}

.sl-progress .progress-indicator.light .determinate .progress-value {
  stroke: var(--brand-tertiary-300);
}

.sl-progress .progress-indicator.light .progress-label {
  color: #fff;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.sl-progress .modal-content {
  background-color: #0000;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sl-progress-backdrop.show {
  background: var(--progress-backdrop);
  opacity: var(--progress-backdrop-opacity);
}

.sl-search {
  width: 100%;
}

.sl-search .sl-search-input {
  flex-direction: column;
  display: flex;
  position: relative;
}

.sl-search .sl-search-input .search-input-body-heading {
  margin-bottom: 1rem;
}

.sl-search .sl-search-input .search-input-header {
  border-color: var(--grey-300);
  color: var(--grey-800);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  height: 2rem;
  margin-bottom: 1.5rem;
  padding: 0 4.5rem .5rem 0;
  font-size: 1rem;
  line-height: 1.5rem;
}

.sl-search .sl-search-input .search-input-header:focus {
  box-shadow: none;
}

.sl-search .sl-search-input .search-input-header:focus-visible {
  outline: 0;
}

.sl-search .sl-search-input .search-input-body {
  border: solid .0625rem var(--grey-700);
  color: var(--grey-800);
  height: 3rem;
  margin-bottom: 1.5rem;
  padding: .75rem 6rem .75rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.sl-search .sl-search-input .search-input-body:focus {
  box-shadow: 0 0 0 .125rem var(--brand-tertiary-500);
  border-color: var(--brand-tertiary-900);
  outline: 0;
}

.sl-search .sl-search-input .search-input-header::placeholder, .sl-search .sl-search-input .search-input-body::placeholder {
  color: var(--grey-700);
  opacity: 1;
}

.sl-search .sl-search-input input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.sl-search .sl-search-input .clear-icon {
  height: 1.5rem;
  color: var(--grey-500);
  text-align: center;
  background-color: #0000;
  border: 0;
  margin-right: .5rem;
}

.sl-search .sl-search-input .clear-icon:focus-visible {
  box-shadow: 0 0 0 .125rem var(--brand-tertiary-500);
  outline: 0;
}

.sl-search .sl-search-input .body-clear-icon {
  width: 2rem;
  height: 2rem;
}

.sl-search .sl-search-input .search-separator {
  align-self: center;
  height: 1rem;
  margin-right: .5rem;
}

.sl-search .sl-search-input .search-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--grey-800);
  text-align: center;
  background-color: #0000;
  border: 0;
}

.sl-search .sl-search-input .search-icon:focus-visible {
  box-shadow: 0 0 0 .125rem var(--brand-tertiary-500);
  outline: 0;
}

.sl-search .sl-search-input .body-search-icon {
  width: 2rem;
  height: 2rem;
  color: var(--grey-900);
  margin: 0 .5rem 0 0;
}

.sl-search .sl-search-input .search-header-icons {
  display: flex;
  position: absolute;
  right: 0;
}

.sl-search .sl-search-input .search-body-icons {
  margin: 0 .5rem 0 0;
  display: flex;
  position: absolute;
  top: 4.3rem;
  right: 0;
}

.sl-search .sl-search-input .search-body-icons-position {
  top: .6rem;
}

.sl-search .sl-search-input .search-helper-text {
  margin-top: -.5rem;
}

.sl-search .sl-search-input .search-suggestions {
  flex-direction: column;
  display: flex;
}

.sl-search .sl-search-input .search-suggestions .search-dropdown-title {
  color: var(--grey-800);
  align-items: center;
  margin-bottom: 0;
  padding: 0 1.5rem 1rem;
  display: flex;
}

@media (width <= 1239.98px) {
  .sl-search .sl-search-input .search-suggestions .search-dropdown-title {
    padding: 0 3.75rem 1rem;
  }
}

@media (width <= 904.98px) {
  .sl-search .sl-search-input .search-suggestions .search-dropdown-title {
    padding: 0 2rem 1rem;
  }
}

@media (width <= 599.98px) {
  .sl-search .sl-search-input .search-suggestions .search-dropdown-title {
    padding: 0 1rem 1rem;
  }
}

.sl-search .sl-search-input .search-suggestions .search-dropdown {
  color: var(--grey-900);
  z-index: 10000;
  max-height: 19rem;
  margin: 0;
  padding: 1.5rem 0;
  list-style-type: none;
  display: block;
  overflow: auto;
  box-shadow: 0 4px 8px 3px #00000014;
}

.sl-search .sl-search-input .search-suggestions .search-dropdown .search-dropdown-list-item {
  color: var(--grey-900);
  align-items: center;
  max-height: 37px;
  margin-bottom: .25rem;
  padding: .5rem 1.5rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  display: flex;
}

.sl-search .sl-search-input .search-suggestions .search-dropdown .search-dropdown-list-item:last-child {
  margin-bottom: 0;
}

.sl-search .sl-search-input .search-suggestions .search-dropdown .search-dropdown-list-item:hover {
  background-color: var(--grey-100);
}

@media (width <= 1239.98px) {
  .sl-search .sl-search-input .search-suggestions .search-dropdown .search-dropdown-list-item {
    padding: .5rem 3.75rem;
  }
}

@media (width <= 904.98px) {
  .sl-search .sl-search-input .search-suggestions .search-dropdown .search-dropdown-list-item {
    padding: .5rem 2rem;
  }
}

@media (width <= 599.98px) {
  .sl-search .sl-search-input .search-suggestions .search-dropdown .search-dropdown-list-item {
    padding: .5rem 1rem;
  }
}

.sl-search .sl-search-input .search-suggestions .search-dropdown .list-item-focus {
  border: solid .125rem var(--brand-tertiary-500);
}

.sl-search .sl-search-input .search-suggestions .search-dropdown .-list-item-out-of-focus {
  border: none;
}

.sl-search .sl-search-input .search-suggestions .search-dropdown-header {
  margin-block-start: -3.875rem;
  padding-block-start: 4.25rem;
}

.sl-search .sl-search-input .search-suggestions .search-dropdown-body {
  margin-block-start: -4rem;
  padding-block-start: 4.25rem;
}

.sl-search .sl-search-input .search-suggestions .search-dropdown-without-title {
  max-height: 16rem;
  margin-block-start: -1.5rem;
  padding-block-start: 1.5rem;
}

.sl-search .sl-search-input .no-suggestion-found {
  color: var(--grey-900);
  z-index: 10000;
  margin: 0;
  margin-block-start: -1.5rem;
  padding: 24px;
  list-style-type: none;
  box-shadow: 0 4px 8px 3px #00000014;
}

@media (width <= 1239.98px) {
  .sl-search .sl-search-input .no-suggestion-found {
    padding: .5rem 3.75rem;
  }
}

@media (width <= 904.98px) {
  .sl-search .sl-search-input .no-suggestion-found {
    padding: .5rem 2rem;
  }
}

@media (width <= 599.98px) {
  .sl-search .sl-search-input .no-suggestion-found {
    padding: .5rem 1rem;
  }
}

.sl-search .sl-search-input:focus-visible {
  outline: solid .125rem var(--brand-tertiary-500);
  outline-offset: 8px;
}

.sl-search .sl-search-input-body:focus-visible {
  outline: 0;
}

.sl-search-chips-container {
  display: flex;
}

.sl-search-chips-container:first-child {
  margin-left: 1rem;
}

.sl-search-chips-container:last-child {
  margin-right: .5rem;
}

.sl-search-chips-container .sl-search-chip {
  border: .0625rem solid var(--grey-700);
  white-space: nowrap;
  background: none;
  border-radius: 1.875rem;
  align-items: center;
  height: 1.8125rem;
  margin: 0 .5rem 0 0;
  padding: .25rem .75rem;
  line-height: 1.3125rem;
  display: flex;
}

.sl-search-chips-container .sl-search-chip:hover {
  border-color: var(--brand-tertiary-900);
  background-color: var(--grey-100);
}

.sl-search-chips-container .sl-search-chip:focus-visible {
  box-shadow: 0 0 0 .125rem var(--brand-tertiary-500);
  border-color: var(--grey-700);
  outline: 0;
}

.sl-search-chips-container .sl-search-chip .search-chip-filter-type-text {
  color: var(--grey-700);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

.sl-search-chips-container .sl-search-chip .search-chip-query-text {
  color: var(--brand-tertiary-800);
  font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

.sl-search-chips-container .sl-search-chip .search-chip-dismiss-button {
  width: 1.25rem;
  height: 1.25rem;
  color: var(--grey-900);
  background: none;
  border: none;
  margin: 0 0 0 .5rem;
}

.sl-search-chips-container .sl-search-chip .search-chip-dismiss-button:focus-visible {
  box-shadow: 0 0 0 .125rem var(--brand-tertiary-500);
  outline: 0;
}

.sl-search-chips-container .selected-search-chip {
  background-color: var(--brand-tertiary-800);
  color: #fff;
}

.sl-search-chips-container .selected-search-chip:hover {
  background-color: var(--brand-tertiary-800);
}

.sl-search-chips-container .selected-search-chip .search-chip-filter-type-text, .sl-search-chips-container .selected-search-chip .search-chip-query-text, .sl-search-chips-container .selected-search-chip .search-chip-dismiss-button {
  color: #fff;
}

.sl-segmented-blocks {
  flex-flow: column wrap;
  display: flex;
}

@media (width >= 905px) {
  .sl-segmented-blocks {
    flex-direction: row;
  }
}

.sl-segmented-blocks .card {
  --bs-card-spacer-y: 0rem;
  --bs-card-spacer-x: 0rem;
  --bs-card-border-width: 0rem;
  --bs-card-border-color: none;
}

.sl-segmented-blocks .card .icon-container {
  margin-bottom: 1.5rem;
}

.sl-segmented-blocks .card .card-body .card-title {
  --bs-card-title-spacer-y: 1rem;
  --bs-card-title-color: var(--grey-900);
}

.sl-segmented-blocks .card .card-body .card-text {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-segmented-blocks .card .card-body .link-container, .sl-segmented-blocks .card .sl-btn, .sl-segmented-blocks .card .sl-dropdown {
  margin-top: 1.5rem;
}

.sl-segmented-blocks .card .grouped-form {
  flex-direction: column;
  display: flex;
}

.sl-segmented-blocks .card.single-form {
  align-items: flex-start;
}

.sl-segmented-blocks .card.single-form .grouped-form {
  width: 100%;
}

@media (width >= 905px) {
  .sl-segmented-blocks .card.single-form .grouped-form {
    flex-direction: row;
  }
}

.sl-segmented-blocks .card.single-form .grouped-form .sl-dropdown {
  width: 100%;
}

@media (width >= 905px) {
  .sl-segmented-blocks .card.single-form .grouped-form .sl-dropdown {
    width: 50%;
    margin: 1.5rem 1.5rem 0 0;
  }
}

@media (width <= 904.98px) {
  .sl-segmented-blocks .card.single-form .grouped-form .sl-btn {
    margin-top: 1rem;
  }
}

.sl-segmented-blocks.multi {
  row-gap: 1.5rem;
}

@media (width >= 905px) {
  .sl-segmented-blocks.multi {
    row-gap: 3.5rem;
  }
}

.sl-segmented-blocks.multi .card {
  box-sizing: border-box;
  flex: 25%;
}

@media (width >= 905px) {
  .sl-segmented-blocks.multi .card {
    padding-right: 1.5rem;
  }
}

.sl-segmented-blocks.multi .card:not(:last-child) {
  border-bottom: .0625rem solid var(--grey-300);
}

@media (width >= 905px) {
  .sl-segmented-blocks.multi .card:not(:last-child) {
    border-bottom: none;
    border-right: .0625rem solid var(--grey-300);
  }
}

@media (width <= 904.98px) {
  .sl-segmented-blocks.multi .card:first-child {
    padding-bottom: 1.5rem;
  }
}

.sl-segmented-blocks.multi .card:not(:first-of-type) {
  padding-bottom: 1.5rem;
}

@media (width >= 905px) {
  .sl-segmented-blocks.multi .card:not(:first-of-type) {
    padding-bottom: 0;
    padding-left: 1.5rem;
  }

  .sl-segmented-blocks.multi .card:nth-child(4n+1) {
    padding-left: 0;
  }

  .sl-segmented-blocks.multi .card:nth-child(4n), .sl-segmented-blocks.multi .card:nth-child(4n+4):last-child {
    border-right: none;
  }
}

.sl-segmented-blocks.multi .card .sl-btn {
  margin-top: 1.5rem;
}

.sl-segmented-blocks.multi .card .grouped-form .sl-btn {
  margin-top: 1rem;
}

.sl-slider {
  width: inherit;
  margin: 2.188rem auto auto;
  position: relative;
}

.sl-slider .wrapper {
  align-items: center;
  display: flex;
}

.sl-slider .wrapper .input-wrapper {
  width: calc(100% + 1.5rem);
  height: 1.5rem;
  margin: 0 -.75rem;
  position: absolute;
}

.sl-slider .wrapper .input-wrapper .input {
  pointer-events: none;
  appearance: none;
  opacity: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  padding: 0;
  position: absolute;
}

.sl-slider .wrapper .input-wrapper .input::-ms-track {
  appearance: none;
  background: none;
  border: #0000;
}

.sl-slider .wrapper .input-wrapper .input::-moz-range-track {
  appearance: none;
  background: none;
  border: #0000;
}

.sl-slider .wrapper .input-wrapper .input:focus::-webkit-slider-runnable-track {
  appearance: none;
  background: none;
  border: #0000;
}

.sl-slider .wrapper .input-wrapper .input::-ms-thumb {
  appearance: none;
  pointer-events: all;
  cursor: grab;
  background-color: red;
  border: 0;
  border-radius: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.sl-slider .wrapper .input-wrapper .input::-ms-thumb:active {
  cursor: grabbing;
}

.sl-slider .wrapper .input-wrapper .input::-moz-range-thumb {
  appearance: none;
  pointer-events: all;
  cursor: grab;
  background-color: red;
  border: 0;
  border-radius: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.sl-slider .wrapper .input-wrapper .input::-moz-range-thumb:active {
  cursor: grabbing;
}

.sl-slider .wrapper .input-wrapper .input::-webkit-slider-thumb {
  appearance: none;
  pointer-events: all;
  cursor: grab;
  background-color: red;
  border: 0;
  border-radius: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.sl-slider .wrapper .input-wrapper .input::-webkit-slider-thumb:active {
  cursor: grabbing;
}

.sl-slider .wrapper .control-wrapper {
  width: 100%;
  height: 1.5rem;
  position: absolute;
}

.sl-slider .wrapper .control-wrapper .value-wrapper {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 1.3125rem;
}

.sl-slider .wrapper .control-wrapper .value-wrapper .min-value {
  color: var(--grey-900);
  margin-bottom: 0;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

.sl-slider .wrapper .control-wrapper .value-wrapper .max-value {
  color: var(--grey-900);
  margin-bottom: 0;
  margin-left: auto;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

.sl-slider .wrapper .control-wrapper .rail {
  background: #d3d3d3;
  width: 100%;
  height: .25rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.sl-slider .wrapper .control-wrapper .rail .inner-rail {
  background: #00588b;
  height: 100%;
  position: absolute;
}

.sl-slider .wrapper .control-wrapper .tooltip {
  color: #fff;
  z-index: 2;
  background: #000;
  align-items: flex-start;
  margin-left: -.75rem;
  padding: .25rem .5rem;
  display: flex;
  position: absolute;
  bottom: 1rem;
  transform: translate3d(0, -50%, 0);
}

.sl-slider .wrapper .control-wrapper .control {
  z-index: 2;
  background: #00588b;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: -.75rem;
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
}

.marker-grey {
  background: #a0a5a7;
  width: .25rem;
  height: 100%;
  position: absolute;
}

.marker-blue {
  background: #94d9f4;
  width: .25rem;
  height: 100%;
  position: absolute;
}

.sl-social-media {
  flex-flow: wrap;
  row-gap: 1.5rem;
  display: flex;
}

.sl-social-media p {
  margin-bottom: unset;
  color: var(--grey-900);
  align-items: center;
  margin-right: 1.5rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  display: flex;
}

.sl-social-media ul {
  flex-flow: wrap;
  justify-content: space-around;
  align-items: center;
  row-gap: 1.5rem;
  margin: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.sl-social-media ul li {
  margin-left: 1.5rem;
}

.sl-social-media ul li a:focus-visible {
  outline: .125rem solid var(--brand-tertiary-500);
  outline-offset: 0;
}

.sl-social-media ul li:first-child {
  margin-left: 0;
}

.sl-statistic-blocks {
  flex-flow: column wrap;
  display: flex;
}

@media (width >= 905px) {
  .sl-statistic-blocks {
    flex-direction: row;
  }
}

.sl-statistic-blocks .card {
  --bs-card-spacer-y: 0rem;
  --bs-card-spacer-x: 0rem;
  --bs-card-border-width: 0rem;
  --bs-card-border-color: none;
}

.sl-statistic-blocks .card .card-body .caption {
  color: var(--grey-900);
  margin-bottom: 1rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
}

.sl-statistic-blocks .card .card-body .card-title {
  --bs-card-title-spacer-y: 1rem;
  --bs-card-title-color: var(--grey-900);
}

.sl-statistic-blocks .card .card-body .card-text {
  color: var(--grey-600);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-statistic-blocks.multi {
  row-gap: 1.5rem;
}

@media (width >= 905px) {
  .sl-statistic-blocks.multi {
    row-gap: 3.5rem;
  }
}

.sl-statistic-blocks.multi .card {
  box-sizing: border-box;
  flex: 25%;
}

@media (width >= 905px) {
  .sl-statistic-blocks.multi .card {
    padding-right: 1.5rem;
  }
}

.sl-statistic-blocks.multi .card:not(:last-child) {
  border-bottom: .0625rem solid var(--grey-300);
}

@media (width >= 905px) {
  .sl-statistic-blocks.multi .card:not(:last-child) {
    border-bottom: none;
    border-right: .0625rem solid var(--grey-300);
  }
}

@media (width <= 904.98px) {
  .sl-statistic-blocks.multi .card:first-child {
    padding-bottom: 1.5rem;
  }
}

.sl-statistic-blocks.multi .card:not(:first-of-type) {
  padding-bottom: 1.5rem;
}

@media (width >= 905px) {
  .sl-statistic-blocks.multi .card:not(:first-of-type) {
    padding-bottom: 0;
    padding-left: 1.5rem;
  }

  .sl-statistic-blocks.multi .card:nth-child(4n+1) {
    padding-left: 0;
  }

  .sl-statistic-blocks.multi .card:nth-child(4n), .sl-statistic-blocks.multi .card:nth-child(4n+4):last-child {
    border-right: none;
  }
}

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), .05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), .1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), .075);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  width: 100%;
  margin-bottom: 1rem;
}

.table > :not(caption) > * > * {
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
  padding: .5rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > *, .table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #a6b5cc;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (width <= 599.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 904.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1239.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1439.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.sl-table {
  color: var(--grey-900);
  border-color: var(--grey-300);
}

.sl-table .table-header {
  caption-side: initial;
  margin-bottom: 1rem;
}

.sl-table .table-header .h3 {
  color: var(--grey-900);
}

.sl-table thead {
  vertical-align: middle;
  height: 3.5rem;
}

.sl-table thead tr > th {
  background-color: var(--brand-secondary-100);
  white-space: nowrap;
  border-style: hidden;
}

.sl-table thead tr > th > span {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-table thead tr > th.sl-table-column-sortable > span {
  cursor: pointer;
  user-select: none;
}

.sl-table thead tr > th.sl-table-column-sortable > span > .sl-table-column-sortable-hover-icon {
  display: none;
}

.sl-table thead tr > th.sl-table-column-sortable > span:hover > .sl-table-column-sortable-hover-icon {
  display: inline-block;
}

.sl-table thead tr > th.sl-table-column-sortable > span:hover > .sl-table-column-sortable-default-icon {
  display: none;
}

.sl-table tr {
  vertical-align: middle;
  background-color: #fff;
  border-bottom-width: .125rem;
  height: 3.3125rem;
}

.sl-table tr td, .sl-table tr th {
  padding: 1rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 21px;
}

.sl-table tr.striped td, .sl-table tr.striped th {
  background-color: var(--brand-secondary-50);
}

.sl-table tr.collapsed {
  border-bottom-style: hidden;
}

.sl-table .sl-table-title {
  color: var(--grey-900);
  font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  display: block;
}

.sl-table .sl-table-subtitle {
  color: var(--grey-900);
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  display: block;
}

.sl-table .sl-table-total {
  color: var(--grey-900);
  font-family: Sun Life New Text Bold, Helvetica Neue, Calibri, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3125rem;
  display: block;
}

@media (width <= 599.98px) {
  .sl-table.sl-stacked {
    padding: 0;
  }

  .sl-table.sl-stacked .table-header {
    caption-side: initial;
    margin-bottom: 0;
    padding: 1.5rem 1rem;
  }

  .sl-table.sl-stacked .table-header .h3 {
    color: var(--grey-800);
    margin-bottom: 0;
  }

  .sl-table.sl-stacked table thead {
    display: none;
  }

  .sl-table.sl-stacked table tbody tr {
    height: unset;
    flex-direction: column;
    display: flex;
  }

  .sl-table.sl-stacked table tbody tr.striped td, .sl-table.sl-stacked table tbody tr.striped th {
    background-color: var(--brand-secondary-100);
  }

  .sl-table.sl-stacked table tbody tr th, .sl-table.sl-stacked table tbody tr td {
    border-bottom-width: 0;
    flex-direction: column;
    padding: 0;
    display: flex;
    position: relative;
  }

  .sl-table.sl-stacked table tbody tr th > .cell-content, .sl-table.sl-stacked table tbody tr td > .cell-content {
    min-height: 3rem;
    padding: .75rem 1rem;
  }

  .sl-table.sl-stacked table tbody tr th:before, .sl-table.sl-stacked table tbody tr td:before {
    content: attr(data-label);
    background-color: var(--brand-secondary-100);
    width: 100%;
    height: 48px;
    padding: .75rem 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sl-table.sl-stacked table tbody tr td.cell-collapsed {
    display: none;
  }

  .sl-table.sl-stacked table tbody tr:not(.sl-table.sl-stacked table tbody tr:last-child):after {
    content: "";
    background-color: #fff;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: .75rem;
    display: flex;
  }

  .sl-table.sl-stacked table tbody tr td:last-child {
    border-color: var(--grey-300);
    border-bottom-style: solid;
    border-bottom-width: 2px;
  }

  .sl-table.sl-stacked table tbody tr.section-total td:before, .sl-table.sl-stacked table tbody tr.section-heading:not(.sl-table.sl-stacked table tbody tr:last-child):after, .sl-table.sl-stacked table tbody tr.collapsed:not(.sl-table.sl-stacked table tbody tr:last-child):after {
    display: none;
  }

  .sl-table.sl-stacked table tbody tr.collapsed:not(.sl-table.sl-stacked table tbody tr:last-child) td:last-child {
    border-bottom-style: hidden;
  }

  .sl-table.sl-stacked table tbody tr.collapsed-heading th:before {
    display: none;
  }
}

.sl-tabs .stack-tab-container {
  margin: 1.5rem 0;
  display: flex;
}

.sl-tabs .stack-tab-container .arrow-btn {
  cursor: pointer;
  text-align: center;
  width: 1.5rem;
  height: 2.375rem;
  padding-bottom: .875rem;
}

.sl-tabs .stack-tab-container .arrow-btn.active {
  color: var(--grey-900);
}

.sl-tabs .stack-tab-container .arrow-btn.disabled {
  color: var(--grey-500);
}

.sl-tabs .stack-tab-container .nav-tabs {
  --bs-nav-tabs-border-width: 0rem;
  --bs-nav-tabs-border-color: none;
  --bs-nav-tabs-link-active-color: var(--grey-900);
  --bs-nav-link-color: var(--grey-700);
  --bs-nav-link-hover-color: var(--grey-900);
}

.sl-tabs .stack-tab-container .nav-tabs .nav-item {
  text-align: center;
  flex: 1 0 auto;
  min-width: 2.75rem;
  height: 2.375rem;
}

.sl-tabs .stack-tab-container .nav-tabs .nav-item .nav-link {
  border: none;
  border-bottom: .125rem solid var(--grey-300);
  height: 2.375rem;
  color: var(--grey-700);
  padding: 0 .75rem .75rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-tabs .stack-tab-container .nav-tabs .nav-item .nav-link:hover, .sl-tabs .stack-tab-container .nav-tabs .nav-item .nav-link.active {
  border-bottom: .125rem solid var(--grey-900);
  color: var(--grey-900);
}

.sl-tabs .stack-tab-container .nav-tabs .nav-item .nav-link:focus-visible {
  color: var(--grey-800);
  outline: .125rem solid var(--brand-tertiary-500);
  outline-offset: -.125rem;
  box-shadow: none;
}

.sl-tabs .stack-tab-container .nav-tabs.scrollable-tabs {
  scrollbar-width: none;
  flex-wrap: nowrap;
  flex: 1;
  justify-content: space-between;
  display: flex;
  overflow-x: hidden;
}

.sl-tabs .stack-tab-container .nav-tabs.scrollable-tabs::-webkit-scrollbar {
  background: none;
  width: 0;
  height: 0;
}

.sl-tabs .tab-content .tab-pane:focus-visible {
  outline: none;
}

.sl-text-area {
  border: .0625rem solid var(--grey-700);
  position: relative;
}

.sl-text-area .form-control {
  resize: none;
  border: none;
  border-bottom: 1.625rem solid var(--white);
  border-top: 1.5rem solid var(--white);
  height: 12.375rem;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1.5rem;
  padding-top: 0 !important;
}

.sl-text-area .form-control ~ label {
  background-color: var(--white);
  white-space: break-spaces;
  width: auto;
  height: auto;
  color: var(--grey-700);
  border: none;
  padding-top: .75rem;
  padding-left: 1rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-text-area .form-control:focus {
  box-shadow: 0 0 0 .125rem var(--brand-tertiary-500);
  box-shadow: none;
  outline: .125rem solid var(--brand-tertiary-500);
  outline-offset: .0625rem;
}

.sl-text-area .form-control:focus ~ label {
  z-index: 1;
  width: 100%;
  height: auto;
  color: var(--brand-tertiary-800);
  opacity: 1;
  padding-top: .5rem;
  padding-bottom: .25rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .75rem;
  font-weight: 400;
  line-height: .75rem;
  transform: scale(1)translateY(0);
}

.sl-text-area .form-control:active {
  box-shadow: none;
}

.sl-text-area .form-control:not(:placeholder-shown) ~ label {
  z-index: 1;
  opacity: 1;
  width: 100%;
  height: auto;
  color: var(--grey-700);
  border: none;
  padding-top: .5rem;
  padding-bottom: .25rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: .75rem;
  font-weight: 400;
  line-height: .75rem;
  transform: scale(1)translateY(0);
}

.sl-text-area .form-control:disabled {
  border-top: 1.5rem solid var(--grey-200);
}

.sl-text-area .character-count {
  width: 100%;
  color: var(--grey-700);
  font-size: .75rem;
  line-height: initial;
  background: var(--white);
  justify-content: flex-end;
  margin-bottom: 0;
  padding: .125rem 1rem .5rem;
  display: flex;
  position: absolute;
  bottom: 0;
}

.sl-text-area.sl-input-error {
  border: .0625rem solid var(--red-800);
}

.sl-text-area.sl-input-error .form-control {
  border-bottom: 1.625rem solid var(--red-50);
  border-top: 1.5rem solid var(--red-50);
  background: var(--red-50);
}

.sl-text-area.sl-input-error label {
  background: var(--red-50);
  color: var(--red-800) !important;
}

.sl-text-area.sl-input-error label:after {
  display: none;
}

.sl-text-area.sl-input-error .character-count {
  background: var(--red-50);
}

.sl-text-area .disabled {
  border-top: .125rem solid var(--grey-200);
  background-color: var(--grey-200) !important;
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 24px;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), .85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), .85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  pointer-events: none;
  width: max-content;
  max-width: 100%;
  position: absolute;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.toast-header .btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.sl-toast-container {
  z-index: 100;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  display: flex;
  position: fixed;
  bottom: 1.5rem;
}

.sl-toast-container .sl-toast {
  --bs-toast-bg: var(--white);
  border: none;
  flex-direction: column;
  width: calc(100vw - 2rem);
  min-width: calc(100vw - 2rem);
  max-width: calc(100vw - 2rem);
  margin-top: .5rem;
  padding: 1.5rem 1.5rem 1.5rem 1.25rem;
  display: flex;
  box-shadow: 0 4px 8px 3px #00000026;
}

@media (width >= 600px) and (width <= 904.98px) {
  .sl-toast-container .sl-toast {
    width: calc(100vw - 4rem);
    min-width: calc(100vw - 4rem);
    max-width: calc(100vw - 4rem);
  }
}

@media (width >= 905px) and (width <= 1239.98px) {
  .sl-toast-container .sl-toast {
    width: fit-content;
    min-width: calc(33.3333vw - 2.5rem - 16px);
    max-width: calc(100vw - 7.5rem);
  }
}

@media (width >= 1240px) and (width <= 1439.98px) {
  .sl-toast-container .sl-toast {
    width: fit-content;
    min-width: 270px;
    max-width: 760px;
  }
}

@media (width >= 1440px) {
  .sl-toast-container .sl-toast {
    width: fit-content;
    min-width: 270px;
    max-width: 760px;
  }
}

.sl-toast-container .sl-toast.sl-toast-button {
  padding: 1rem 1.5rem 1rem 1.25rem;
}

.sl-toast-container .sl-toast.sl-toast-info {
  border-left: solid .25rem var(--brand-tertiary-800);
}

.sl-toast-container .sl-toast.sl-toast-success {
  border-left: solid .25rem var(--green-800);
}

.sl-toast-container .sl-toast.sl-toast-warning {
  border-left: solid .25rem var(--brand-primary-800);
}

.sl-toast-container .sl-toast.sl-toast-danger {
  border-left: solid .25rem var(--red-800);
}

.sl-toast-container .sl-toast .toast-component {
  align-items: baseline;
  display: flex;
}

.sl-toast-container .sl-toast .toast-component .toast-icon-container {
  text-align: center;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 .5rem 0 0;
}

.sl-toast-container .sl-toast .toast-component .toast-icon-container .info-icon {
  color: var(--brand-tertiary-800);
}

.sl-toast-container .sl-toast .toast-component .toast-icon-container .success-icon {
  color: var(--green-800);
}

.sl-toast-container .sl-toast .toast-component .toast-icon-container .warning-icon {
  color: var(--brand-primary-800);
}

.sl-toast-container .sl-toast .toast-component .toast-icon-container .danger-icon {
  color: var(--red-800);
}

.sl-toast-container .sl-toast .toast-component .toast-body {
  color: var(--grey-900);
  padding: 0 1.5rem 0 0;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.sl-toast-container .sl-toast .toast-component .toast-body p {
  margin-bottom: 0;
}

.sl-toast-container .sl-toast .toast-component .toast-buttons {
  display: flex;
}

.sl-toast-container .sl-toast .toast-component .toast-buttons .toast-action-button {
  margin: auto 1.5rem auto 0;
}

.sl-toast-container .sl-toast .toast-component .toast-dismiss-button {
  margin-left: auto;
}

.sl-toast-container .sl-toast .mobile-toast-action-button {
  margin-top: 1rem;
}

.sl-toast-container .sl-toast .dismiss-button {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--grey-900);
  background: none;
  border: none;
  margin: auto;
}

* {
  font-smooth: always;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
}

body {
  -webkit-print-color-adjust: exact;
  font-size: 1rem;
}

hr {
  border: none;
  border-top: 1px solid var(--grey-500);
  opacity: .4;
  height: 0;
  margin-top: 24px;
  margin-bottom: 24px;
}

#root {
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  min-height: 100%;
  display: grid;
}

.wrapper {
  max-width: 985px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
}

.text-center {
  text-align: center;
}

.bg-whisper {
  background: var(--grey-50);
}

.w-full {
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.btn-reset {
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.btn-reset[disabled] {
  opacity: .5;
}

.sr-only {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  display: inline-block;
  overflow: hidden;
  position: absolute !important;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.hide\:empty:empty {
  display: none;
}

@media print {
  .ReactQueryDevtools_toggleButton__printHidden {
    display: none !important;
  }
}

@font-face {
  font-family: Sun Life New Display Bold;
  src: url("SunLifeNewDisplay-Bold.eb85fb76.woff") format("woff");
  src: url("SunLifeNewDisplay-Bold.78a38654.woff2") format("woff2");
}

@font-face {
  font-family: Sun Life New Display Regular;
  src: url("SunLifeNewDisplay-Regular.68dce666.woff") format("woff");
  src: url("SunLifeNewDisplay-Regular.c949f5e1.woff2") format("woff2");
}

@font-face {
  font-family: Sun Life New Text Bold;
  src: url("SunLifeNewText-Bold.c164b8b4.woff") format("woff");
  src: url("SunLifeNewText-Bold.cf3293c2.woff2") format("woff2");
}

@font-face {
  font-family: Sun Life New Text Bold Italic;
  src: url("SunLifeNewText-BoldItalic.a572b246.woff") format("woff");
  src: url("SunLifeNewText-BoldItalic.c64c6ec4.woff2") format("woff2");
}

@font-face {
  font-family: Sun Life New Text Italic;
  src: url("SunLifeNewText-Italic.52f0a5de.woff") format("woff");
  src: url("SunLifeNewText-Italic.8c6ab885.woff2") format("woff2");
}

@font-face {
  font-family: Sun Life New Text Regular;
  src: url("SunLifeNewText-Regular.cafe4d76.woff") format("woff");
  src: url("SunLifeNewText-Regular.20db8379.woff2") format("woff2");
}

.OYPoea_legalFooterBody {
  margin-bottom: 4rem;
}

.OYPoea_footerLink {
  color: var(--brand-tertiary-800) !important;
  font-weight: 700 !important;
  text-decoration: underline !important;
}

.SFlPbW_exitnotification button {
  display: none !important;
}

.SFlPbW_exitnotification [class="container"] {
  justify-content: center;
  display: flex;
}

.SFlPbW_exitnotification [class="notification-icon"] {
  display: none;
}

.SFlPbW_exitnotification [class="notification-text"] {
  text-align: center;

  & a {
    white-space: initial;
  }
}

.SFlPbW_exitnotification [role="alert"] {
  border-left: none;
  box-shadow: none !important;
}

@media (width <= 575.98px) {
  .SFlPbW_exitnotification [class="notification-text"] {
    margin-right: 0;
    font-size: 12px;
    line-height: 1rem;

    & a {
      font-size: 12px;
      line-height: 1rem;
    }
  }
}

.fOkqUa_logoutSection {
  flex-direction: column;
  place-content: center;
  padding-top: 5.5rem;
  padding-bottom: 5.5rem;
  display: flex;
}

.fOkqUa_centerText {
  text-align: center;
}

.fOkqUa_buttonContainer {
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  display: flex;
}

._4du78q_benefitCard {
  border: 2px solid #dcdedf;
  margin-top: 1.75rem;
  padding: 1.25rem 1.5rem 1.25rem 1.25rem;
}

._4du78q_benefitCardHeader {
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  display: flex;
}

._4du78q_benefitCardActions {
  vertical-align: middle;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  margin-left: auto;
  display: flex;
}

._4du78q_radioCaption {
  color: var(--Grey-Helios-Grey-700, #5b6062);
  margin-left: 2.25rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
  top: -.75rem;
}

._4du78q_radioCaption + div {
  padding-top: 0;
}

.Unwmvq_textBoxMargin {
  margin: 1.5rem 0;
}

.kNQy4q_dependentContainer {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.kNQy4q_dependentContainer [class*="sl-btn"] {
  min-width: 330px;
  margin-top: 1rem;
}

.kNQy4q_dependentContainer [class="table-responsive"] {
  overflow: inherit;
}

.kNQy4q_dependentContainer [class="table"] {
  margin-top: .75rem;
}

.kNQy4q_dropdownIcon {
  justify-content: right;
  width: 1.5rem;
  margin-left: auto;
  padding: 4px !important;
}

.kNQy4q_dropdownIcon .kNQy4q_dropdownLabel {
  display: none;
}

.kNQy4q_dependentsTable {
  & td {
    vertical-align: top;
  }
}

._8plloW_errorNotification [class="btn-close"] {
  display: none;
}

.zdeyIq_textBoxMargin {
  margin: 1.5rem 0;
}

.zdeyIq_halfRow {
  width: 45%;
}

.zdeyIq_phoneCtr {
  justify-content: space-between;
  margin-top: -2rem;
  display: flex;
}

.zdeyIq_threeQuarRow {
  width: 58%;
}

.zdeyIq_oneQuarRow {
  width: 38%;
}

.zwbvxW_halfRow {
  justify-content: space-between;
  display: flex;
}

.zwbvxW_extraText {
  width: 80%;
  margin-top: 1.5rem;
}

.zwbvxW_textBoxMargin {
  margin: 1.5rem 0;
}

.zwbvxW_justBottomMargin {
  margin-bottom: 5.5rem;
}

.zwbvxW_radioRow {
  margin-bottom: -1rem;
  display: flex;
}

.zwbvxW_subtitle {
  margin-top: 3.5rem;
}

.ES8I2G_halfRow {
  justify-content: space-between;
  display: flex;
}

.ES8I2G_contactText {
  width: 90%;
}

._1O8kza_dataDetails {
  grid-template-columns: 200px auto;
  display: grid;

  & dt {
    color: var(--grey-700);
    grid-column: 1;
    padding-right: .5rem;
    font-weight: normal;
  }

  & dd {
    grid-column: 2;
    margin-bottom: .25rem;
  }

  @media (width <= 575.98px) {
    & {
      grid-template-columns: auto;
    }

    & dd {
      grid-column: 1;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}

.dlwArW_stack {
  flex-direction: column;
  display: flex;
}

.dlwArW_growFirst {
  & :first-child {
    flex-grow: 1;
  }
}

.ifFZzG_error {
  justify-content: center;
  margin: 4rem 0;
  display: flex;
}

.ifFZzG_loading {
  margin: 4rem 0;
}

.DmJRXW_depTable {
  margin-top: 1.5rem;
}

.DmJRXW_noDependents {
  text-align: center;
  white-space: pre-wrap;
  display: block;

  & h4 {
    display: none;
  }
}

.ArlQ5W_cardMargin {
  margin-top: 1rem;
  padding-bottom: .75rem;
}

.ArlQ5W_noBenefits {
  text-align: center;
  display: block;

  & h4 {
    display: none;
  }
}

.ArlQ5W_policyNumber {
  color: #5b6062;
  margin-left: .1rem;
  padding-bottom: .5rem;
  font-size: 14px;
  line-height: .51rem;
}

.CcRAka_dropdownIcon {
  justify-content: center;
  width: 1rem;
  padding: 0 !important;
}

.CcRAka_table {
  margin-top: 1.5rem;

  & table {
    table-layout: fixed;
    width: 100%;

    & th:last-child {
      width: auto !important;
    }
  }
}

.CcRAka_badge {
  white-space: nowrap;
  align-items: center;
  inline-size: fit-content;
  display: flex;

  & span {
    min-width: 80px;
  }
}

.CcRAka_cardMargin {
  margin-top: 1rem;
  padding-bottom: .75rem;
}

._9X68HW_policyinfo label {
  color: var(--grey-700);
  padding-right: .5rem;
}

._9X68HW_greybox {
  background-color: #fff;
  border: 2px solid #dcdedf;
  padding: 1.5rem;
}

._9X68HW_spacer {
  margin-top: 1rem;
}

.vCC5eq_breadcrumbs {
  line-height: 1;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

:is(.vCC5eq_breadcrumbs > .breadcrumb-item, .vCC5eq_breadcrumbs > .current-item) {
  margin: 0 !important;
}

.vCC5eq_mobileIcon {
  margin-bottom: -.3rem;
}

.vCC5eq_marginTop1rem {
  margin-top: 1rem;
}

.vCC5eq_marginTop1rem span {
  background: var(--brand-tertiary-800);
}

.vCC5eq_margin2rem {
  margin-top: 2rem;
}

.vCC5eq_marginTopHalfrem {
  margin-top: .5rem;
}

.vCC5eq_removeMargins {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.vCC5eq_dateContainer {
  flex-direction: row;
  justify-content: flex-start;
  font-family: Sun Life New Text Regular;
  display: flex;
}

.vCC5eq_cardLabel {
  text-align: left;
  color: #3d4142;
  border-right: solid 2px var(--grey-300);
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: Sun Life New Text Regular;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.vCC5eq_memberHeaderAttr :first-child {
  padding-left: 0;
}

.vCC5eq_memberHeaderAttr :last-child {
  border-right: none;
  padding-right: 0;
}

.vCC5eq_cardInnerContainer {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.vCC5eq_entityHeader {
  border: none;
  padding-top: 24px;
}

.vCC5eq_memberHeaderAttr {
  display: flex;
}

.vCC5eq_cardTitle {
  color: var(--grey-900);
  margin-top: 2.5rem;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 2.25rem;
}

.vCC5eq_cardMargin {
  margin-top: 1rem;
  padding-bottom: .75rem;
}

@media (width <= 575.98px) {
  .vCC5eq_memberHeaderAttr {
    flex-direction: column;
  }

  .vCC5eq_memberHeaderAttr > .vCC5eq_cardLabel {
    border: none;
    padding: 0;
  }
}

.vCC5eq_headerContainer {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: Sun Life New Text Regular, Helvetica Neue, Calibri, sans-serif;
  display: flex;
}

.vCC5eq_dentalButton {
  white-space: nowrap;
  margin-left: 1rem;
  font-family: Sun Life New Text Regular;
}

@media (width <= 575.98px) {
  .vCC5eq_headerContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .vCC5eq_dentalButton {
    width: 100%;
    margin-top: 1rem;
    margin-left: 0;
  }
}

.gcM8bG_pageContent {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 15%;
  margin-bottom: 8%;
  display: flex;
}

.gcM8bG_progress {
  margin-bottom: 2%;
}

.UlFc5G_emptyState {
  text-align: center;
  color: var(--color-gray-900);
  padding-top: .5rem;
  padding-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: 400;
}

.aK4RmW_dot {
  background-color: #bbb;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  margin-right: .5rem;
  display: inline-block;
}

.aK4RmW_color-APPROVED_ACTIVE_SOON {
  background-color: var(--orange-600);
}

.aK4RmW_color-APPROVED_ACTIVE {
  background-color: var(--green-600);
}

.aK4RmW_color-TERMINATING_SOON {
  background-color: var(--red-600);
}

.aK4RmW_color-PENDING {
  background-color: var(--orange-600);
}

._2owStG_dropdownIcon {
  justify-content: right;
  width: 1.5rem;
  margin-left: auto;
  padding: 4px !important;
}

._2owStG_dropdownIcon ._2owStG_dropdownLabel {
  display: none;
}

._2owStG_memberListTable td {
  vertical-align: top;
}

._2owStG_memberName a {
  white-space: initial;
}

.CuNXSG_memberSearch ul {
  display: none !important;
}

.CuNXSG_memberSearch input {
  margin-bottom: .5rem !important;
}

.Rhrn8G_pageTitle {
  margin: 1.5rem 0 !important;
}

.mqEHlW_loadingContainer {
  display: none;
}

.mqEHlW_loadingContainer[data-loading="true"] {
  display: unset;
}

.mqEHlW_loading {
  margin: 4rem 0;
}

.mqEHlW_errorContainer {
  display: none;
}

.mqEHlW_errorContainer[data-error="true"] {
  display: unset;
}

.mqEHlW_error {
  justify-content: center;
  margin: 4rem 0;
  display: flex;
}

.mqEHlW_resultContainer[data-loading="true"], .mqEHlW_resultContainer[data-error="true"] {
  display: none;
}

.-\39 yNyq_helpWrapper {
  padding-top: 3rem;
}

.-\39 yNyq_removeMargins {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.-\39 yNyq_titleCtr {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (width <= 575.98px) {
  .-\39 yNyq_addBtn {
    width: 50%;
  }
}
/*# sourceMappingURL=index.7b40a409.css.map */
